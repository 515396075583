import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { EmitterService } from 'src/app/shared/services/event-service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { isPlatformBrowser } from '@angular/common';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { __await } from 'tslib';
import * as moment from 'moment';
import { AmchartsComponent } from '../../widgets/amcharts/amcharts.component';
import { PieChartComponent } from '../../widgets/pie-chart/pie-chart.component';
import { Router } from '@angular/router';

@Component({
  selector: 'zainergy-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss']
})
export class SingleComponent implements OnInit {

  private chart: am4charts.XYChart;

  public action: String = 'getChartDataByAccount';
  public device_id: any;
  public parameters: any = [];
  public date_from: String = '';
  public date_to: String = '';
  public date_from_trend: String = '';
  public date_to_trend: String = '';

  public vl_from_date_selection: String = '';
  public curr_from_date_selection: String = '';
  public kw_from_date_selection: String = '';
  public pf_from_date_selection: String = '';
  public f_from_date_selection: String = '';
  public fh_from_date_selection: String = '';

  public vl_to_date_selection: String = '';
  public curr_to_date_selection: String = '';
  public kw_to_date_selection: String = '';
  public pf_to_date_selection: String = '';
  public f_to_date_selection: String = '';
  public fh_to_date_selection: String = '';

  public params: any = [];
  public params_trend: any = [];
  public data_param: String = '';
  public data_param_el: String = '';
  public tabular_data: any;
  public tabular_data_trend: any;
  public param_data: any;

  public overview_params: any = [];
  public overview_param: any = [];
  public overview_title: any = 'Overview';
  public curr_tab = 'tab-trends';
  public tab_trends_status: boolean = false;
  public tab_graph_status: boolean = false;
  public tab_table_status: boolean = false;
  public tab_overview_status: boolean = false;

  public vol_data: any = []; public vol_chart_active: String = 'line';
  public curr_data: any = []; public curr_chart_active: String = 'line';
  public kw_data: any = []; public kw_chart_active: String = 'line';
  public pf_data: any = []; public pf_chart_active: String = 'line';
  public f_data: any = []; public f_chart_active: String = 'line';
  public fh_data: any = []; public fh_chart_active: String = 'line';
  public ov_chart_active: String = 'line';

  public selected_params: any = [];
  public voltage_prop: any;
  public curr_prop: any;
  public kw_prop: any;
  public pf_prop: any;
  public f_prop: any;
  public fh_prop: any;

  public voltage_header: string = 'GRAPH';
  public current_header: string = 'GRAPH';
  public kw_header: string = 'GRAPH';
  public power_header: string = 'GRAPH';

  public voltage_status: boolean = true;
  public current_status: boolean = true;
  public kw_status: boolean = true;
  public power_status: boolean = true;

  public dropdownList = [];
  public selectedItems = [];
  public dropdownSettings: IDropdownSettings;
  public selectedItems_trend = [];
  public dropdownSettings_trend: IDropdownSettings;
  public total_found: any = 0;
  public total_found_trend: any = 0;
  public dir: any = 'asc';
  public export_table: any = [];
  public units: any = [];
  public unit: String = '';
  public unit_rate: String = '';

  public from: any = 0;
  public page = 1; public size: any = 50;
  public pages: any = [];

  alwaysShowCalendars: boolean;
  public pie_chart_sum: any = 0.00;
  public genset_pie_chart_sum: any = 0.00;
  public refelling_pie_chart_sum: any = 0.00;
  public consumption_pie_chart_sum: any = 0.00;
  public chain_analysis_chart_status: boolean = true;
  public card_interval: number;

  selected: any = { start: moment(), end: moment() };
  tabular_selected: any = { start: moment(), end: moment() };
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Week': [moment().startOf('week'), moment().endOf('days')],
    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('days')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  public chart_data: any = [];
  public export_data: any = [];
  public current_date: String = '';
  public graph_type: String = 'val_';
  public fixed_interval: String = '5m';
  public chart_active: String = 'line';
  public energy_consumption: any = { current: 0.00, last: 0.00 };
  public energy_profile_type: string = 'Today';
  public genset_energy_profile_type: string = 'Today';
  public refelling_energy_profile_type: string = 'Today';
  public consumption_energy_profile_type: string = 'Today';
  public energy_profile_graph_status: boolean = false;
  public week_start_date: String = '';
  public month_start_date: String = '';
  public type: String = 'Daily';
  public genset_type: String = 'Daily';
  public min_max: any = { card_1_min: 0.00, card_1_max: 0.00, card_2_min: 0.00, card_2_max: 0.00, card_3_min: 0.00, card_3_max: 0.00, card_4_min: 0.00, card_4_max: 0.00 }
  public min_max_name: any = { card_1: '', card_2: '', card_3: '', card_4: '' }
  public min_max_unit: any = { card_1: '', card_2: '', card_3: '', card_4: '' }
  public motor_rpm: any = 10;
  public max_temp_range: any = 10;
  public source_type: boolean = false;
  public flow_meter_status: boolean = false;
  public energy_status: boolean = false;
  public sensor_status: boolean = false;
  public level_status: boolean = false;
  public map_status = 'd-none';
  public kpi: string = 'dashboard';
  public table_trend_class: string = 'col-xl-12';
  public inverter_trend_status: string = 'd-none';
  public inverter_table: any = [];
  public inverter_mppt_table: any = [];
  public last_updated_inverter_table: any = '';
  public table_dropdown_interval: number;

  // google maps zoom level
  zoom: number = 18;

  // initial center position for the map
  lat: number = 24.8961;
  lng: number = 67.0814;
  public interval: number;

  public level_unit: string = '';
  public device_unit: string = '';

  constructor(private dashboardService: DashboardService, @Inject(PLATFORM_ID) private platformId, private zone: NgZone, private eventEmitter: EmitterService, private dataShared: DataSharedService, private toastr: ToastrService, private amcharts: AmchartsComponent, private pieChart: PieChartComponent, private router: Router) {
    this.parameters = ['avg_vl_pp', 'avg_curr_3p', 'total_w', 'avg_pf_3p', 'total_f', 'total_fh']; this.alwaysShowCalendars = true;
  }

  ngOnInit(): void {

    this.card_interval = + setInterval(() => {

      if (this.dataShared.geo_location && this.dataShared.map_status && this.dataShared.map_status != "0") {

        this.lat = parseFloat(this.dataShared.geo_location.lat),
          this.lng = parseFloat(this.dataShared.geo_location.lng)

        this.map_status = (this.dataShared.map_status == 1) ? 'd-block' : 'd-none';
      }
    }, 5000);

    this.getDashboardSettings(); this.getFLowUnit(); this.tableDropdownSetting();

    this.eventEmitter.listen('getSelectedSingleDevice', (device_id) => {
      if (this.dataShared.curr_menu == 'dashboard~single') {

        if (device_id != 'N/A') {

          this.device_id = device_id;
          this.resetSinleDevice();

          if (this.curr_tab == 'tab-graph') this.getSingleDeviceGraph();
          if (this.curr_tab == 'tab-overview') {

            this.selectedOverviewParam();

          }

          if (this.curr_tab == 'tab-table') {
            this.resetSinleDevice();
            this.checkDeviceModelStatus();
            this.getSingleDeviceTable();
            this.exportSingleTable();
          }
        }
      }
    });
    this.getCurrDate();
  }

  getDashboardSettings() {

    let level_unit;

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].tab_setting) : JSON.parse(config[0].sub_tab_setting);
        this.tab_trends_status = data.allow_tab.dashboard.single.trends
        this.tab_graph_status = data.allow_tab.dashboard.single.graph
        this.tab_table_status = data.allow_tab.dashboard.single.table
        this.tab_overview_status = data.allow_tab.dashboard.single.overview

        level_unit = JSON.parse(config[0].meta_value);
        this.level_unit = level_unit.level_unit;

      }
    })
  }

  getFLowUnit() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = JSON.parse(config[0].meta_value);
        this.unit = data.flow_unit;
        this.unit_rate = data.flow_rate;
      }
    })
  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  resetSinleDevice() {
    // this.params = [];
    // this.date_from = ''; this.date_to = '';
    this.tabular_data = []; this.getCurrDate();
  }

  getCurrDate() {

    let date = new Date();
    this.date_from = this.vl_from_date_selection = this.curr_from_date_selection = this.kw_from_date_selection = this.pf_from_date_selection = this.f_from_date_selection = this.fh_from_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    this.date_to = this.vl_to_date_selection = this.curr_to_date_selection = this.kw_to_date_selection = this.pf_to_date_selection = this.f_to_date_selection = this.fh_to_date_selection = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');

    this.date_from_trend = this.selected.start['_d'].toISOString().substring(0, 10);
    this.date_to_trend = this.selected.end['_d'].toISOString().substring(0, 10);

    var today = new Date();
    var day = today.getDay() || 7; // Get current day number, converting Sun. to 7
    if (day !== 1) today.setHours(-24 * (day - 1));   // Only manipulate the date if it isn't Mon and Set the hours to day number minus 1

    this.current_date = new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + String(new Date().getDate()).padStart(2, '0');
    this.week_start_date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
    this.month_start_date = new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-01';

    if (this.dataShared.device_id != null && this.curr_tab == 'tab-trends') {
      this.checkDeviceModelStatus();
      // this.getMinMax();
      // this.getGensetPieChartData('Daily');
      // this.getEnergyProfileAndChainAnalysis('Daily');
      // if (this.params_trend.length != 0) {
      // this.getChartDataTrend();
      // }
      // this.getPieChartData('getEnergyHourlyData');
    }
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  checkDeviceModelStatus() {

    let body = {
      'action': 'getDeviceByID',
      'device_id': [this.device_id ? this.device_id : this.dataShared.device_id]
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.status === 200 && res.data[this.device_id ? this.device_id : this.dataShared.device_id]) {

        this.motor_rpm = (res.data[this.device_id ? this.device_id : this.dataShared.device_id].motor_rpm == null) ? 0 : parseInt(res.data[this.device_id ? this.device_id : this.dataShared.device_id].motor_rpm);
        this.max_temp_range = (res.data[this.device_id ? this.device_id : this.dataShared.device_id].max_temp_range == null) ? 0 : parseInt(res.data[this.device_id ? this.device_id : this.dataShared.device_id].max_temp_range);

        this.dropdownList = this.sortAsc(res.data[this.device_id ? this.device_id : this.dataShared.device_id]["parameters"]);

        if (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'VFD') {

          this.energy_status = true;
          this.source_type = false;
          this.flow_meter_status = false;
          this.chain_analysis_chart_status = false
          this.sensor_status = false;
          this.level_status = false;

          this.VFDDropdownSetting();
          this.getMinMax();

          if (this.curr_tab == 'tab-graph') {
            this.getSingleDeviceGraph();
          }

          if (this.curr_tab == 'tab-table') {
            this.tableDropdownSetting()
            this.getSingleDeviceTable();
          }

        } else if (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'Flow Meter') {

          this.energy_status = false;
          this.flow_meter_status = true;
          this.chain_analysis_chart_status = true;
          this.source_type = false;
          this.sensor_status = false;
          this.level_status = false;

          this.flowDropdownSetting();
          this.getInstantFlowRate();

          if (this.curr_tab == 'tab-graph') {
            this.getSingleDeviceGraph();
          }

          if (this.curr_tab == 'tab-table') {
            this.tableDropdownFLowSetting()
            this.getSingleDeviceTable();
          }

        } else if (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'Env Sensor') {

          this.energy_status = false;
          this.flow_meter_status = false;
          this.sensor_status = true;
          this.source_type = false;
          this.chain_analysis_chart_status = false;
          this.level_status = false;

          this.sensorDropdownSetting();
          this.tableSensorDropdownSetting();
          this.getMinMax();

          if (this.curr_tab == 'tab-graph') {
            this.getSingleDeviceGraph();
          }


        } else if (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'Level') {

          this.energy_status = false;
          this.flow_meter_status = false;
          this.sensor_status = false;
          this.level_status = true;
          this.source_type = false;
          this.chain_analysis_chart_status = false;

          this.levelDropdownSetting();
          this.getRefellingPieChartData('Daily')
          this.getConsumptionPieChartData('Daily')
          this.tableLevelDropdownSetting()
          // this.getMinMax();

          if (this.curr_tab == 'tab-graph') {
            this.getSingleDeviceGraph();
          }


        } else {

          this.energy_status = true;
          this.flow_meter_status = false;
          this.source_type = (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'Dual Source') ? true : false;
          this.chain_analysis_chart_status = true;
          this.sensor_status = false;
          this.level_status = false;

          this.table_trend_class = (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'Inverter') ? 'col-xl-8' : 'col-xl-12';
          this.inverter_trend_status = (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'Inverter') ? 'd-block' : 'd-none';
          if (res.data[this.device_id ? this.device_id : this.dataShared.device_id].device_model == 'Inverter') this.getInverterTable();

          this.DropdownSetting();
          this.getMinMax();

          if (this.curr_tab == 'tab-graph') {
            this.getSingleDeviceGraph();
          }

          if (this.curr_tab == 'tab-table') {
            this.tableDropdownSetting()
            this.getSingleDeviceTable();
          }
        }

        this.getPieChartData('getEnergyHourlyData');
        this.getGensetPieChartData('Daily');
        this.getEnergyProfileAndChainAnalysis('Daily')

        if (this.curr_tab == 'tab-table') {
          this.getSingleDeviceTable();
          this.exportSingleTable();
        }

        if (this.params_trend.length != 0) {
          this.getChartDataTrend();
        }
      }
    })
  }

  DropdownSetting() {

    this.min_max_name = { card_1: 'Avg. Line Voltage VL-L', card_2: 'Avg. 3 Phase Current', card_3: 'Total Active Power', card_4: 'Power Factor 3 Phase' };
    this.min_max_unit = { card_1: 'V', card_2: 'A', card_3: 'Kw', card_4: '' };

    // this.dropdownList = this.dataShared.device_params;
    // if (this.dropdownList.length > 0) {

    this.params_trend = ['total_w'];

    this.selectedItems_trend = [
      { parameter: 'total_w', description: 'Total Active Power' }

    ];

    if (this.graph_type != 'all') {
      this.params_trend.push('avg_curr_3p', 'avg_vl_pp', 'avg_pf_3p');
      this.selectedItems_trend.push(
        { parameter: 'avg_curr_3p', description: 'Avg. 3 Phase Current (Amps)' },
        { parameter: 'avg_vl_pp', description: 'Avg. Line Voltage VL-L (Volts)' },
        { parameter: (this.chain_analysis_chart_status == false) ? 'mot_spd' : 'avg_pf_3p', description: (this.chain_analysis_chart_status == false) ? 'Motor Speed Used' : 'Power Factor 3 Phase (Avg)' })
    }

    this.dropdownSettings_trend = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 6,
      searchPlaceholderText: 'Select parmeters',
    };
    this.getParamUnit(this.params_trend)
    if (this.graph_type == 'all') this.dropdownSettings_trend['singleSelection'] = true;
    // }
  }

  VFDDropdownSetting() {

    this.min_max_name = { card_1: 'Voltage', card_2: 'Current', card_3: 'Power', card_4: 'Speed' };
    this.min_max_unit = { card_1: 'V', card_2: 'A', card_3: 'Kw', card_4: 'rpm' };

    // this.dropdownList = this.dataShared.device_params;
    // if (this.dropdownList.length > 0) {

    this.params_trend = ['total_w'];

    this.selectedItems_trend = [
      { parameter: 'total_w', description: 'Total Active Power' }

    ];

    if (this.graph_type != 'all') {
      this.params_trend.push('avg_curr_3p', 'avg_vl_pp', 'mot_spd');
      this.selectedItems_trend.push(
        { parameter: 'avg_curr_3p', description: 'Avg. 3 Phase Current (Amps)' },
        { parameter: 'avg_vl_pp', description: 'Avg. Line Voltage VL-L (Volts)' },
        { parameter: 'mot_spd', description: 'Motor Speed Used' })
    }

    this.dropdownSettings_trend = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 6,
      searchPlaceholderText: 'Select parmeters',
    };
    this.getParamUnit(this.params_trend)
    if (this.graph_type == 'all') this.dropdownSettings_trend['singleSelection'] = true;
    // }
  }

  flowDropdownSetting() {

    this.min_max_name = { card_1: 'Voltage', card_2: 'Current', card_3: 'Power', card_4: 'Speed' };
    this.min_max_unit = { card_1: 'V', card_2: 'A', card_3: 'Kw', card_4: 'rpm' };

    // this.dropdownList = this.dataShared.device_params;
    // if (this.dropdownList.length > 0) {

    this.params_trend = ['total_f'];

    this.selectedItems_trend = [
      { parameter: 'total_f', description: 'Instant Flow Rate', unit: this.unit_rate }

    ];

    if (this.graph_type != 'all') {
      this.params_trend.push('total_fh');
      this.selectedItems_trend.push(
        { parameter: 'total_fh', description: 'Total Flow', unit: this.unit })
    }

    this.dropdownSettings_trend = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 2,
      searchPlaceholderText: 'Select parmeters',
    };
    this.getParamUnit(this.params_trend)
    if (this.graph_type == 'all') this.dropdownSettings_trend['singleSelection'] = true;
    // }
  }

  sensorDropdownSetting() {

    this.min_max_name = { card_1: 'Temperature', card_2: 'Humidity', card_3: 'Pressure', card_4: 'CO2' };
    this.min_max_unit = { card_1: '\xB0C', card_2: '%', card_3: 'Kpa', card_4: 'ppm' };

    // this.dropdownList = this.dataShared.device_params;
    // if (this.dropdownList.length > 0) {

    this.params_trend = ['temp'];

    this.selectedItems_trend = [
      { parameter: 'temp', description: 'Temperature', unit: '\xB0C' }

    ];

    if (this.graph_type != 'all') {
      this.params_trend.push('humid', 'airp', "co_2");
      this.selectedItems_trend.push(
        { parameter: 'humid', description: 'Humidity', unit: '%' },
        { parameter: 'airp', description: 'Air Pressure', unit: 'Kpa' },
        { parameter: 'co_2', description: 'Carbon Dioxide CO2', unit: 'ppm' }
      )
    }

    this.dropdownSettings_trend = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 4,
      searchPlaceholderText: 'Select parmeters',
    };
    this.getParamUnit(this.params_trend)
    if (this.graph_type == 'all') this.dropdownSettings_trend['singleSelection'] = true;
    // }
  }

  
  tableSensorDropdownSetting() {

    this.params = ['temp', 'humid', 'airp', "co_2"];

    this.selectedItems = [
      { parameter: 'temp', description: 'Temperature', unit: '\xB0C' },
      { parameter: 'humid', description: 'Humidity', unit: '%' },
      { parameter: 'airp', description: 'Air Pressure', unit: 'Kpa' },
      { parameter: 'co_2', description: 'Carbon Dioxide CO2', unit: 'ppm' }

    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 4,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 6,
      searchPlaceholderText: 'Select parmeters',
    };
  }

  levelDropdownSetting() {

    this.dropdownList = this.dataShared.device_params;
    // if (this.dropdownList.length > 0) {

    this.params_trend = ['volume'];

    this.selectedItems_trend = [
      { parameter: 'volume', description: 'Volume Filled', unit: this.level_unit }

    ];

    if (this.graph_type != 'all') {
      this.params_trend.push('total_cr', "total_cc");
      this.selectedItems_trend.push(
        { parameter: 'total_cr', description: 'Refilling Counter', unit: this.level_unit },
        { parameter: 'total_cc', description: 'Consumption Counter', unit: this.level_unit }
      )
    }

    this.dropdownSettings_trend = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 3,
      searchPlaceholderText: 'Select parmeters',
    };
    this.getParamUnit(this.params_trend)
    if (this.graph_type == 'all') this.dropdownSettings_trend['singleSelection'] = true;
    // }

    // let props = this.getGraphProp('tank_capacity', []);

    // run in every 30 seconds
    this.interval = + setInterval(() => {
      // this.implementPictorialSliceShape();
      this.implementCylinderGauge();
    }, 30000);
  }

  tableLevelDropdownSetting() {

    this.params = ['volume', 'total_cr', "total_cc"];

    this.selectedItems = [
      { parameter: 'volume', description: 'Volume Filled', unit: this.level_unit },
      { parameter: 'total_cr', description: 'Refilling Counter', unit: this.level_unit },
      { parameter: 'total_cc', description: 'Consumption Counter', unit: this.level_unit }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'parameter',
      textField: 'description',
      showSelectedItemsAtTop: true,
      itemsShowLimit: 4,
      allowSearchFilter: true,
      clearSearchFilter: true,
      limitSelection: 6,
      searchPlaceholderText: 'Select parmeters',
    };

  }

  getParamUnit(param) {

    this.units = [];
    let body = {
      "action": "getParamsUnit",
      "parameter": param
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.units = res.data
    })

    this.getDeviceUnit();
  }

  getDeviceUnit() {

    this.device_unit = '';
    let body = {
      "action": "getDeviceUnit",
      "device_id": this.device_id ? this.device_id : this.dataShared.device_id
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.device_unit = res.data
    })
  }

  getChartDataTrend() {

    this.dataShared.fixed_interval = this.fixed_interval; let chart_data = []; let export_data = []; let data = []; this.action = 'getChartDataByAccount';
    this.date_from_trend = this.selected.start['_d'].toISOString().substring(0, 10);
    this.date_to_trend = this.selected.end['_d'].toISOString().substring(0, 10);

    this.dashboardService.httpPost(this.dataShared.getBody(this.action, this.date_from_trend, this.date_to_trend, this.params_trend, '')).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          for (let i = 0; i < this.params_trend.length; i++) {

            for (let j = 0; j < data.length; j++) {

              if (this.graph_type == 'all') {

                chart_data.push({
                  date: new Date(data[j].key_as_string),
                  avg: data[j]['val_' + this.params_trend[i]].value,
                  min: data[j]['min_' + this.params_trend[i]].value,
                  max: data[j]['max_' + this.params_trend[i]].value
                });

              } else {

                chart_data.push({
                  ['date_' + i]: new Date(data[j].key_as_string),
                  ['value_' + i]: (data[j][this.graph_type + this.params_trend[i]].value) ? data[j][this.graph_type + this.params_trend[i]].value : 0
                });

              }
            }
          }

          let units = [];

          if (this.selectedItems_trend[0].unit) {

            this.selectedItems_trend.forEach(element => {
              units.push(element.unit);
            });

          } else {
            this.units = units;
          }

          this.chart_data = chart_data;
          this.export_data = export_data;
          let props = this.amcharts.getGraphProp(chart_data, 'line');
          this.amcharts.implementComparativeChart(props, this.params_trend, units);

          this.getTable();
        }
      } else {


        let props = this.amcharts.getGraphProp(chart_data, 'line');
        this.amcharts.implementComparativeChart(props, this.params_trend, this.units);
        this.getTable();
      }
    });
  }

  getEnergyProfileAndChainAnalysis(type) {

    this.energy_consumption.current = 0.00; this.energy_consumption.last = 0.00; let data = [];

    if (type == 'Daily') this.getPieChartData('getEnergyHourlyData');
    if (type == 'Weekly') this.getPieChartData('getEnergyDailyData');
    if (type == 'Monthly') this.getPieChartData('getEnergyWeeklyData');
    if (type == 'Yearly') this.getPieChartData('getEnergyYearlyData');

    this.dashboardService.httpPost(this.dataShared.getBody('getEnergyProfileandChainAnalysis', '', '', this.getPieParameters()[1], type)).subscribe((res: any) => {

      if (res.data.current) {

        this.energy_consumption.current = res.data.current; this.energy_consumption.last = res.data.last;

        data.push({ category: 'Current', value: res.data.current }, { category: 'Last', value: res.data.last })

        // let props = this.getGraphProp('', data);

        if (this.chain_analysis_chart_status == false) {
          let props = this.getGraphProp('gauge', data);
          this.implementAnimatedGauge(props);

        } else {
          let props = this.getGraphProp('', data);
          this.implementBarVarianceChart(props);
        }

        // if (this.flow_meter_status) {
        //   let props = this.getGraphProp('gauge', data);
        //   this.implementAnimatedGauge(props);
        // }

      } else {

        data.push({ category: 'Current', value: 0 }, { category: 'Last', value: 0 })

        if (this.chain_analysis_chart_status == false) {
          let props = this.getGraphProp('gauge', data);
          this.implementAnimatedGauge(props);

        } else {
          let props = this.getGraphProp('', data);
          this.implementBarVarianceChart(props);
        }

        // if (this.flow_meter_status) {
        //   let props = this.getGraphProp('gauge', data);
        //   this.implementAnimatedGauge(props);
        // }

      }
    })
  }

  implementBarVarianceChart(data) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;
    chart.data = data.data;

    // Populate data
    for (var i = 0; i < (chart.data.length - 1); i++) {
      chart.data[i].valueNext = chart.data[i + 1].value;
    }

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.heatRules.push({
      "target": series.columns.template,
      "property": "stroke",
      "min": am4core.color("green"),
      "max": am4core.color("red"),
      "dataField": "valueY"
    });
    series.heatRules.push({
      "target": series.columns.template,
      "property": "fill",
      "min": am4core.color("green"),
      "max": am4core.color("red"),
      "dataField": "valueY"
    });

    // Add series for showing variance arrows
    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "valueNext";
    series2.dataFields.openValueY = "value";
    series2.dataFields.categoryX = "category";
    series2.columns.template.width = 1;
    series2.fill = am4core.color("#555");
    series2.stroke = am4core.color("#555");

    // Add a triangle for arrow tip
    let arrow = series2.bullets.push(new am4core.Triangle);
    arrow.width = 10;
    arrow.height = 10;
    arrow.horizontalCenter = "middle";
    arrow.verticalCenter = "top";
    arrow.dy = -1;

    // Set up a rotation adapter which would rotate the triangle if its a negative change
    arrow.adapter.add("rotation", function (rotation, target) {
      return getVariancePercent(target.dataItem) < 0 ? 180 : rotation;
    });

    // Set up a rotation adapter which adjusts Y position
    arrow.adapter.add("dy", function (dy, target) {
      return getVariancePercent(target.dataItem) < 0 ? 1 : dy;
    });

    // Add a label
    let label = series2.bullets.push(new am4core.Label);
    label.padding(10, 10, 10, 10);
    label.text = "";
    label.fill = am4core.color("#0c0");
    label.strokeWidth = 0;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.fontWeight = "bolder";

    // Adapter for label text which calculates change in percent
    label.adapter.add("textOutput", function (text, target) {
      let percent = getVariancePercent(target.dataItem);
      return percent ? percent + "%" : text;
    });

    // Adapter which shifts the label if it's below the variance column
    label.adapter.add("verticalCenter", function (center, target) {
      return getVariancePercent(target.dataItem) < 0 ? "top" : center;
    });

    // Adapter which changes color of label to red
    label.adapter.add("fill", function (fill, target) {
      return getVariancePercent(target.dataItem) < 0 ? am4core.color("#c00") : fill;
    });

    function getVariancePercent(dataItem) {
      if (dataItem) {
        let value = dataItem.valueY;
        let openValue = dataItem.openValueY;
        let change = value - openValue;
        return Math.round(change / openValue * 100);
      }
      return 0;
    }
  }

  getStartDateAndType(action) {
    let date; let type;
    if (action == 'getEnergyHourlyData') { type = 'Today'; date = this.current_date; }
    else if (action == 'getEnergyDailyData') { type = 'Weekly'; date = this.week_start_date; }
    else if (action == 'getEnergyWeeklyData') { type = 'Monthly'; date = this.month_start_date; }
    else if (action == 'getEnergyYearlyData') { type = 'Yearly'; date = new Date().getFullYear(); }
    return [date, type];
  }

  getPieParameters() {
    let parameters = [];
    if (this.params_trend[0] == ('total_f' || 'total_fh')) { parameters = []; parameters = ['@timestamp', 'total_fh'] }
    else { parameters = []; parameters = ['@timestamp', 'total_wh'] }
    return parameters;
  }

  getPieChartData(action) {

    let pie_chart_data = []; this.action = action; let start_date = this.getStartDateAndType(action)[0];

    this.dashboardService.httpPost(this.dataShared.getBody(this.action, start_date, this.current_date, this.getPieParameters(), '')).subscribe((res: any) => {

      let first_data = res.data.first.device_1; let last_data = res.data.last.device_1;

      for (let i = 0; i < last_data.length; i++) {

        if (i != 0) {

          if (first_data[i][this.getPieParameters()[1]] > last_data[i][this.getPieParameters()[1]]) {
            last_data[i][this.getPieParameters()[1]] = first_data[i][this.getPieParameters()[1]];
          }
          if (first_data[i][this.getPieParameters()[1]] == 0 || first_data[i][this.getPieParameters()[1]] == undefined) {
            first_data[i][this.getPieParameters()[1]] = last_data[i][this.getPieParameters()[1]];
          }
          if (last_data[i][this.getPieParameters()[1]] == undefined || first_data[i][this.getPieParameters()[1]] == undefined) {
            first_data[i][this.getPieParameters()[1]] = last_data[i][this.getPieParameters()[1]] = 0;
          }

          let date;

          if (action == 'getEnergyHourlyData') date = new Date(last_data[i]['@timestamp']).toLocaleTimeString();
          if (action == 'getEnergyDailyData') date = new Date(last_data[i]['@timestamp']);
          if (action == 'getEnergyWeeklyData') { date = 'week' + (i + 1) }
          if (action == 'getEnergyHourlyData') new Date(last_data[i]['@timestamp']).toLocaleTimeString();

          pie_chart_data.push({
            date: (action == 'getEnergyHourlyData') ? new Date(last_data[i]['@timestamp']).toLocaleTimeString() : new Date(last_data[i]['@timestamp']),
            first: first_data[i][this.getPieParameters()[1]],
            last: last_data[i][this.getPieParameters()[1]],
            diff: last_data[i][this.getPieParameters()[1]] - first_data[i][this.getPieParameters()[1]],
          });
        }
      }
      this.pie_chart_sum = 0.00;

      this.pie_chart_sum = pie_chart_data.map(a => a.diff).reduce(function (a, b) {
        return a + b;
      });
      this.energy_profile_type = this.getStartDateAndType(action)[1];
      let props = this.pieChart.getGraphProp(pie_chart_data);
      this.pieChart.implementChart(props);
      this.implementGaugeWithTwoAxes();
    })

  }

  getGensetPieChartData(type) {

    let action = '';
    if (type == 'Daily') action = 'getEnergyHourlyData';
    if (type == 'Weekly') action = 'getEnergyDailyData';
    if (type == 'Monthly') action = 'getEnergyWeeklyData';
    if (type == 'Yearly') action = 'getEnergyYearlyData';

    let pie_chart_data = []; let start_date = this.getStartDateAndType(action)[0];

    this.dashboardService.httpPost(this.dataShared.getBody(action, start_date, this.current_date, ['@timestamp', 'total_wh_g'], '')).subscribe((res: any) => {

      let first_data = res.data.first.device_1; let last_data = res.data.last.device_1;

      for (let i = 1; i < last_data.length; i++) {

        if (first_data[i] != null && first_data[i].total_wh_g != undefined) {
          if (first_data[i].total_wh_g > last_data[i].total_wh_g) {
            last_data[i].total_wh_g = first_data[i].total_wh_g;
          }
          if (first_data[i].total_wh_g == 0 || first_data[i].total_wh_g == undefined) {
            first_data[i].total_wh_g = last_data[i].total_wh_g;
          }
          if (last_data[i].total_wh_g == undefined || first_data[i].total_wh_g == undefined) {
            first_data[i].total_wh_g = last_data[i].total_wh_g = 0;
          }
        }

        let date;

        if (action == 'getEnergyHourlyData') date = new Date(last_data[i]['@timestamp']).toLocaleTimeString();
        if (action == 'getEnergyDailyData') date = new Date(last_data[i]['@timestamp']);
        if (action == 'getEnergyWeeklyData') { date = 'week' + (i + 1) }
        if (action == 'getEnergyHourlyData') new Date(last_data[i]['@timestamp']).toLocaleTimeString();

        pie_chart_data.push({
          date: (action == 'getEnergyHourlyData') ? new Date(last_data[i]['@timestamp']).toLocaleTimeString() : new Date(last_data[i]['@timestamp']),
          first: (first_data[i].total_wh_g) ? first_data[i].total_wh_g : 0,
          last: (last_data[i].total_wh_g) ? last_data[i].total_wh_g : 0,
          diff: (last_data[i].total_wh_g && first_data[i].total_wh_g) ? last_data[i].total_wh_g - first_data[i].total_wh_g : 0
        });
      }
      this.genset_pie_chart_sum = 0.00;

      this.genset_pie_chart_sum = pie_chart_data.map(a => a.diff).reduce(function (a, b) {
        return a + b;
      });

      this.genset_energy_profile_type = this.getStartDateAndType(action)[1];
      let genset_props = this.getGraphProp('genset', pie_chart_data);
      this.implementPieChart(genset_props);
    })
  }

  getLevelPieChartData(type) {

    this.getRefellingPieChartData(type)
    this.getConsumptionPieChartData(type);
  }

  getRefellingPieChartData(type) {

    let action = '';
    if (type == 'Daily') action = 'getEnergyHourlyData';
    if (type == 'Weekly') action = 'getEnergyDailyData';
    if (type == 'Monthly') action = 'getEnergyWeeklyData';
    if (type == 'Yearly') action = 'getEnergyYearlyData';

    let pie_chart_data = []; let start_date = this.getStartDateAndType(action)[0];

    this.dashboardService.httpPost(this.dataShared.getBody(action, start_date, this.current_date, ['@timestamp', 'total_cr'], '')).subscribe((res: any) => {

      let first_data = res.data.first.device_1; let last_data = res.data.last.device_1;


      for (let i = 1; i < last_data.length; i++) {

        // if (first_data[i] != null && first_data[i].total_cr != undefined) {
        //   if (first_data[i].total_cr > last_data[i].total_cr) {
        //     last_data[i].total_cr = first_data[i].total_cr;
        //   }
        //   if (first_data[i].total_cr == 0 || first_data[i].total_cr == undefined) {
        //     first_data[i].total_cr = last_data[i].total_cr;
        //   }
        //   if (last_data[i].total_cr == undefined || first_data[i].total_cr == undefined) {
        //     first_data[i].total_cr = last_data[i].total_cr = 0;
        //   }
        // }

        let date;

        if (action == 'getEnergyHourlyData') date = new Date(last_data[i]['@timestamp']).toLocaleTimeString();
        if (action == 'getEnergyDailyData') date = new Date(last_data[i]['@timestamp']);
        if (action == 'getEnergyWeeklyData') { date = 'week' + (i + 1) }
        if (action == 'getEnergyHourlyData') new Date(last_data[i]['@timestamp']).toLocaleTimeString();

        pie_chart_data.push({
          date: (action == 'getEnergyHourlyData') ? new Date(last_data[i]['@timestamp']).toLocaleTimeString() : new Date(last_data[i]['@timestamp']),
          first: (first_data[i].total_cr) ? first_data[i].total_cr : 0,
          last: (last_data[i].total_cr) ? last_data[i].total_cr : 0,
          diff: last_data[i].total_cr - first_data[i].total_cr
        });
      }
      this.refelling_pie_chart_sum = 0.00;

      this.refelling_pie_chart_sum = pie_chart_data.map(a => a.diff).reduce(function (a, b) {
        return a + b;
      });

      this.refelling_energy_profile_type = this.getStartDateAndType(action)[1];
      let props = this.getGraphProp('refelling', pie_chart_data);
      this.implementPieChart(props);
    })
  }

  getConsumptionPieChartData(type) {

    let action = '';
    if (type == 'Daily') action = 'getEnergyHourlyData';
    if (type == 'Weekly') action = 'getEnergyDailyData';
    if (type == 'Monthly') action = 'getEnergyWeeklyData';
    if (type == 'Yearly') action = 'getEnergyYearlyData';

    let pie_chart_data = []; let start_date = this.getStartDateAndType(action)[0];

    this.dashboardService.httpPost(this.dataShared.getBody(action, start_date, this.current_date, ['@timestamp', 'total_cc'], '')).subscribe((res: any) => {

      let first_data = res.data.first.device_1; let last_data = res.data.last.device_1;


      for (let i = 1; i < last_data.length; i++) {

        // if (first_data[i] != null && first_data[i].total_cc != undefined) {
        //   if (first_data[i].total_cc > last_data[i].total_cc) {
        //     last_data[i].total_cc = first_data[i].total_cc;
        //   }
        //   if (first_data[i].total_cc == 0 || first_data[i].total_cc == undefined) {
        //     first_data[i].total_cc = last_data[i].total_cc;
        //   }
        //   if (last_data[i].total_cc == undefined || first_data[i].total_cc == undefined) {
        //     first_data[i].total_cc = last_data[i].total_cc = 0;
        //   }
        // }
        let date;

        if (action == 'getEnergyHourlyData') date = new Date(last_data[i]['@timestamp']).toLocaleTimeString();
        if (action == 'getEnergyDailyData') date = new Date(last_data[i]['@timestamp']);
        if (action == 'getEnergyWeeklyData') { date = 'week' + (i + 1) }
        if (action == 'getEnergyHourlyData') new Date(last_data[i]['@timestamp']).toLocaleTimeString();

        pie_chart_data.push({
          date: (action == 'getEnergyHourlyData') ? new Date(last_data[i]['@timestamp']).toLocaleTimeString() : new Date(last_data[i]['@timestamp']),
          first: (first_data[i].total_cc) ? first_data[i].total_cc : 0,
          last: (last_data[i].total_cc) ? last_data[i].total_cc : 0,
          diff: last_data[i].total_cc - first_data[i].total_cc
        });
      }

      this.consumption_pie_chart_sum = 0.00;
      this.consumption_pie_chart_sum = pie_chart_data.map(a => a.diff).reduce(function (a, b) {
        return a + b;
      });

      this.consumption_energy_profile_type = this.getStartDateAndType(action)[1];
      let props = this.getGraphProp('consumption', pie_chart_data);
      this.implementPieChart(props);
    })
  }

  implementPieChart(data) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.PieChart);
    chart.data = data.data;

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "diff";
    pieSeries.dataFields.category = "date";
    pieSeries.labels.template.text = "";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.fillOpacity = 100;
    pieSeries.strokeWidth = 0;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.cornerRadius = 5;

    chart.hiddenState.properties.radius = am4core.percent(0);
  }

  getMinMax() {

    this.dataShared.fixed_interval = '1d'; let chart_data = []; let data = [];
    this.action = 'getChartDataByAccount';

    this.dashboardService.httpPost(this.dataShared.getBody(this.action, this.current_date, this.current_date, ["avg_vl_pp", "avg_curr_3p", "total_w", "avg_pf_3p", "mot_spd", "temp", "humid", "airp", "co_2"], '')).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          if (data[0].max_mot_spd.value != null && data[0].min_mot_spd.value != null) {

            this.min_max = {
              card_1_min: (data[0].min_avg_vl_pp.value != null) ? data[0].min_avg_vl_pp.value : 0,
              card_1_max: (data[0].max_avg_vl_pp.value != null) ? data[0].max_avg_vl_pp.value : 0,
              card_2_min: (data[0].min_avg_curr_3p.value != null) ? data[0].min_avg_curr_3p.value : 0,
              card_2_max: (data[0].max_avg_curr_3p.value != null) ? data[0].max_avg_curr_3p.value : 0,
              card_3_min: (data[0].min_total_w.value != null) ? data[0].min_total_w.value : 0,
              card_3_max: (data[0].max_total_w.value != null) ? data[0].max_total_w.value : 0,
              card_4_min: (data[0].min_mot_spd.value != null) ? data[0].min_mot_spd.value : 0,
              card_4_max: (data[0].max_mot_spd.value != null) ? data[0].max_mot_spd.value : 0
            }

          } else if (data[0].min_temp.value != null && data[0].max_humid.value != null) {

            this.min_max = {
              card_1_min: (data[0].min_temp.value != null) ? data[0].min_temp.value : 0,
              card_1_max: (data[0].max_temp.value != null) ? data[0].max_temp.value : 0,
              card_2_min: (data[0].min_humid.value != null) ? data[0].min_humid.value : 0,
              card_2_max: (data[0].max_humid.value != null) ? data[0].max_humid.value : 0,
              card_3_min: (data[0].min_airp.value != null) ? data[0].min_airp.value : 0,
              card_3_max: (data[0].max_airp.value != null) ? data[0].max_airp.value : 0,
              card_4_min: (data[0].min_co_2.value != null) ? data[0].min_co_2.value : 0,
              card_4_max: (data[0].max_co_2.value != null) ? data[0].max_co_2.value : 0
            }
          } else {

            this.min_max = {
              card_1_min: (data[0].min_avg_vl_pp.value != null) ? data[0].min_avg_vl_pp.value : 0,
              card_1_max: (data[0].max_avg_vl_pp.value != null) ? data[0].max_avg_vl_pp.value : 0,
              card_2_min: (data[0].min_avg_curr_3p.value != null) ? data[0].min_avg_curr_3p.value : 0,
              card_2_max: (data[0].max_avg_curr_3p.value != null) ? data[0].max_avg_curr_3p.value : 0,
              card_3_min: (data[0].min_total_w.value != null) ? data[0].min_total_w.value : 0,
              card_3_max: (data[0].max_total_w.value != null) ? data[0].max_total_w.value : 0,
              card_4_min: (data[0].min_avg_pf_3p.value != null) ? data[0].min_avg_pf_3p.value : 0,
              card_4_max: (data[0].max_avg_pf_3p.value != null) ? data[0].max_avg_pf_3p.value : 0
            }
          }
        }
      } else {
        this.min_max = { card_1_min: 0.00, card_1_max: 0.00, card_2_min: 0.00, card_2_max: 0.00, card_3_min: 0.00, card_3_max: 0.00, card_4_min: 0.00, card_4_max: 0.00 }
      }
    });
  }

  getInstantFlowRate() {

    let body = {
      "action": "getDeviceByID",
      "device_id": [this.dataShared.device_id]
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {

      let data = res.data[this.dataShared.device_id];
      let props = this.getGraphProp('gauge', data);
      this.implementAnimatedGauge(props);

    })
  }

  getTable() {

    this.tabular_data_trend = []; let data = []; let tabular_data_trend = [];
    this.action = 'getTabularDataByAccount';

    this.dashboardService.httpPost(this.dataShared.getBody(this.action, this.date_from_trend, this.date_to_trend, this.params_trend, '')).subscribe((res: any) => {

      if (res.data.hits) {
        data = res.data.hits;
        data.forEach(data => {
          data['@timestamp'] = data['timestamp'] = (new Date(data['@timestamp']).toLocaleString());
          delete data['@timestamp'];

          tabular_data_trend.push(data);

          this.tabular_data_trend = tabular_data_trend.filter((arr, index, self) =>
            index === self.findIndex((t) => (t.timestamp === arr.timestamp)))

        });
        this.total_found_trend = res.data.total_found;
      } else {
        this.tabular_data_trend = [];
      }
    });
  }

  getInverterTable() {

    this.inverter_table = []; this.inverter_mppt_table = []; this.last_updated_inverter_table = '';

    let body = {
      "action": "getTabularDataByAccount",
      "user_id": [
        localStorage.getItem('user')
      ],
      "device_id": [
        (this.device_id) ? this.device_id : this.dataShared.device_id
      ],
      "range": {
        "from": this.date_from,
        "to": this.date_to
      },
      "parameters": [],
      "sort": "desc",
      "from": 0,
      "size": 1
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.data.hits[0]) {

        let data = res.data.hits[0]


        this.inverter_table.push(
          {
            string: "PV1",
            voltage: (data.pv1) ? data.pv1 : 0.00,
            current: (data.pc1) ? data.pc1 : 0.00,
          },
          {
            string: "PV2",
            voltage: (data.pv2) ? data.pv2 : 0.00,
            current: (data.pc2) ? data.pc2 : 0.00,
          },
          {
            string: "PV3",
            voltage: (data.pv3) ? data.pv3 : 0.00,
            current: (data.pc3) ? data.pc3 : 0.00,
          },
          {
            string: "PV4",
            voltage: (data.pv4) ? data.pv4 : 0.00,
            current: (data.pc4) ? data.pc4 : 0.00,
          },
          {
            string: "PV5",
            voltage: (data.pv5) ? data.pv5 : 0.00,
            current: (data.pc5) ? data.pc5 : 0.00,
          },
          {
            string: "PV6",
            voltage: (data.pv6) ? data.pv6 : 0.00,
            current: (data.pc6) ? data.pc6 : 0.00,
          },
          {
            string: "PV7",
            voltage: (data.pv7) ? data.pv7 : 0.00,
            current: (data.pc7) ? data.pc7 : 0.00,
          },
          {
            string: "PV8",
            voltage: (data.pv8) ? data.pv8 : 0.00,
            current: (data.pc8) ? data.pc8 : 0.00,
          },
          {
            string: "PV9",
            voltage: (data.pv9) ? data.pv9 : 0.00,
            current: (data.pc9) ? data.pc9 : 0.00,
          },
          {
            string: "PV10",
            voltage: (data.pv10) ? data.pv10 : 0.00,
            current: (data.pc10) ? data.pc10 : 0.00,
          },
          {
            string: "PV11",
            voltage: (data.pv11) ? data.pv11 : 0.00,
            current: (data.pc11) ? data.pc11 : 0.00,
          },
          {
            string: "PV12",
            voltage: (data.pv12) ? data.pv12 : 0.00,
            current: (data.pc12) ? data.pc12 : 0.00,
          },
          {
            string: "PV13",
            voltage: (data.pv13) ? data.pv13 : 0.00,
            current: (data.pc13) ? data.pc13 : 0.00,
          },
          {
            string: "PV14",
            voltage: (data.pv14) ? data.pv14 : 0.00,
            current: (data.pc14) ? data.pc14 : 0.00,
          },
          {
            string: "PV15",
            voltage: (data.pv15) ? data.pv15 : 0.00,
            current: (data.pc15) ? data.pc15 : 0.00,
          },
          {
            string: "PV16",
            voltage: (data.pv16) ? data.pv16 : 0.00,
            current: (data.pc16) ? data.pc16 : 0.00,
          },
          {
            string: "PV17",
            voltage: (data.pv17) ? data.pv17 : 0.00,
            current: (data.pc17) ? data.pc17 : 0.00,
          },
          {
            string: "PV18",
            voltage: (data.pv18) ? data.pv18 : 0.00,
            current: (data.pc18) ? data.pc18 : 0.00,
          },
          {
            string: "PV19",
            voltage: (data.pv19) ? data.pv19 : 0.00,
            current: (data.pc19) ? data.pc19 : 0.00,
          },
          {
            string: "PV20",
            voltage: (data.pv20) ? data.pv20 : 0.00,
            current: (data.pc20) ? data.pc20 : 0.00,
          },
          {
            string: "PV21",
            voltage: (data.pv21) ? data.pv21 : 0.00,
            current: (data.pc21) ? data.pc21 : 0.00,
          },
          {
            string: "PV22",
            voltage: (data.pv22) ? data.pv22 : 0.00,
            current: (data.pc22) ? data.pc22 : 0.00,
          },
          {
            string: "PV23",
            voltage: (data.pv23) ? data.pv23 : 0.00,
            current: (data.pc23) ? data.pc23 : 0.00,
          },
          {
            string: "PV24",
            voltage: (data.pv24) ? data.pv24 : 0.00,
            current: (data.pc24) ? data.pc24 : 0.00,
          },
          {
            string: "PV25",
            voltage: (data.pv25) ? data.pv25 : 0.00,
            current: (data.pc25) ? data.pc25 : 0.00,
          }
        )

        this.inverter_mppt_table.push(
          {
            string: "MPV1",
            voltage: (data.mpv1) ? data.mpv1 : 0.00,
            current: (data.mpc1) ? data.mpc1 : 0.00,
          },
          {
            string: "MPV2",
            voltage: (data.mpv2) ? data.mpv2 : 0.00,
            current: (data.mpc2) ? data.mpc2 : 0.00,
          },
          {
            string: "MPV3",
            voltage: (data.mpv3) ? data.mpv3 : 0.00,
            current: (data.mpc3) ? data.mpc3 : 0.00,
          },
          {
            string: "MPV4",
            voltage: (data.mpv4) ? data.mpv4 : 0.00,
            current: (data.mpc4) ? data.mpc4 : 0.00,
          },
          {
            string: "MPV5",
            voltage: (data.mpv5) ? data.mpv5 : 0.00,
            current: (data.mpc5) ? data.mpc5 : 0.00,
          },
          {
            string: "MPV6",
            voltage: (data.mpv6) ? data.mpv6 : 0.00,
            current: (data.mpc6) ? data.mpc6 : 0.00,
          },
          {
            string: "MPV7",
            voltage: (data.mpv7) ? data.mpv7 : 0.00,
            current: (data.mpc7) ? data.mpc7 : 0.00,
          },
          {
            string: "MPV8",
            voltage: (data.mpv8) ? data.mpv8 : 0.00,
            current: (data.mpc8) ? data.mpc8 : 0.00,
          },
          {
            string: "MPV9",
            voltage: (data.mpv9) ? data.mpv9 : 0.00,
            current: (data.mpc9) ? data.mpc9 : 0.00,
          },
          {
            string: "MPV10",
            voltage: (data.mpv10) ? data.mpv10 : 0.00,
            current: (data.mpc10) ? data.mpc10 : 0.00,
          },
          {
            string: "MPV11",
            voltage: (data.mpv11) ? data.mpv11 : 0.00,
            current: (data.mpc11) ? data.mpc11 : 0.00,
          },
          {
            string: "MPV12",
            voltage: (data.mpv12) ? data.mpv12 : 0.00,
            current: (data.mpc12) ? data.mpc12 : 0.00,
          },
          {
            string: "MPV13",
            voltage: (data.mpv13) ? data.mpv13 : 0.00,
            current: (data.mpc13) ? data.mpc13 : 0.00,
          },
          {
            string: "MPV14",
            voltage: (data.mpv14) ? data.mpv14 : 0.00,
            current: (data.mpc14) ? data.mpc14 : 0.00,
          },
          {
            string: "MPV15",
            voltage: (data.mpv15) ? data.mpv15 : 0.00,
            current: (data.mpc15) ? data.mpc15 : 0.00,
          },
          {
            string: "MPV16",
            voltage: (data.mpv16) ? data.mpv16 : 0.00,
            current: (data.mpc16) ? data.mpc16 : 0.00,
          },
          {
            string: "MPV17",
            voltage: (data.mpv17) ? data.mpv17 : 0.00,
            current: (data.mpc17) ? data.mpc17 : 0.00,
          },
          {
            string: "MPV18",
            voltage: (data.mpv18) ? data.mpv18 : 0.00,
            current: (data.mpc18) ? data.mpc18 : 0.00,
          },
          {
            string: "MPV19",
            voltage: (data.mpv19) ? data.mpv19 : 0.00,
            current: (data.mpc19) ? data.mpc19 : 0.00,
          },
          {
            string: "MPV20",
            voltage: (data.mpv20) ? data.mpv20 : 0.00,
            current: (data.mpc20) ? data.mpc20 : 0.00,
          },
          {
            string: "MPV21",
            voltage: (data.mpv21) ? data.mpv21 : 0.00,
            current: (data.mpc21) ? data.mpc21 : 0.00,
          },
          {
            string: "MPV22",
            voltage: (data.mpv22) ? data.mpv22 : 0.00,
            current: (data.mpc22) ? data.mpc22 : 0.00,
          },
          {
            string: "MPV23",
            voltage: (data.mpv23) ? data.mpv23 : 0.00,
            current: (data.mpc23) ? data.mpc23 : 0.00,
          },
          {
            string: "MPV24",
            voltage: (data.mpv24) ? data.mpv24 : 0.00,
            current: (data.mpc24) ? data.mpc24 : 0.00,
          },
          {
            string: "MPV25",
            voltage: (data.mpv25) ? data.mpv25 : 0.00,
            current: (data.mpc25) ? data.mpc25 : 0.00,
          }
        )
        this.last_updated_inverter_table = new Date(data['@timestamp']);
      }
    })
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {
      this.getChartData();
    });
  }

  getChartData() {

    let voltage_data = []; let curr_data = []; let kw_data = []; let pf_data = []; let f_data = []; let fh_data = [];
    let data = []; this.action = 'getChartDataByAccount';

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          for (let i = 0; i < data.length; i++) {

            voltage_data.push({
              date: new Date(data[i].key_as_string),
              value: (data[i].val_avg_vl_pp.value != null) ? data[i].val_avg_vl_pp.value : 0
            });

            curr_data.push({
              date: new Date(data[i].key_as_string),
              value: (data[i].val_avg_curr_3p.value != null) ? data[i].val_avg_curr_3p.value : 0
            });

            kw_data.push({
              date: new Date(data[i].key_as_string),
              value: (data[i].val_total_w.value != null) ? data[i].val_total_w.value : 0,
            });

            pf_data.push({
              date: new Date(data[i].key_as_string),
              value: (data[i].val_avg_pf_3p.value != null) ? data[i].val_avg_pf_3p.value : 0
            });

            f_data.push({
              date: new Date(data[i].key_as_string),
              value: (data[i].val_total_f != null) ? data[i].val_total_f.value : 0
            });

            fh_data.push({
              date: new Date(data[i].key_as_string),
              value: (data[i].val_total_fh != null) ? data[i].val_total_fh.value : 0
            });

          }

          this.vol_data = voltage_data; this.curr_data = curr_data; this.kw_data = kw_data;
          this.pf_data = pf_data; this.f_data = f_data; this.fh_data = fh_data;

        }
      }

      this.voltage_prop = this.getGraphProp('voltage', this.vol_data);
      this.curr_prop = this.getGraphProp('current', this.curr_data);
      this.kw_prop = this.getGraphProp('kilo_watt', this.kw_data);
      this.pf_prop = this.getGraphProp('power_factor', this.pf_data);
      this.f_prop = this.getGraphProp('inst_flow', this.f_data);
      this.fh_prop = this.getGraphProp('total_flow', this.fh_data);
      this.dynamicChartValues();
    });
  }

  dynamicChartValues() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].chart_setting) : JSON.parse(config[0].sub_chart_setting);

        this.voltage_header = data.dashboard.single.graph.chart_1.header;
        this.current_header = data.dashboard.single.graph.chart_2.header;
        this.kw_header = data.dashboard.single.graph.chart_3.header;
        this.power_header = data.dashboard.single.graph.chart_4.header;

        this.voltage_prop.title = data.dashboard.single.graph.chart_1.title;
        this.curr_prop.title = data.dashboard.single.graph.chart_2.title;
        this.kw_prop.title = data.dashboard.single.graph.chart_3.title;
        this.pf_prop.title = data.dashboard.single.graph.chart_4.title;

        let voltage_unit = data.dashboard.single.graph.chart_1.unit;
        let current_unit = data.dashboard.single.graph.chart_2.unit;
        let kw_unit = data.dashboard.single.graph.chart_3.unit;
        let power_unit = data.dashboard.single.graph.chart_4.unit;
        let f_unit = this.unit_rate;
        let fh_unit = this.unit;

        this.voltage_status = data.dashboard.single.graph.chart_1.status;
        this.current_status = data.dashboard.single.graph.chart_2.status;
        this.kw_status = data.dashboard.single.graph.chart_3.status;
        this.power_status = data.dashboard.single.graph.chart_4.status;

        this.implementLineChart(this.voltage_prop, voltage_unit);
        this.implementLineChart(this.curr_prop, current_unit);
        this.implementLineChart(this.kw_prop, kw_unit);
        this.implementLineChart(this.pf_prop, power_unit);
        this.implementLineChart(this.f_prop, f_unit);
        this.implementLineChart(this.fh_prop, fh_unit);
      }
    })

  }

  getChartDataByParam(element) {
    let param_data = []; this.action = 'getChartDataByAccount';

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets && res.data.buckets[0].date_agg) {
        let data = res.data.buckets[0].date_agg.buckets;

        if (data.length > 0) {

          for (let i = 0; i < data.length; i++) {
            param_data.push({
              date: new Date(data[i].key_as_string),
              value: data[i]['val_' + this.params].value
            });
          }
        }
      }

      this.param_data = param_data;

      let props = this.getGraphProp(element, this.param_data);

      if (element == 'voltage' && this.vol_chart_active == 'line') { this.implementLineChart(props, 'Volt'); this.vol_data = this.param_data }
      else if (element == 'voltage' && this.vol_chart_active == 'bar') { this.implementBarChart(props, 'Volt'); this.vol_data = this.param_data }
      if (element == 'current' && this.curr_chart_active == 'line') { this.implementLineChart(props, 'Amp'); this.curr_data = this.param_data }
      else if (element == 'current' && this.curr_chart_active == 'bar') { this.implementBarChart(props, 'Amp'); this.curr_data = this.param_data }
      if (element == 'kilo_watt' && this.kw_chart_active == 'line') { this.implementLineChart(props, 'Kw'); this.kw_data = this.param_data }
      else if (element == 'kilo_watt' && this.kw_chart_active == 'bar') { this.implementBarChart(props, 'Kw'); this.kw_data = this.param_data }
      if (element == 'power_factor' && this.pf_chart_active == 'line') { this.implementLineChart(props, 'Power'); this.pf_data = this.param_data }
      else if (element == 'power_factor' && this.pf_chart_active == 'bar') { this.implementBarChart(props, 'Power'); this.pf_data = this.param_data }
      if (element == 'inst_flow' && this.f_chart_active == 'line') { this.implementLineChart(props, this.unit_rate); this.f_data = this.param_data }
      else if (element == 'inst_flow' && this.f_chart_active == 'bar') { this.implementBarChart(props, this.unit_rate); this.f_data = this.param_data }
      if (element == 'overview' && this.ov_chart_active == 'line') this.implementLineChart(props, 'Overveiw');
      else if (element == 'overview' && this.ov_chart_active == 'bar') this.implementBarChart(props, 'Overveiw');

    });
  }

  getBody() {

    let body = {
      'action': this.action,
      'user_id': [localStorage.getItem('user')],
      'device_id': [this.dataShared.device_id],
      'date': this.date_from,
      'fixed_interval': (this.dataShared.single_interval == null) ? '5m' : this.dataShared.single_interval,
      'parameters': (this.params.length > 0) ? this.params : this.parameters
    };

    if (this.date_from != this.date_to) {

      let range = {
        from: this.date_from,
        to: this.date_to,
      };

      delete body['date'];
      body['range'] = range
    }

    if (this.action == 'getTabularDataByAccount') {
      body['sort'] = "desc";
      body['from'] = this.from;
      body['size'] = this.size;
    }

    return body;
  }

  getGraphProp(param, param_data) {
    let interval = ''; let value = 0;

    if (this.dataShared.single_interval == null) interval = 'minute'; value = 5;
    if (this.dataShared.single_interval == '1s') interval = 'second'; value = 1;
    if (this.dataShared.single_interval == '1m') interval = 'minute'; value = 1;
    if (this.dataShared.single_interval == '5m') interval = 'minute'; value = 5;
    if (this.dataShared.single_interval == '15m') interval = 'minute'; value = 15;
    if (this.dataShared.single_interval == '30m') interval = 'minute'; value = 30;
    if (this.dataShared.single_interval == '1h') interval = 'hour'; value = 1;

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': interval,
        'value': value
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: true
    };

    if (param == 'voltage') {
      data.element = 'chartVoltage'
    } else if (param == 'current') {
      data.element = 'chartCurr'
    } else if (param == 'kilo_watt') {
      data.element = 'chartKiloWatt'
    } else if (param == 'power_factor') {
      data.element = 'chartPowerFactor'
      data.title = 'Power Factor'
    } else if (param == 'inst_flow') {
      data.element = 'chartInstFlow'
      data.title = 'Instant Flow Rate'
    } else if (param == 'total_flow') {
      data.element = 'chartTotalFlow'
      data.title = 'Total Flow'
    } else if (param == 'overview') {
      data.element = 'chartOverview'
      data.title = this.overview_param.description
    } else if (param == '') {
      data.element = 'chartChain'
      data.title = 'Kwh'
    } else if (param == 'genset') {
      data.element = 'gensetPieChart'
    } else if (param == 'refelling') {
      data.element = 'refellingPieChart'
    } else if (param == 'consumption') {
      data.element = 'consumptionPieChart'
    } else if (param == 'gauge') {
      data.element = 'guageChart'
    } else if (param == 'tank_capacity') {
      data.element = 'capacityChart'
    }

    return data;
  }

  implementCylinderGauge() {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create("capacityChart", am4charts.XYChart3D);

    // chart.titles.create().text = "Crude oil reserves";

    // Add data
    chart.data = [{
      "category": this.dataShared.volume_filled,
      "value1": this.dataShared.volume_filled,
      "value2": parseInt(this.dataShared.tank_capacity)
    }];

    let device_unit = this.device_unit;
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.max = parseInt(this.dataShared.tank_capacity);
    valueAxis.strictMinMax = true;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      if ((parseInt(text) > 100) || (parseInt(text) < 0)) {
        return "";
      }
      else {
        return text + device_unit;
      }

    })

    // Create series
    let series1 = chart.series.push(new am4charts.ConeSeries());
    series1.dataFields.valueY = "value1";
    series1.dataFields.categoryX = "category";
    series1.columns.template.width = am4core.percent(50);
    series1.columns.template.fillOpacity = 0.9;
    series1.columns.template.strokeOpacity = 1;
    series1.columns.template.strokeWidth = 2;

    let series2 = chart.series.push(new am4charts.ConeSeries());
    series2.dataFields.valueY = "value2";
    series2.dataFields.categoryX = "category";
    series2.stacked = true;
    series2.columns.template.width = am4core.percent(50);
    series2.columns.template.fill = am4core.color("#000");
    series2.columns.template.fillOpacity = 0.1;
    series2.columns.template.stroke = am4core.color("#000");
    series2.columns.template.strokeOpacity = 0.2;
    series2.columns.template.strokeWidth = 2;

  }

  implementPictorialSliceShape() {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let capacity = this.dataShared.tank_capacity;
    let value = this.dataShared.volume_filled;

    let circleSize = 0.8;

    let component = am4core.create("capacityChart", am4core.Container)
    component.width = am4core.percent(100);
    component.height = am4core.percent(100);

    let chartContainer = component.createChild(am4core.Container);
    chartContainer.x = am4core.percent(50)
    chartContainer.y = am4core.percent(50)

    let circle = chartContainer.createChild(am4core.Circle);
    circle.fill = am4core.color("#dadada");

    let circleMask = chartContainer.createChild(am4core.Circle);

    let waves = chartContainer.createChild(am4core.WavedRectangle);
    waves.fill = am4core.color("#34a4eb");
    waves.mask = circleMask;
    waves.horizontalCenter = "middle";
    waves.waveHeight = 10;
    waves.waveLength = 30;
    waves.y = 500;
    circleMask.y = -500;

    component.events.on("maxsizechanged", function () {
      let smallerSize = Math.min(component.pixelWidth, component.pixelHeight);
      let radius = smallerSize * circleSize / 2;

      circle.radius = radius;
      circleMask.radius = radius;
      waves.height = smallerSize;
      waves.width = Math.max(component.pixelWidth, component.pixelHeight);

      //capacityLabel.y = radius;

      let labelRadius = radius + 20

      capacityLabel.path = am4core.path.moveTo({ x: -labelRadius, y: 0 }) + am4core.path.arcToPoint({ x: labelRadius, y: 0 }, labelRadius, labelRadius);
      capacityLabel.locationOnPath = 0.5;

      setValue(value);
    })


    function setValue(value) {
      let y = - circle.radius - waves.waveHeight + (1 - value / capacity) * circle.pixelRadius * 2;
      waves.animate([{ property: "y", to: y }, { property: "waveHeight", to: 10, from: 15 }, { property: "x", from: -50, to: 0 }], 5000, am4core.ease.elasticOut);
      circleMask.animate([{ property: "y", to: -y }, { property: "x", from: 50, to: 0 }], 5000, am4core.ease.elasticOut);
    }


    let label = chartContainer.createChild(am4core.Label)
    let formattedValue = component.numberFormatter.format(value, "#.#a");
    formattedValue = formattedValue.toUpperCase();

    label.text = formattedValue + " Litres";
    label.fill = am4core.color("#fff");
    label.fontSize = 30;
    label.horizontalCenter = "middle";


    let capacityLabel = chartContainer.createChild(am4core.Label)

    let formattedCapacity = component.numberFormatter.format(capacity, "#.#a").toUpperCase();;

    capacityLabel.text = "Capacity " + formattedCapacity + " Litres";
    capacityLabel.fill = am4core.color("#34a4eb");
    capacityLabel.fontSize = 20;
    capacityLabel.textAlign = "middle";
    capacityLabel.padding(0, 0, 0, 0);
  }

  implementAnimatedGauge(data) {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // create chart
    let chart = am4core.create(data.element, am4charts.GaugeChart);
    chart.innerRadius = am4core.percent(82);

    let unit = this.unit
    /**
     * Normal axis
     */
    let axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis.min = 0;
    axis.max = (data.data[0]) ? 100 : parseInt(data.data.max_flow_rate);
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.ticks.template.disabled = false
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.length = 10;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = 40;

    if (data.data[0]) {
      axis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "%";
      })
    } else {
      axis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "";
      })
    }

    /**
     * Axis for ranges
     */
    let colorSet = new am4core.ColorSet();

    let axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis2.min = 0;
    axis2.max = (data.data[0]) ? 100 : parseInt(data.data.max_flow_rate);
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = true;

    let range0 = axis2.axisRanges.create();
    range0.value = 0;
    range0.endValue = 50;
    range0.axisFill.fillOpacity = 1;
    range0.axisFill.fill = colorSet.getIndex(0);

    let range1 = axis2.axisRanges.create();
    range1.value = 50;
    range1.endValue = (data.data[0]) ? 100 : parseInt(data.data.max_flow_rate);
    range1.axisFill.fillOpacity = 1;
    range1.axisFill.fill = colorSet.getIndex(2);

    /**
     * Label
     */
    let label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = (data.data[0]) ? 25 : 15;
    label.x = am4core.percent(50);
    label.y = am4core.percent(200);
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.text = "50%";

    /**
     * Hand
     */
    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(20);
    hand.startWidth = 10;
    hand.pin.disabled = true;
    hand.value = 50;

    hand.events.on("propertychanged", function (ev) {
      range0.endValue = ev.target.value;
      range1.value = ev.target.value;
      label.text = (data.data[0]) ? axis2.positionToValue(hand.currentPosition).toFixed(1) : axis2.positionToValue(hand.currentPosition).toFixed(1) + '  ' + unit;
      axis2.invalidate();
    });

    // run in every 2 seconds
    this.card_interval = + setInterval(() => {
      let animation = new am4core.Animation(hand, {
        property: "value",
        to: (this.sensor_status) ? this.dataShared.humid : this.dataShared.animated_gauge_value
      }, 1000, am4core.ease.cubicOut).start();
    }, 2000);
  }

  implementGaugeWithTwoAxes() {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0;

    let axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis.min = 0;
    axis.max = (this.sensor_status) ? this.max_temp_range : this.motor_rpm;
    axis.strictMinMax = true;
    axis.renderer.inside = true;
    //axis.renderer.ticks.template.inside = true;
    //axis.stroke = chart.colors.getIndex(3);
    axis.renderer.radius = am4core.percent(97);
    //axis.renderer.radius = 80;
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.line.strokeWidth = 5;
    axis.renderer.line.stroke = chart.colors.getIndex(0);
    axis.renderer.ticks.template.disabled = false
    axis.renderer.ticks.template.stroke = chart.colors.getIndex(0);
    axis.renderer.labels.template.radius = 35;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.ticks.template.length = 10;
    axis.hiddenState.properties.opacity = 1;
    axis.hiddenState.properties.visible = true;
    axis.setStateOnChildren = true;
    axis.renderer.hiddenState.properties.endAngle = 180;

    let axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis2.min = 0;
    axis2.max = (this.sensor_status) ? this.max_temp_range : this.motor_rpm;
    axis2.strictMinMax = true;

    axis2.renderer.line.strokeOpacity = 1;
    axis2.renderer.line.strokeWidth = 5;
    axis2.renderer.line.stroke = chart.colors.getIndex(3);
    axis2.renderer.ticks.template.stroke = chart.colors.getIndex(3);

    axis2.renderer.ticks.template.disabled = false
    axis2.renderer.ticks.template.strokeOpacity = 1;
    axis2.renderer.grid.template.disabled = true;
    axis2.renderer.ticks.template.length = 10;
    axis2.hiddenState.properties.opacity = 1;
    axis2.hiddenState.properties.visible = true;
    axis2.setStateOnChildren = true;
    axis2.renderer.hiddenState.properties.endAngle = 180;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.fill = axis.renderer.line.stroke;
    hand.stroke = axis.renderer.line.stroke;
    hand.axis = axis;
    hand.pin.radius = 14;
    hand.startWidth = 10;

    let hand2 = chart.hands.push(new am4charts.ClockHand());
    hand2.fill = axis2.renderer.line.stroke;
    hand2.stroke = axis2.renderer.line.stroke;
    hand2.axis = axis2;
    hand2.pin.radius = 10;
    hand2.startWidth = 10;

    // run in every 2 seconds
    this.card_interval = + setInterval(() => {

      let hand_value1 = (this.sensor_status) ? this.dataShared.temp : this.dataShared.mot_spd;
      let hand_value2 = (this.sensor_status) ? this.dataShared.set_temp : this.dataShared.mot_spd_estm;

      hand.showValue(hand_value1, 1000, am4core.ease.cubicOut);
      label.text = Math.round(hand.value).toString();

      hand2.showValue(hand_value2, 1000, am4core.ease.cubicOut);
      label2.text = Math.round(hand2.value).toString();

    }, 2000);

    let legend = new am4charts.Legend();
    legend.isMeasured = false;
    legend.y = am4core.percent(100);
    legend.verticalCenter = "bottom";
    legend.parent = chart.chartContainer;
    legend.data = [{
      "name": (this.sensor_status) ? "Temp\xB0C" : "Speed (Used)",
      "fill": chart.colors.getIndex(0)
    }, {
      "name": (this.sensor_status) ? "Set Temp\xB0C" : "Speed (Estm.)",
      "fill": chart.colors.getIndex(3)
    }];

    legend.itemContainers.template.events.on("hit", function (ev) {
      let index = ev.target.dataItem.index;

      if (!ev.target.isActive) {
        chart.hands.getIndex(index).hide();
        chart.xAxes.getIndex(index).hide();
        labelList.getIndex(index).hide();
      }
      else {
        chart.hands.getIndex(index).show();
        chart.xAxes.getIndex(index).show();
        labelList.getIndex(index).show();
      }
    });

    let labelList = new am4core.ListTemplate(new am4core.Label());
    labelList.template.isMeasured = false;
    labelList.template.background.strokeWidth = 2;
    labelList.template.fontSize = 15;
    labelList.template.padding(5, 10, 5, 10);
    labelList.template.y = am4core.percent(75);
    labelList.template.horizontalCenter = "middle";

    let label = labelList.create();
    label.parent = chart.chartContainer;
    label.x = am4core.percent(40);
    label.background.stroke = chart.colors.getIndex(0);
    label.fill = chart.colors.getIndex(0);
    label.text = "0";

    let label2 = labelList.create();
    label2.parent = chart.chartContainer;
    label2.x = am4core.percent(60);
    label2.background.stroke = chart.colors.getIndex(3);
    label2.fill = chart.colors.getIndex(3);
    label2.text = "0";
  }

  implementLineChart(data, name) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.baseInterval = {
      'timeUnit': data.interval.interval,
      'count': data.interval.value
    };

    dateAxis.tooltipDateFormat = data.tooltipFormat;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = data.title;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.name = name;
    series.tooltipText = '[bold]{name} : {valueY}[/]';
    series.fillOpacity = 0;
    // series.strokeWidth = 1;
    series.strokeWidth = 2;
    series.tensionX = 0.8;
    series.tensionY = 1;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;

    if (data.xScrollbar) {
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;
    }

    dateAxis.start = 0;
    dateAxis.keepSelection = true;

  }

  implementBarChart(data, name) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.baseInterval = {
      'timeUnit': data.interval.interval,
      'count': data.interval.value
    };

    dateAxis.tooltipDateFormat = data.tooltipFormat;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = data.title;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.name = name;
    series.tooltipText = '[bold]{name} : {valueY}[/]';
    series.fillOpacity = 1;
    series.strokeWidth = 2;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;

    if (data.xScrollbar) {
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;
    }

    dateAxis.start = 0;
    dateAxis.keepSelection = true;

  }

  onFromDateSelection(e, param) {

    this.date_from = e.target.value;
    if (param == 'voltage') this.params = ['avg_vl_pp'];
    else if (param == 'current') this.params = ['avg_curr_3p'];
    else if (param == 'kilo_watt') this.params = ['total_w'];
    else if (param == 'power_factor') this.params = ['avg_pf_3p'];
    else if (param == 'inst_flow') this.params = ['total_f'];
    else if (param == 'total_flow') this.params = ['total_fh'];

    this.getChartDataByParam(param);
  }

  onToDateSelection(e, param) {

    this.date_to = e.target.value;
    if (param == 'voltage') this.params = ['avg_vl_pp'];
    else if (param == 'current') this.params = ['avg_curr_3p'];
    else if (param == 'kilo_watt') this.params = ['total_w'];
    else if (param == 'power_factor') this.params = ['avg_pf_3p'];
    else if (param == 'inst_flow') this.params = ['total_f'];
    else if (param == 'total_flow') this.params = ['total_fh'];

    this.getChartDataByParam(param);
  }

  // onTabularFromDateSelection(e) {
  //   this.date_from = e.target.value;
  //   this.getSingleDeviceTable();
  // }

  // onTabularToDateSelection(e) {
  //   this.date_to = e.target.value;
  //   this.getSingleDeviceTable();
  // }

  onTabularDateSelection(e) {
    this.date_from = e.start['_d'].toISOString().substring(0, 10);;
    this.date_to = e.end['_d'].toISOString().substring(0, 10);;
  }

  tabChange($event: NgbTabChangeEvent) {

    this.curr_tab = $event.nextId;

    if (this.curr_tab == 'tab-overview') {

      this.resetSinleDevice();

      let body = {
        'action': 'getDeviceByID',
        'device_id': [this.device_id]
      }

      this.dashboardService.httpPost(body).subscribe((res: any) => {

        if (res.status === 200 && res.data[this.device_id]) {
          this.overview_params = this.sortAsc(res.data[this.device_id]["parameters"]);
        }

      })

    } else if (this.curr_tab == 'tab-graph' && this.dataShared.device_id) {
      this.eventEmitter.emit('getSelectedSingleDevice', this.dataShared.device_id);
      this.getSingleDeviceGraph();
    } else if (this.curr_tab == 'tab-table') {
      this.resetSinleDevice();
      this.checkDeviceModelStatus();
      this.getSingleDeviceTable();
      this.exportSingleTable();
    } else if (this.curr_tab == 'tab-trends') {
      this.resetSinleDevice();
      this.DropdownSetting();
      this.getChartDataTrend();
      this.getGensetPieChartData('Daily');
      this.getEnergyProfileAndChainAnalysis('Daily');
      this.getMinMax();
      this.getPieChartData('getEnergyHourlyData');
    }
  }

  onClick() {
    if (this.curr_tab == 'tab-table') {
      this.getSingleDeviceTable();
      this.exportSingleTable();
    }

    if (this.device_id != 'N/A' && this.params_trend.length > 0) {
      // this.resetSinleDevice();
      this.getParamUnit(this.params_trend);
      this.getChartDataTrend();
    }
  }

  tableDropdownSetting() {

    this.dropdownList = this.dataShared.device_params;

    if (this.dropdownList.length > 0) {

      let param = [
        (this.dataShared.device_detail.device_type == 'energy') ? 'total_w' : this.dataShared.device_params[0].parameter,
        (this.dataShared.device_detail.device_type == 'energy') ? 'avg_curr_3p' : this.dataShared.device_params[1].parameter,
        (this.dataShared.device_detail.device_type == 'energy') ? 'avg_vl_pp' : this.dataShared.device_params[2].parameter,
        (this.dataShared.device_detail.device_type == 'energy') ? 'avg_pf_3p' : this.dataShared.device_params[3].parameter,
      ]

      let description = [
        (this.dataShared.device_detail.device_type == 'energy') ? 'Total Active Power' : this.dataShared.device_params[0].description,
        (this.dataShared.device_detail.device_type == 'energy') ? 'Avg. 3 Phase Current (Amps)' : this.dataShared.device_params[1].description,
        (this.dataShared.device_detail.device_type == 'energy') ? 'Avg. Line Voltage VL-L (Volts)' : this.dataShared.device_params[2].description,
        (this.dataShared.device_detail.device_type == 'energy') ? 'Power Factor 3 Phase (Avg)' : this.dataShared.device_params[3].description,
      ]

      this.params = [param[0], param[1]];

      this.selectedItems = [
        { parameter: param[0], description: description[0] },
        { parameter: param[1], description: description[1] }
      ];

      if (this.dataShared.device_params[2]) {

        this.params.push(param[2]);
        this.selectedItems.push({ parameter: param[2], description: description[2] })

      }

      if (this.dataShared.device_params[3]) {

        this.params.push(param[3]);
        this.selectedItems.push({ parameter: param[3], description: description[3] })

      }

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'parameter',
        textField: 'description',
        showSelectedItemsAtTop: true,
        itemsShowLimit: 4,
        allowSearchFilter: true,
        clearSearchFilter: true,
        limitSelection: 6,
        searchPlaceholderText: 'Select parmeters',
      };

      clearInterval(this.table_dropdown_interval);

    } else {
      // run in every 5 seconds
      this.table_dropdown_interval = + setInterval(() => {
        this.tableDropdownSetting();
      }, 5000);
    }


  }

  tableDropdownFLowSetting() {
    this.dropdownList = this.dataShared.device_params;

    if (this.dropdownList.length > 0) {

      this.params = ['total_f', 'total_fh'];

      this.selectedItems = [
        { parameter: 'total_f', description: 'Instant Flow Rate' },
        { parameter: 'total_fh', description: 'Total Flow' }
      ];

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'parameter',
        textField: 'description',
        showSelectedItemsAtTop: true,
        itemsShowLimit: 4,
        allowSearchFilter: true,
        clearSearchFilter: true,
        limitSelection: 6,
        searchPlaceholderText: 'Select parmeters',
      };
    }
  }

  pageSelection(e) {

    this.size = 50;

    if (e == 'prev_page') {
      this.from = (this.page == 1) ? 0 : (this.page - 2) * 50;
      this.page = (this.page == 1) ? 1 : parseInt(this.page.toString()) - 1;
      if (this.page != 1) this.getSingleDeviceTable();
    }

    if (e == 'curr_page') {
      this.from = (this.page - 1) * 50
      this.page = parseInt(this.page.toString());
      this.getSingleDeviceTable();
    }

    if (e == 'next_page') {
      this.from = (this.pages.length == this.page) ? (this.page - 1) * 50 : this.page * 50;
      this.page = (this.pages.length == this.page) ? this.page : parseInt((this.page).toString()) + 1;
      if (this.pages.length != this.page) this.getSingleDeviceTable();
    }

  }

  getSingleDeviceTable() {

    if (this.dataShared.device_id == '' || this.dataShared.device_id == null) {
      this.toastr.info('Please select a device');
      return;
    }

    let tabular_data = []; this.tabular_data = [];
    this.action = 'getTabularDataByAccount';

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.hits) {

        res.data.hits.forEach(data => {

          let keys = Object.keys(data);
          let values = [];

          for (let i = 0; i < Object.values(data).length; i++) {

            if (typeof Object.values(data)[i] == 'number') {

              let value = Object.values(data)[i] as number;
              values.push(value.toFixed(2));

            } else {

              let value = Object.values(data)[i];
              values.push(value);
            }
          }

          let merged = keys.reduce((obj, key, index) => ({ ...obj, [key]: values[index] }), {});

          merged['@timestamp'] = merged['timestamp'] = (new Date(merged['@timestamp']).toLocaleString());
          delete merged['@timestamp'];

          tabular_data.push(merged);

        });

        this.tabular_data = tabular_data.filter((arr, index, self) =>
          index === self.findIndex((t) => (t.timestamp === arr.timestamp)))

        this.pages = [];
        for (let i = 1; i < (res.data.total_found / 50); i++) {
          this.pages.push(i);
        }

        this.total_found = res.data.total_found;
      }
    });
  }

  exportSingleTable() {
    if (this.dataShared.device_id == '' || this.dataShared.device_id == null) {
      this.toastr.info('Please select a device');
      return;
    }

    this.export_table = [];
    this.action = 'getTabularDataByAccount';

    let body = {
      'action': 'getTabularDataByAccount',
      'user_id': [localStorage.getItem('user')],
      'device_id': [this.dataShared.device_id],
      'range': {
        'from': this.date_from,
        'to': this.date_to,
      },
      'parameters': (this.params.length > 0) ? this.params : this.parameters,
      'sort': "asc",
      'from': 0,
      'size': 9999,
    };
    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.data.hits) {

        res.data.hits.forEach(data => {
          data['@timestamp'] = data['timestamp'] = (new Date(data['@timestamp']).toLocaleString());
          delete data['@timestamp'];

          this.export_table.push(data);

        });
      }
    });
  }

  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, switchcount = 0;
    table = document.getElementById("myTable");
    switching = true;
    //Set the sorting direction to ascending:
    this.dir = "asc";
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
      //start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /*Loop through all table rows (except the
      first, which contains table headers):*/
      for (i = 1; i < (rows.length - 1); i++) {
        //start by saying there should be no switching:
        shouldSwitch = false;
        /*Get the two elements you want to compare,
        one from current row and one from the next:*/
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /*check if the two rows should switch place,
        based on the direction, asc or desc:*/
        if (this.dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (this.dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
        and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        //Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        /*If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again.*/
        if (switchcount == 0 && this.dir == "asc") {
          this.dir = "desc";
          switching = true;
        }
      }
    }

  }

  selectedOverviewParam() {
    if (this.overview_param) {
      this.overview_title = this.overview_param.description;
      this.params = [this.overview_param.parameter];
      this.getChartDataByParam('overview')
    }
  }

  overviewFromDateSelection(e) {
    if (this.params.length > 0) {
      this.date_from = e.target.value;
      this.getChartDataByParam('overview')
    }
  }

  overviewToDateSelection(e) {
    if (this.params.length > 0) {
      this.date_to = e.target.value;
      this.getChartDataByParam('overview')
    }
  }

  sortAsc(data) {
    data.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      } else if (a.description > b.description) {
        return 1;
      } else {
        return 0;
      }
    });
    return data;
  }

  tabLineChart(element) {

    if (element == 'voltage' && this.vol_chart_active != 'line' && this.vl_from_date_selection == this.vl_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.vol_data), 'Volt'); this.vol_chart_active = 'line';

    } else if (element == 'voltage' && this.vol_chart_active != 'line' && this.vl_from_date_selection !== this.vl_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Volt'); this.vol_chart_active = 'line';

    } else if (element == 'current' && this.curr_chart_active != 'line' && this.curr_from_date_selection == this.curr_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.curr_data), 'Amp'); this.curr_chart_active = 'line';

    } else if (element == 'current' && this.curr_chart_active != 'line' && this.curr_from_date_selection !== this.curr_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Amp'); this.curr_chart_active = 'line';

    } else if (element == 'kilo_watt' && this.kw_chart_active != 'line' && this.kw_from_date_selection == this.kw_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.kw_data), 'Kw'); this.kw_chart_active = 'line';

    } else if (element == 'kilo_watt' && this.kw_chart_active != 'line' && this.kw_from_date_selection !== this.kw_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Kw'); this.kw_chart_active = 'line';

    } else if (element == 'power_factor' && this.pf_chart_active != 'line' && this.pf_from_date_selection == this.pf_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.pf_data), 'Power'); this.pf_chart_active = 'line';

    } else if (element == 'power_factor' && this.pf_chart_active != 'line' && this.pf_from_date_selection !== this.pf_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Power'); this.pf_chart_active = 'line';

    } else if (element == 'inst_flow' && this.f_chart_active != 'line' && this.f_from_date_selection == this.f_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.f_data), this.unit_rate); this.f_chart_active = 'line';

    } else if (element == 'inst_flow' && this.f_chart_active != 'line' && this.f_from_date_selection !== this.f_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.param_data), this.unit_rate); this.f_chart_active = 'line';

    } else if (element == 'total_flow' && this.fh_chart_active != 'line' && this.fh_from_date_selection == this.fh_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.fh_data), this.unit); this.fh_chart_active = 'line';

    } else if (element == 'total_flow' && this.fh_chart_active != 'line' && this.fh_from_date_selection !== this.fh_to_date_selection) {

      this.implementLineChart(this.getGraphProp(element, this.param_data), this.unit); this.fh_chart_active = 'line';

    } else if (element == 'overview' && this.ov_chart_active != 'line') {

      this.implementLineChart(this.getGraphProp(element, this.param_data), 'Overview'); this.ov_chart_active = 'line';

    } if (element == 'line') {

      this.amcharts.implementComparativeChart(this.amcharts.getGraphProp(this.chart_data, 'line'), this.params_trend, this.units); this.chart_active = 'line';

    }

  }

  tabBarChart(element) {

    if (element == 'voltage' && this.vol_chart_active != 'bar' && this.vl_from_date_selection == this.vl_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.vol_data), 'Volt'); this.vol_chart_active = 'bar';

    } else if (element == 'voltage' && this.vol_chart_active != 'bar' && this.vl_from_date_selection !== this.date_to) {

      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Volt'); this.vol_chart_active = 'bar';

    } else if (element == 'current' && this.curr_chart_active != 'bar' && this.curr_from_date_selection == this.curr_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.curr_data), 'Amp'); this.curr_chart_active = 'bar';

    } else if (element == 'current' && this.curr_chart_active != 'bar' && this.curr_from_date_selection !== this.curr_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Amp'); this.curr_chart_active = 'bar';

    } else if (element == 'kilo_watt' && this.kw_chart_active != 'bar' && this.kw_to_date_selection == this.kw_from_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.kw_data), 'Kw'); this.kw_chart_active = 'bar';

    } else if (element == 'kilo_watt' && this.kw_chart_active != 'bar' && this.kw_to_date_selection !== this.kw_from_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Kw'); this.kw_chart_active = 'bar';

    } else if (element == 'power_factor' && this.pf_chart_active != 'bar' && this.pf_from_date_selection == this.pf_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.pf_data), 'Power'); this.pf_chart_active = 'bar';

    } else if (element == 'power_factor' && this.pf_chart_active != 'bar' && this.pf_from_date_selection !== this.pf_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Power'); this.pf_chart_active = 'bar';

    } else if (element == 'inst_flow' && this.f_chart_active != 'bar' && this.f_from_date_selection == this.f_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.f_data), this.unit_rate); this.f_chart_active = 'bar';

    } else if (element == 'inst_flow' && this.f_chart_active != 'bar' && this.f_from_date_selection !== this.f_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.param_data), this.unit_rate); this.f_chart_active = 'bar';

    } else if (element == 'total_flow' && this.fh_chart_active != 'bar' && this.fh_from_date_selection == this.fh_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.fh_data), this.unit); this.fh_chart_active = 'bar';

    } else if (element == 'total_flow' && this.fh_chart_active != 'bar' && this.fh_from_date_selection !== this.fh_to_date_selection) {

      this.implementBarChart(this.getGraphProp(element, this.param_data), this.unit); this.fh_chart_active = 'bar';

    } else if (element == 'overview' && this.ov_chart_active != 'bar') {

      this.implementBarChart(this.getGraphProp(element, this.param_data), 'Overview'); this.ov_chart_active = 'bar';

    } if (element == 'bar') {

      this.amcharts.implementComparativeChart(this.amcharts.getGraphProp(this.chart_data, 'bar'), this.params_trend, this.units); this.chart_active = 'bar';

    }
  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data);
  }

  getDataByParam() {
    if (this.data_param == 'voltage') return this.vol_data;
    else if (this.data_param == 'current') return this.curr_data;
    else if (this.data_param == 'kilo_watt') return this.kw_data;
    else if (this.data_param == 'power_factor') return this.pf_data;
    else if (this.data_param == 'inst_flow') return this.f_data;
    else if (this.data_param == 'total_flow') return this.fh_data;
    else if (this.data_param == 'table') return this.export_table;
    else if (this.data_param == 'overview') return this.param_data
    else if (this.data_param == 'trend_graph') return this.chart_data
  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }

  onItemSelect(item: any) {
    this.params.push(item['parameter']);
  }

  onItemSelectTrend(item: any) {
    if (this.graph_type == 'all') this.params_trend = [];
    this.params_trend.push(item['parameter']);
  }

  onItemDeSelect(item: any) {
    let index = this.params.indexOf(item['parameter'], 0)
    if (index > -1) this.params.splice(index, 1);
  }

  onItemDeSelectTrend(item: any) {
    let index = this.params_trend.indexOf(item['parameter'], 0)
    if (index > -1) this.params_trend.splice(index, 1);
  }

  getTitleByParamCode(key) {
    return this.dataShared.getTitleByParamCode(key);
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
