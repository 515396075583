import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from 'src/app/shared/services/event-service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {

  public months: any = [];
  public month: any = null;
  public years: any = [];
  public year: any = null;
  public cost: number = 0.00;
  public selling_rate: number = 0.00;
  public genset_unit_rate_rs: number = 0.00;
  public action: string = '';
  public tabular_data: any = [];
  public service_charges: number = 0.00;
  public misc_charges: number = 0.00;
  public fuel_rate: number = 0.00;
  public fuel_rate_status: boolean = false;
  public late_charges: number = 0.00;
  public tab_cost_status: boolean = false;
  public device_id;
  public modalClose: any;
  public device_hierarchy: any;
  public curr_tab = 'tab-alarm';
  public device_params: any = [];
  public alarm_value: any = { min: "", max: "", email: "" }
  public alarm_module: any = { param: "", min: "", max: "", email: "" }
  public alarm_param_value: any = { "": { min: "", max: "", email: "" } }
  public alarm_table: any = []
  public device_detail: any = []
  public device_location: any = '';
  public device_type: any = 'energy';
  public alarm_setting_status: any = 'update';
  public alarm_param: any = { param: "", desc: "", min: "", max: "", email: "" };
  public cost_slice: any = { start: 0, end: 12 };
  public cost_page = 1;
  public cost_pages: any = [];
  page = 1;
  pageSize = 4;

  public refelling: any = { current_counter: 0.00, correction_counter: 0.00, new_counter: 0.00 };
  public consumption: any = { current_counter: 0.00, correction_counter: 0.00, new_counter: 0.00 };
  public counter: any = { current_counter: 0.00, correction_counter: 0.00, new_counter: 0.00 };
  public topic: string = '';
  public counter_interval: number;
  public akd_interval: number;
  public counter_type: any;
  public level_counter_status: boolean = false;

  public tab_energy_status: boolean = false;
  public tab_flow_status: boolean = false;
  public tab_sensor_status: boolean = false;
  public tab_level_status: boolean = false;

  constructor(private dashboardService: DashboardService, private toastr: ToastrService, private modalService: NgbModal, private dataShared: DataSharedService, private eventEmitter: EmitterService) { }

  ngOnInit(): void {

    this.deviceLocationHierarchy();
    this.getDevices()
    this.getDashboardSettings()
    this.getMonthsAndYear();
    this.getPerKwhUnitRateTable();


    this.eventEmitter.listen('selectedDevice', (device_id) => {

      this.device_id = device_id;
      this.alarm_module.param = "";

      this.refelling.correction_counter = 0.00;
      this.refelling.new_counter = 0.00;

      this.consumption.correction_counter = 0.00;
      this.consumption.new_counter = 0.00;

      this.getDevices()
      this.getDeviceParameters();
      this.getAlarmTable();


      // this.getLevelCounter();

    });

  }

  // allow premission
  getDashboardSettings() {
    this.action = 'getSettings';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].tab_setting) : JSON.parse(config[0].sub_tab_setting);
        this.tab_cost_status = data.allow_tab.settings.cost;
      }
    })
  }

  // for tab change 
  tabChange($event: NgbTabChangeEvent) {

    this.device_location = '';

    if ($event.nextId == 'tab-energy') { this.device_type = 'energy'; this.deviceLocationHierarchy(); }
    else if ($event.nextId == 'tab-flow') { this.device_type = 'flow'; this.deviceLocationHierarchy(); }
    else if ($event.nextId == 'tab-sensor') { this.device_type = 'sensor'; this.deviceLocationHierarchy(); }
    else if ($event.nextId == 'tab-level') { this.device_type = 'level'; this.deviceLocationHierarchy(); }
    else this.curr_tab = $event.nextId;

    if (this.curr_tab == 'tab-alarm') {
      
      clearInterval(this.counter_interval);
      // this.device_type = 'energy';
      this.deviceLocationHierarchy();

      this.getDevices();
      this.getDeviceParameters();
      this.getAlarmTable();

    }
    if (this.curr_tab == 'tab-cost') {
      clearInterval(this.counter_interval);
      this.toastr.info('tab-cost');
    }

    if (this.curr_tab == 'tab-counter-correction') {

      this.device_type = 'level';
      this.deviceLocationHierarchy();

      // this.getDevices();
      // this.getLevelCounter(); 

      // run in every 5 seconds
      this.counter_interval = + setInterval(() => {
        this.getLevelCounter();
      }, 5000);
    }
  }

  // api bodies
  getBody() {

    let body = {
      action: this.action,
    }

    if (this.action == 'getSettings') {
      body['meta_key'] = 'config';
      body['user_id'] = localStorage.getItem('user');
      body['account_id'] = localStorage.getItem('account');
    }

    if (this.action == 'getDeviceLocationHierarchy') {

      body['account_id'] = localStorage.getItem('account'),
        body['sub_user'] = localStorage.getItem('sub_user'),
        body['device_type'] = this.device_type

    }

    if (this.action == 'getDeviceByID') {
      body['device_id'] = [this.device_id]
    }

    if (this.action == 'setAlarmSetting') {

      body['account'] = localStorage.getItem('account');
      body['device_id'] = this.device_id;
      body['alarm_param_values'] = this.alarm_param_value;
      body['alarm_param'] = this.alarm_param.param;
      body['status'] = this.alarm_setting_status;

    }

    if (this.action == 'updateSetting') {
      if (this.fuel_rate_status) body['fuel_rate'] = this.fuel_rate;
      body['column'] = 'cost_by_month';
      body['month'] = this.month.toLowerCase();
      body['year'] = this.year;
      body['per_kwh_unit_rate'] = this.cost;
      body['selling_rate'] = this.selling_rate;
      body['genset_unit_rate_rs'] = this.genset_unit_rate_rs;
      body['service_charges'] = this.service_charges;
      body['misc_charges'] = this.misc_charges;
      body['late_charges'] = this.late_charges;
      body['account_id'] = localStorage.getItem('account');
    }

    if (this.action == 'getDevices') {
      body["account_id"] = localStorage.getItem('account');
      body["user_id"] = localStorage.getItem('user');
    }

    if (this.action == 'getDeviceLevelKPI') {

      body["user_id"] = localStorage.getItem('user');
      body['device_id'] = this.device_id;
      body["parameters"] = ["total_cc", "total_cr", "volume", "@timestamp", "akd"];
    }

    if (this.action == 'insertCounterCorrection') {
      body['counter_type'] = this.counter_type;
      body["account"] = localStorage.getItem('account');
      body['current_counter'] = this.counter.current_counter;
      body['correction'] = this.counter.correction_counter;
      body['new_counter'] = this.counter.new_counter;
      body['device_id'] = this.device_id;
    }

    if (this.action == 'getCounterCorrection') {

      body['device_id'] = this.device_id;
      body["account"] = localStorage.getItem('account');
      body["user_id"] = localStorage.getItem('user');
      body["columns"] = ["timestamp", "correction"];

    }

    return body;
  }

  // get device detail by account and user
  getDevices() {

    this.action = 'getDevices'; this.device_detail = []; this.alarm_table = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.device_detail = res.data;

      // tab checking condition
      if (this.device_params.length > 0) {

        this.device_detail.forEach(element => {


          // allow tab 
          this.tab_energy_status = true;
          this.tab_flow_status = true;
          this.tab_sensor_status = true;
          this.tab_level_status = true;

          if (element.device_type == 'energy') this.tab_energy_status = true;
          if (element.device_type == 'flow') this.tab_flow_status = true;
          if (element.device_type == 'sensor') this.tab_sensor_status = true;
          if (element.device_type == 'level') this.tab_level_status = true;

          if (element.device_id == this.device_id) {

            this.device_location = element.location;
            this.topic = element.aws_thing_topic;

            let alarm_param_values = JSON.parse(element.alarm_param_values);

            if (alarm_param_values != null) {

              let key = Object.keys(alarm_param_values);
              this.alarm_table = [];

              // for alarm table 
              for (let i = 0; i < key.length; i++) {

                for (let j = 0; j < this.dataShared.device_params.length; j++) {

                  if (this.dataShared.device_params[j].parameter == key[i]) {

                    this.alarm_table.push({
                      parameter: this.dataShared.device_params[j].parameter,
                      description: this.dataShared.device_params[j].description,
                      min: alarm_param_values[key[i]].min,
                      max: alarm_param_values[key[i]].max,
                      email: alarm_param_values[key[i]].email
                    })
                  }
                }
              }
              // end alarm table 

              // for on change alarm 
              if (alarm_param_values[this.alarm_module.param]) {

                this.alarm_value = {
                  "min": parseFloat(alarm_param_values[this.alarm_module.param]['min']),
                  "max": parseFloat(alarm_param_values[this.alarm_module.param]['max']),
                  "email": alarm_param_values[this.alarm_module.param]['email']
                }

              } else {

                this.alarm_value = {
                  "min": "",
                  "max": "",
                  "email": ""
                }
              }
              // end on change alarm 

            } else {

              this.alarm_value = {
                min: "",
                max: "",
                email: ""
              }
            }
          }

        });
      } else {

        this.alarm_value = {
          "min": "",
          "max": "",
          "email": ""
        }

      }

    })
  }

  // for post api to device using aws api gateway to lambda 
  awsApiGateway(payload) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(payload);

    fetch("https://7gusvkf0lb.execute-api.eu-central-1.amazonaws.com/reverse/reverse_lambda", {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => { this.getDevices() })
      .catch(error => { this.toastr.error('Some thing went wrong') });
  }

  // for alarm tab 

  // device hierarcy alarm side bar 
  deviceLocationHierarchy() {

    this.action = 'getDeviceLocationHierarchy';

    let body = {
      action: 'getDeviceLocationHierarchy',
      account_id: localStorage.getItem('account'),
      sub_user: localStorage.getItem('sub_user'),
      device_type: this.device_type
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.data[0]) {

        this.device_hierarchy = res.data;
        this.firstDeviceID(res.data[0]);
      }
    })
  }

  /** get first device ID from nested array */
  firstDeviceID(data) {
    if (data.childrens) {
      this.firstDeviceID(data.childrens[0]);
    } else {
      this.device_id = this.dataShared.device_id = data.id;
      this.getDevices();
    }
  }

  // set device param list 
  getDeviceParameters() {

    this.action = 'getDeviceByID'; this.device_params = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.status === 200 && res.data[this.device_id]) {

        let device_info = res.data[this.device_id];

        // for alarm parameter 
        this.device_params = device_info.parameters;
      }
    })

  }

  // on change param 
  onChangeAlarmParam() {
    this.getDevices()
  }

  // set threshold values
  setThresholdValues() {

    if (this.alarm_module.min > this.alarm_module.max) {
      this.alarm_module.max = this.alarm_module.min
    }

  }

  // update and insert alarm setting 
  updateAlarmSetting() {

    // checking table length 
    for (let i = 0; i < this.alarm_table.length; i++) {

      if (i == 4) {
        return this.toastr.error('max parameter limit is 5');
        break;
      } else if (this.alarm_module.param == this.alarm_table[i].parameter) {
        break;
      }

    }

    this.action = 'setAlarmSetting'; this.alarm_setting_status = 'update'; this.alarm_param_value = {};

    let alarm_param_value = {
      min: (this.alarm_module.min == "") ? parseFloat(this.alarm_value.min) : parseFloat(this.alarm_module.min),
      max: (this.alarm_module.max == "") ? parseFloat(this.alarm_value.max) : parseFloat(this.alarm_module.max),
      email: (this.alarm_module.email == "") ? this.alarm_value.email : this.alarm_module.email
    }
    this.alarm_param_value[this.alarm_module.param] = alarm_param_value;

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      // showing response 
      if (res.data == 'nothing to updated') this.toastr.info(res.data.msg);
      else this.toastr.success(res.data.msg);

      let payload = {
        topic: res.data.topic,
        payload: {
          'als': 1
        }
      }    // create payload for ms device 


      this.awsApiGateway(payload);
    })
  }

  // alarm table by device ids
  getAlarmTable() {

    this.getDevices();

  }

  // open alarm delete modal
  openAlarmDeleteModal(content, alarm) {

    this.alarm_param.param = alarm.parameter;
    this.alarm_param.desc = alarm.description;
    this.alarm_param.min = alarm.min;
    this.alarm_param.max = alarm.max;
    this.alarm_param.email = alarm.email;

    this.modalClose = this.modalService.open(content, { centered: true });

  }

  // delete alarm setting by parameter 
  deleteAlarmSetting() {

    this.action = 'setAlarmSetting'; this.alarm_setting_status = 'delete';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.modalClose.close();
      this.toastr.error(res.data);
      this.getDevices()

    })

  }
  // end alarm tab

  getMonthsAndYear() {

    this.months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    for (let i = 2020; i < (new Date().getFullYear() + 1); i++) {
      this.years.push(i)
    }
  }

  onChangeMonthYear() {
    if (this.month != undefined && this.year != undefined) this.getPerKwhUnitRate(this.month.toLowerCase(), this.year);
  }

  getPerKwhUnitRate(month, year) {

    this.action = 'getSettings'; this.cost = 0.00; this.service_charges = 0.00; this.misc_charges = 0.00;
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      let config = res.data?.config[0];
      let data = JSON.parse(config.cost_by_month);
      if (data[month][year]) {
        this.fuel_rate = (data[month][year]['fuel_rate']) ? data[month][year]['fuel_rate'] : 0.00;
        this.cost = data[month][year]['per_kwh_unit_rate(rs)'];
        this.selling_rate = data[month][year]['selling_rate(rs)'];
        this.genset_unit_rate_rs = data[month][year]['genset_unit_rate_rs'];
        this.service_charges = data[month][year]['service_charges'];
        this.misc_charges = data[month][year]['misc_charges'];
        this.late_charges = data[month][year]['late_charges'];
      }
    })
  }

  updatePerKwhUnitRate() {
    this.action = 'updateSetting';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.data.message);
      } else {
        this.toastr.error('Some Thing Went Wrong');
      }
    })
    this.getPerKwhUnitRateTable();
  }

  getPerKwhUnitRateTable() {

    this.action = 'getSettings'; let tabular_data = []; this.tabular_data = [];
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let config = res.data?.config[0];
      let data = JSON.parse(config.cost_by_month);
      this.fuel_rate_status = (data['january'][new Date().getFullYear()]['fuel_rate']) ? true : false;

      for (let y = 2020; y < (new Date().getFullYear() + 1); y++) {
        for (let m = 0; m < this.months.length; m++) {
          if (data[this.months[m].toLowerCase()][y]) {

            tabular_data.push({
              month: this.months[m].toUpperCase(),
              year: y,
              fuel_rate: (data[this.months[m].toLowerCase()][y]['fuel_rate']) ? data[this.months[m].toLowerCase()][y]['fuel_rate'] : 0.00,
              per_kwh_unit_rate: data[this.months[m].toLowerCase()][y]['per_kwh_unit_rate(rs)'],
              selling_rate: data[this.months[m].toLowerCase()][y]['selling_rate(rs)'],
              genset_unit_rate_rs: data[this.months[m].toLowerCase()][y]['genset_unit_rate_rs'],
              service_charges: data[this.months[m].toLowerCase()][y]['service_charges'],
              misc_charges: data[this.months[m].toLowerCase()][y]['misc_charges'],
              late_charges: data[this.months[m].toLowerCase()][y]['late_charges'],
              modified_on: data[this.months[m].toLowerCase()][y]['modified_on']
            })
          }
        }
      }

      this.cost_pages = [];
      for (let i = 1; i <= (tabular_data.length / 12); i++) {
        this.cost_pages.push(i);
      }

      tabular_data = tabular_data.slice(this.cost_slice.start, this.cost_slice.end);
      this.tabular_data = tabular_data;

    })
  }


  pageSelection(e) {

    if (e == 'prev_page') {

      this.cost_page = (this.cost_page == 1) ? 1 : parseInt(this.cost_page.toString()) - 1;

      if (this.cost_slice.start != 0) {
        this.cost_slice.start = this.cost_slice.start - 12;
        this.cost_slice.end = this.cost_slice.end - 12;
        this.getPerKwhUnitRateTable();
      }

    }

    if (e == 'curr_page') {
      this.cost_slice.start = (this.cost_page - 1) * 12
      this.cost_slice.end = (this.cost_page) * 12
      this.cost_page = parseInt(this.cost_page.toString());
      this.getPerKwhUnitRateTable();

    }

    if (e == 'next_page') {

      this.cost_page = (this.cost_pages.length == this.cost_page) ? this.cost_page : parseInt((this.cost_page).toString()) + 1;

      if (this.cost_slice.end != (this.cost_pages.length * 12)) {
        this.cost_slice.start = this.cost_slice.start + 12;
        this.cost_slice.end = this.cost_slice.end + 12;
        this.getPerKwhUnitRateTable();
      }
    }
  }

  // for counter correction tab
  getLevelCounter() {

    this.action = 'getDeviceLevelKPI';

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let data = res.data;

      if (data) {

        this.refelling.current_counter = data.refelling.counter.toFixed(2);
        this.consumption.current_counter = data.consumption.counter.toFixed(2);

      }
    })
  }

  setNewCounter(type) {

    if (type == 'refelling') this.refelling.new_counter = parseFloat(this.refelling.current_counter) + this.refelling.correction_counter
    if (type == 'consumption') this.consumption.new_counter = parseFloat(this.consumption.current_counter) + this.consumption.correction_counter
  }

  updateLevelCounter(type) {

    this.counter_type = type;

    let key = (type == 'refelling') ? 'cc_ref' : 'cc_con';
    let value = (type == 'refelling') ? this.refelling.correction_counter : this.consumption.correction_counter;

    this.counter.current_counter = (type == 'refelling') ? this.refelling.current_counter : this.consumption.current_counter;
    this.counter.correction_counter = (type == 'refelling') ? this.refelling.correction_counter : this.consumption.correction_counter;
    this.counter.new_counter = (type == 'refelling') ? this.refelling.new_counter : this.consumption.new_counter;

    // create payload for ms device 
    let payload = {
      topic: this.topic,
      payload: {
        'btf': 1,
        [key]: value
      }
    }

    // send to aws api gateway
    this.awsApiGateway(payload);
    this.insertCounterCorrection()
  }

  insertCounterCorrection() {

    this.action = 'insertCounterCorrection';

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.toastr.info('Successfully Create a Log.');
      clearInterval(this.akd_interval);

      // run in every 5 seconds
      this.akd_interval = + setInterval(() => {
        this.checkAkdForDevice();
      }, 5000);

    })

  }

  checkAkdForDevice() {

    this.action = 'getCounterCorrection';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.akd_ts != 'not available') {

        if (res.data.timestamp.getTIme() < res.data.akd_ts.getTIme()) {
          clearInterval(this.akd_interval);
          this.toastr.success('acknowledged');
        } else {
          this.toastr.warning('wating for acknowledged');
        }
      } else {
        this.toastr.error('akd_ts not recieve')
      }
    })
  }
  // end counter correction tab
}