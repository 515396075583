import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { DataSharedService } from '../shared/services/data-shared.service';
import { isPlatformBrowser } from '@angular/common';
import { DashboardService } from '../shared/services/dashboard.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ToastrService } from 'ngx-toastr';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LoginService } from 'src/app/shared/services/login.service';
import { EmitterService } from '../shared/services/event-service';

@Component({
  selector: 'app-pv-net-metering',
  templateUrl: './pv-net-metering.component.html',
  styleUrls: ['./pv-net-metering.component.scss']
})

export class PvNetMeteringComponent implements OnInit {

  // chart
  private chart: am4charts.XYChart;
  public valueAxis: am4charts.ValueAxis;

  //undefine 
  public graph: any = null;
  public graph_month: any = null;
  public last_update_chart_data: any;
  public installed_capicity: any;
  public predictic_generation: any;
  public inverter_make: any;
  public panel_make: any;
  public location: any;
  public solar_insolation: any;
  public average_sun_perday: any;
  public carbon_factor: any;
  public power_props: any;
  public nm_location: any;

  //boolean
  public show_pv_power: Boolean = false;
  public show_ke_power: Boolean = false;
  public show_ke_power_feed_out: Boolean = false;
  public show_gs_power: Boolean = false;
  public show_gs_power_line: Boolean = false;
  public tab_graph_status: boolean = false;
  public tab_billing_and_income_table_status: boolean = false;
  public tab_saving_table_status: boolean = false;
  public tab_pv_yeild_energy_status: boolean = false;
  public tab_pv_yeild_energy_graph: boolean = false;
  public tab_consumption_status: boolean = false;
  public tab_saving_pv_knx_status: boolean = false;
  public tab_consumption_graph: boolean = false;
  public tab_energy_balance_status: boolean = false;
  public tab_energy_balance_graph: boolean = false;
  public tab_plant_performance_by_table_status: boolean = false;
  public tab_plant_performance_by_graph_status: boolean = false;
  public tab_basis_of_plant_performance_calculation_status: boolean = false;
  public tab_solar_perfomance_status: boolean = false;
  public power_status: boolean = true;
  public state_status: boolean = true;
  public stock_status: boolean = true;
  public weather_status: boolean = true;
  public stock_export_to_grid: boolean = false;
  public stock_pv_self_use: boolean = false;
  public stock_import_from_grid: boolean = false;
  public stock_genset: boolean = false;
  public stock_graph_heading: boolean = true;

  //int
  public card_interval: number;
  public current_state_pv: any = 0;
  public current_state_ke: any = 0;
  public current_state_load: any = 0;
  public current_state_gs: any = 0;
  public export_to_grid_sum: any = 0.00;
  public tabular_exprot_sum: any = 0.00;
  public tabular_sale_sum: any = 0.00;
  public tabular_saving_sum: any = 0.00;
  public import_from_grid_sum: any = 0.00;
  public gs_sum: any = 0.00;
  public tabular_import_sum: any = 0.00;
  public tabular_buy_sum: any = 0.00;
  public tabular_total_saving_sum: any = 0.00;
  public pv_self_use_sum: any = 0.00;
  public tabular_balance_sum: any = 0.00;
  public tabular_pv_sum: any = 0.00;
  public tabular_gs_sum: any = 0.00;
  public sum: any = 0.00;
  public pv_yeild_use_sum = 0.00;
  public export_rate: any = 0.00;
  public pv_rate: any = 0.00;
  public pv_yeild_rate: any = 0.00;
  public consumption_sum: any = 0.00;
  public import_rate: any = 0.00;
  public gs_rate: any = 0.00;
  public consumption_rate: any = 0.00;
  public energy_balance_sum: any = 0.00;
  public energy_balance_rate: any = 0.00;
  public export_to_grid_percentage: any = 0.00;
  public import_from_grid_percentage: any = 0.00;
  public pv_self_use_percentage: any = 0.00;
  public buy_from_grid: any = 0.00;
  public sale_to_grid: any = 0.00;
  public gs_unit_rate: any = 0.00;
  public balance: any = 0.00;
  public carbon_saving_sum: any = 0.00;
  public difference_sum: any = 0.00;
  public cuf_avg: any = 0.00;
  public predictic_generation_avg: any = 0.00;
  public yeild_avg: any = 0.00;
  public actual_generation_avg: any = 0.00;
  public performance_ratio_avg: any = 0.00;
  public plant_loading_factor_avg: any = 0.00;
  public solar_irradiance: any = 0.00;
  public curr_state_table_power_sum: any = 0.00;
  public curr_state_table_yeild_sum: any = 0.00;
  public curr_state_table_self_sum: any = 0.00;
  public curr_state_table_exp_sum: any = 0.00;
  public curr_state_table_imp_sum: any = 0.00;
  public curr_state_table_gs_sum: any = 0.00;

  //array
  public weather_chart: any = [];
  public power_data: any = [];
  public energy_data: any = [];
  public tabular_billing_and_income_data: any = [];
  public tabular_saving_data: any = [];
  public pv_yeild_energy_graph: any = [];
  public graph_list: any = [];
  public pv_yeild_energy = [];
  public consumption = [];
  public energy_balance = [];
  public device_ids = [];
  public solar_performance_values = [];
  public solar_performance = [];
  public solar_performance_graph = [];
  public ke_devices: any = [];
  public pv_devices: any = [];
  public gs_devices: any = [];
  public curr_state_table: any = [];

  //string
  public month: String = 'N/A';
  public pv_power_chart_active: String = 'bar';
  public weather_icon_today: String = '';
  public weather_icon_1: String = '';
  public weather_icon_2: String = '';
  public weather_icon_3: String = '';
  public weather_icon_4: String = '';
  public weather_temp_today: String = '';
  public weather_temp_1: String = '';
  public weather_temp_2: String = '';
  public weather_temp_3: String = '';
  public weather_temp_4: String = '';
  public weather_day: String = '';
  public weather_day_1: String = '';
  public weather_day_2: String = '';
  public weather_day_3: String = '';
  public weather_day_4: String = '';
  public pv_power_date: String = '';
  public pv_date: String = '';
  public date: String = '';
  public tabular_date_to: String = '';
  public tabular_date_from: String = '';
  public action: String = '';
  public interval: String = '';
  public data_param: String = '';
  public data_param_el: String = '';
  public curr_tab = 'tab-graph';
  public city_location = 'N/A';
  public power_header: string = 'GRAPH';
  public state_header: string = 'GRAPH';
  public weather_header: string = 'GRAPH';
  public power_class: string = 'col-md-8 grid-margin stretch-card';
  public state_class: string = 'col-md-4 grid-margin stretch-card';
  public stock_class: string = 'col-md-8 grid-margin stretch-card';
  public weather_class: string = 'col-md-4 grid-margin stretch-card';
  public starting_month_for_tables = '';
  public main_logo = '';
  public user: any;
  public curr_date: string = '';
  public nm_site: string = '';
  public full_load: any = 0;

  public saving_pv_knx: any = [];
  public saving_pv_knx_import_sum: any = 0;
  public saving_pv_knx_pv_sum: any = 0;
  public saving_pv_knx_gs_sum: any = 0;
  public saving_pv_knx_consumption_sum: any = 0;
  public saving_pv_knx_full_load_sum: any = 0;
  public saving_pv_knx_saving_sum: any = 0;
  public sh_timing: any;

  tabular_selected: any = { start: moment(), end: moment() };
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Week': [moment().startOf('week'), moment().endOf('days')],
    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('days')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  constructor(private authService: LoginService, private dataShared: DataSharedService, private dashboardService: DashboardService, @Inject(PLATFORM_ID) private platformId, private zone: NgZone, private toastr: ToastrService, private eventEmitter: EmitterService) {
    this.graph_list = ['Pv-Yeild Energy', 'Consumption', 'Energy Balance'];
    this.month = this.tabular_selected.start['_d'].toLocaleString('default', { month: 'long' });
  }

  ngOnInit(): void {
    this.setMainLogo(); this.currentUser();
    this.getDashboardSettings(); this.getCurrDate(); this.getForcast(); this.getSolarPerfomance();
    // Chart code goes in here
    this.browserOnly(() => {
      this.getDeviceIds();
    });

    if (localStorage.getItem('user') == '20' || this.curr_tab == 'tab-graph') {
      // run in every 5 minutus
      this.card_interval = + setInterval(() => {
        this.getChartData();
      }, 300000);

      // run in every 3 hours
      this.card_interval = + setInterval(() => {
        this.getForcast();
      }, 10800000);
    }

  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  currentUser() {
    this.authService.getUser().subscribe((res: any) => {
      if (res?.data || res?.user) {
        this.user = (res.data) ? res.data : res.user;
      }
    })
  }

  setMainLogo() {
    let body = {
      "action": "getLogo",
      "account_id": localStorage.getItem('account')
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      this.main_logo = (res.data[0]) ? 'https://app.zainergy.com/backend/zainergy/assets/' + res.data[0].private_hash + '?key=directus-large-contain' : 'assets/images/zainergy-logo.svg';
    })
  }

  getDashboardSettings() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].tab_setting) : JSON.parse(config[0].sub_tab_setting);
        this.tab_graph_status = data.allow_tab.pv_net_metering.graph;
        this.tab_billing_and_income_table_status = data.allow_tab.pv_net_metering.billing_and_income_table;
        this.tab_saving_table_status = data.allow_tab.pv_net_metering.saving_table;
        this.tab_pv_yeild_energy_status = data.allow_tab.pv_net_metering.pv_yeild_energy;
        this.tab_consumption_status = data.allow_tab.pv_net_metering.consumption;
        this.tab_energy_balance_status = data.allow_tab.pv_net_metering.energy_balance;
        this.tab_plant_performance_by_table_status = data.allow_tab.pv_net_metering.plant_performance_by_table;
        this.tab_plant_performance_by_graph_status = data.allow_tab.pv_net_metering.plant_performance_by_graph;
        this.tab_basis_of_plant_performance_calculation_status = data.allow_tab.pv_net_metering.basis_of_plant_performance_calculation;

        let meta_value = JSON.parse(config[0].meta_value)
        let nm = Object.values(meta_value.nm)[0];

        this.tab_saving_pv_knx_status = data.allow_tab.pv_net_metering.saving_pv_knx;
        this.full_load = nm['sh_timing'] ? nm['sh_timing'].full_load : 0;
        this.sh_timing = nm['sh_timing'] ? nm['sh_timing'] : {};
      }
    })
  }

  getDeviceIds() {

    this.eventEmitter.listen('nmDevices', nm => {

      this.ke_devices = []; this.pv_devices = []; this.gs_devices = []; this.device_ids = [];
      let ke_devices = []; let pv_devices = []; let gs_devices = []; let device_ids = [];
      this.stock_graph_heading = true; this.stock_export_to_grid = false; this.stock_pv_self_use = false;
      this.stock_import_from_grid = false; this.stock_genset = false; this.graph = null; this.sum = 0.00;

      this.tabular_billing_and_income_data = []; this.tabular_saving_data = []; this.pv_yeild_energy = [];
      this.consumption = []; this.energy_balance = []; this.solar_performance = [];

      this.nm_location = nm[1]

      this.full_load = nm[0]['sh_timing'] ? nm[0]['sh_timing'].full_load : 0
      this.sh_timing = nm[0]['sh_timing'] ? nm[0]['sh_timing'] : {}

      for (let k = 0; k < nm[0].ke.length; k++) {
        ke_devices.push(nm[0].ke[k]);
      }

      for (let p = 0; p < nm[0].pv.length; p++) {
        pv_devices.push(nm[0].pv[p]);
      }

      if (nm[0].gs != undefined) {

        for (let g = 0; g < nm[0].gs.length; g++) {
          gs_devices.push(nm[0].gs[g]);
        }

        this.show_gs_power = true;

      } else {

        gs_devices = [];
        this.show_gs_power = false;
      }

      // Here array.values() function is called.
      var ke_iterator = nm[0].ke.values();
      var pv_iterator = nm[0].pv.values();
      var gs_iterator = (nm[0].gs != undefined) ? nm[0].gs.values() : [];

      // Here all the elements of the array is being printed.
      for (let elements of ke_iterator) {
        device_ids.push(elements);
      }

      for (let elements of pv_iterator) {
        device_ids.push(elements);
      }

      if (nm[0].gs != undefined) {
        for (let elements of gs_iterator) {
          device_ids.push(elements);
        }
      }

      this.ke_devices = ke_devices; this.pv_devices = pv_devices; this.gs_devices = gs_devices; this.device_ids = device_ids; this.nm_site = (nm[1]) ? nm[1] : "";

      this.getSelectedDeviceInfo();

      if (this.curr_tab == 'tab-graph') {
        this.getCurrDate();
        // Chart code goes in here
        this.browserOnly(() => {
          this.getChartData();
          this.getForcast();
        });

        this.getCurrentState();
        this.getCurrentStateTable();

      } else if (this.curr_tab == 'tab-plant-performance-by-graph') {
        this.getSolarPerfomanceGraph();
      } else if (this.curr_tab == 'tab-bill-income-table' || this.curr_tab == 'tab-saving-table' || this.curr_tab == 'tab-pv-yeild-energy' || this.curr_tab == 'tab-consumption' || this.curr_tab == 'tab-energy-balance' || this.curr_tab == 'tab-plant-performance-by-table') {
        this.getTableData()
      } else if (this.curr_tab == 'tab-saving-pv-knx') {
        this.getSavingPvKNXTableData()
      }
    })
  }

  getSelectedDeviceInfo() {

    let body = {
      'action': 'getDeviceByID',
      'device_id': [this.device_ids]
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {
      if (res.status === 200 && res.data[this.device_ids[0]]) {
        let device_info = res.data[this.device_ids[0]];
        if (device_info.starting_billing_month != null) {
          this.starting_month_for_tables = device_info.starting_billing_month;
        }
      }
    })
  }

  tabChange($event: NgbTabChangeEvent) {

    this.curr_tab = $event.nextId;

    this.tabular_date_from = this.tabular_selected.start['_d'].toISOString().substring(0, 10);
    this.tabular_date_to = this.tabular_selected.end['_d'].toISOString().substring(0, 10);

    if (this.curr_tab == 'tab-graph') {
      this.getCurrDate();
      // Chart code goes in here
      this.browserOnly(() => {
        this.getChartData();
        this.getForcast();
      });

    } else if (this.curr_tab == 'tab-plant-performance-by-graph') {
      this.getSolarPerfomanceGraph();
    } else if (this.tabular_billing_and_income_data.length == 0) {
      this.getTableData()
    }
    if (this.curr_tab == 'tab-saving-pv-knx') this.getSavingPvKNXTableData();
  }

    getCurrDate() {
      let date = new Date();
      this.date = this.pv_power_date = this.pv_date = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    }

  onDateSelection(e, param) {
    this.date = e.target.value;
    if (param == 'power_trend') this.getChartData();
    if (param == 'pv') this.getStockChartData(this.graph);

  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  onChangeGraph(e) {

    this.getStockChartData(e);
  }

  onTabularDateSelection(e) {
    this.tabular_date_from = e.start['_d'].toISOString().substring(0, 10);
    this.tabular_date_to = e.end['_d'].toISOString().substring(0, 10);
    this.month = e.start['_d'].toLocaleString('default', { month: 'long' });
  }

  onClick() {
    this.getBuyandSale();
    (this.curr_tab == 'tab-plant-performance-by-graph') ? this.getSolarPerfomanceGraph() : this.getTableData();
    if (this.curr_tab == 'tab-saving-pv-knx') this.getSavingPvKNXTableData();
  }

  getChartData() {

    let ke_data_sum = []; let pv_data_sum = []; let gs_data_sum = []; let ke_data = []; let pv_data = []; let gs_data = [];
    let power_trend = []; this.action = 'netMeteringPowerTrend'; let pv_max_length; let ke_max_length; let pv_data_length = [];
    let ke_data_length = []; let gs_max_length; let gs_data_length = []; let pv_power = 0; let ke_power = 0; let gs_power = 0;
    let timestamp = [];

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets) {

        for (let i = 0; i < res.data.buckets.length; i++) {

          for (let k = 0; k < this.ke_devices.length; k++) {

            if (this.ke_devices[k] == res.data.buckets[i].key) {

              ke_data_length.push(res.data.buckets[i].date_agg.buckets.length);
              ke_data.push(res.data.buckets[i].date_agg.buckets.reverse());

            }
          }

          for (let p = 0; p < this.pv_devices.length; p++) {

            if (this.pv_devices[p] == res.data.buckets[i].key) {

              pv_data_length.push(res.data.buckets[i].date_agg.buckets.length);
              pv_data.push(res.data.buckets[i].date_agg.buckets.reverse())

            }
          }

          if (this.gs_devices[0]) {

            for (let g = 0; g < this.gs_devices.length; g++) {

              if (this.gs_devices[g] == res.data.buckets[i].key) {

                gs_data_length.push(res.data.buckets[i].date_agg.buckets.length);
                gs_data.push(res.data.buckets[i].date_agg.buckets.reverse())

              }
            }
          }
        }

        ke_max_length = Math.max(...ke_data_length); pv_max_length = Math.max(...pv_data_length);

        for (let i = 0; i < ke_max_length; i++) {

          let value = 0; let sum = 0;

          for (let j = 0; j < this.ke_devices.length; j++) {

            if (ke_data[j][i]) {
              value = ke_data[j][i].val_total_w.value;
            } else {
              value = 0;
            }

            sum += value;

            if (j == (this.ke_devices.length - 1)) {
              ke_data_sum.push({
                key_as_string: ke_data[j][i].key_as_string,
                val_total_w: { value: sum }
              })
            }
          }
        }

        for (let i = 0; i < pv_max_length; i++) {

          let value = 0; let sum = 0; let l = 0;

          for (let j = 0; j < pv_data.length; j++) {

            if (pv_data[j][i]) {

              value = pv_data[j][i].val_total_w.value;
            } else {
              value = 0;
            }

            sum += value;
            if (pv_max_length == pv_data[j].length) l = j
            if (j == (pv_data.length - 1)) {
              pv_data_sum.push({
                key_as_string: pv_data[l][i].key_as_string,
                val_total_w: { value: sum }
              })
            }
          }
        }

        if (this.gs_devices[0]) {

          gs_max_length = Math.max(...gs_data_length);

          for (let i = 0; i < gs_max_length; i++) {

            let value = 0; let sum = 0; let l = 0;

            for (let j = 0; j < gs_data.length; j++) {

              if (gs_data[j][i]) {

                value = gs_data[j][i].val_total_w.value;
              } else {

                value = 0;
              }

              sum += value;


              if (gs_max_length == gs_data[j].length) l = j
              if (j == (gs_data.length - 1)) {

                gs_data_sum.push({
                  key_as_string: gs_data[l][i].key_as_string,
                  val_total_w: { value: sum }
                })
              }

            }
          }
        }

        ke_data_sum.reverse();
        pv_data_sum.reverse();
        gs_data_sum.reverse();

        for (let h = 0; h < 24; h++) {

          for (let m = 0; m < 60; m++) {

            timestamp.push({
              hours: String(h).padStart(2, "0"),
              minutes: String(m).padStart(2, "0")
            })
            m += 4;
          }
        }

        let ke_power_data = []; let pv_power_data = []; let gs_power_data = [];

        //for ke
        for (let i = 0; i < timestamp.length; i++) {

          for (let j = 0; j < ke_data_sum.length; j++) {

            let hour = ke_data_sum[j].key_as_string.slice(11, 13); let minutes = ke_data_sum[j].key_as_string.slice(14, 16);

            if (timestamp[i].hours == hour && timestamp[i].minutes == minutes) {

              ke_power_data.push({
                date: new Date(ke_data_sum[j].key_as_string),
                ke_power: ke_data_sum[j].val_total_w.value
              })
              break;

            } else if (timestamp[i].hours < hour && (timestamp[i].minutes < minutes || timestamp[i].minutes == '55')) {

              ke_power_data.push({
                date: new Date(ke_data_sum[0].key_as_string.slice(0, 10) + 'T' + timestamp[i].hours + ':' + timestamp[i].minutes + ':00.000+05:00'),
                ke_power: 0
              })
              break;
            }
          }
        }

        //for pv
        for (let i = 0; i < timestamp.length; i++) {

          for (let j = 0; j < pv_data_sum.length; j++) {

            let hour = pv_data_sum[j].key_as_string.slice(11, 13);
            let minutes = pv_data_sum[j].key_as_string.slice(14, 16);

            if (timestamp[i].hours == hour && timestamp[i].minutes == minutes) {

              pv_power_data.push({
                date: new Date(pv_data_sum[j].key_as_string),
                pv_power: pv_data_sum[j].val_total_w.value
              })
              break;

            } else if (timestamp[i].hours < hour && (timestamp[i].minutes < minutes || timestamp[i].minutes == '55')) {

              pv_power_data.push({
                date: new Date(pv_data_sum[0].key_as_string.slice(0, 10) + 'T' + timestamp[i].hours + ':' + timestamp[i].minutes + ':00.000+05:00'),
                pv_power: 0
              })
              break;
            }
          }
        }

        //for gs
        if (this.gs_devices[0]) {

          for (let i = 0; i < timestamp.length; i++) {

            for (let j = 0; j < gs_data_sum.length; j++) {

              let hour = gs_data_sum[j].key_as_string.slice(11, 13); let minutes = gs_data_sum[j].key_as_string.slice(14, 16);

              if (timestamp[i].hours == hour && timestamp[i].minutes == minutes) {

                gs_power_data.push({
                  date: new Date(gs_data_sum[j].key_as_string),
                  gs_power: gs_data_sum[j].val_total_w.value
                })
                break;

              } else if (timestamp[i].hours < hour && (timestamp[i].minutes < minutes || timestamp[i].minutes == '55')) {

                gs_power_data.push({
                  date: timestamp[i].hours + ':' + timestamp[i].minutes,
                  gs_power: 0
                })
                break;
              }

            }
          }
        }

        let power_length = Math.max(ke_power_data.length, pv_power_data.length, gs_power_data.length);
        let date;

        for (let i = 0; i < power_length; i++) {

          let pv_power = (pv_power_data[i]) ? pv_power_data[i].pv_power : 0;
          let ke_power = (ke_power_data[i]) ? ke_power_data[i].ke_power : 0;
          let gs_power = (gs_power_data[i]) ? gs_power_data[i].gs_power : 0;

          if (ke_power_data.length == power_length) date = ke_power_data[i].date;
          if (pv_power_data.length == power_length) date = pv_power_data[i].date;
          if (gs_power_data.length == power_length) date = gs_power_data[i].date;

          power_trend.push({
            date: date,
            pv_power: pv_power,
            ke_power: ke_power,
            gs_power: gs_power,
            load_power: pv_power + ke_power + gs_power,
          });

        }

        this.power_data = power_trend;
        this.last_update_chart_data = (this.power_data.length > 0) ? this.power_data[this.power_data.length - 1].date : 'not available';
        this.power_props = this.getGraphProp('power_trend', this.power_data);
        this.dynamicChartValues();
      }
    });
  }

  getCurrentState() {

    this.action = 'getCurrentStatePower';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.current_state_ke = res.data.ke_power;
      this.current_state_pv = res.data.pv_power;
      this.current_state_gs = res.data.gs_power;

      this.show_ke_power = (this.current_state_ke > 0) ? true : false;
      this.show_ke_power_feed_out = (this.current_state_ke < 0) ? true : false;

      this.show_pv_power = (this.current_state_pv > 0) ? true : false;

      this.show_gs_power_line = (this.current_state_gs > 0) ? true : false;

      this.current_state_load = this.current_state_ke + this.current_state_pv + this.current_state_gs;

    })
  }

  getCurrentStateTable() {

    this.action = 'getCurrentStateTable'; let data = []; let curr_state_table = []; this.curr_state_table_power_sum = 0.00; this.curr_state_table_yeild_sum = 0.00;
    this.curr_state_table_self_sum = 0.00; this.curr_state_table_exp_sum = 0.00; this.curr_state_table_imp_sum = 0.00; this.curr_state_table_gs_sum = 0.00;

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let first = res.data.first; let last = res.data.last;

      // ke export
      let exp_first = 0.00;
      let exp_last = 0.00;

      // ke imp 
      let imp_first = 0.00;
      let imp_last = 0.00;

      // for data length 
      for (let i = 0; i < last.length; i++) {

        // ke devices length 
        for (let k = 0; k < this.ke_devices.length; k++) {

          if (first[i].device_id == this.ke_devices[k]) {

            // ke export
            exp_first = (res.data.first[i].total_wh_exp) ? res.data.first[i].total_wh_exp : 0.00;
            exp_last = (res.data.last[i].total_wh_exp) ? res.data.last[i].total_wh_exp : 0.00;

            // ke imp 
            imp_first = (res.data.first[i].total_wh) ? res.data.first[i].total_wh : 0.00;
            imp_last = (res.data.last[i].total_wh) ? res.data.last[i].total_wh : 0.00;

            data.push({
              icon: "theme-icon-2 grid-icon",
              location: first[i].location,
              total_power: last[i].total_w,
              total_power_percentage: 0.00,
              pv_yeild: "----",
              pv_self_use: "----",
              total_exp: exp_last - exp_first,
              total_imp: imp_last - imp_first,
              total_genset: "----",
              last_update: new Date(last[i].last_update).toLocaleString()
            })
          }
        }

        // pv devices length 
        for (let p = 0; p < this.pv_devices.length; p++) {

          if (first[i].device_id == this.pv_devices[p]) {

            data.push({
              icon: "theme-icon-2 solar-icon",
              location: first[i].location,
              total_power: last[i].total_w,
              total_power_percentage: 0.00,
              pv_yeild: last[i].total_wh - first[i].total_wh,
              pv_self_use: (last[i].total_wh - first[i].total_wh) - (exp_last - exp_first),
              total_exp: "----",
              total_imp: "----",
              total_genset: "----",
              last_update: new Date(last[i].last_update).toLocaleString()
            })
          }
        }

        // gs devices length 
        if (this.gs_devices[0]) {

          for (let g = 0; g < this.gs_devices.length; g++) {

            if (first[i].device_id == this.gs_devices[g]) {

              data.push({
                icon: "theme-icon-2 genset-icon",
                location: first[i].location,
                total_power: last[i].total_w,
                total_power_percentage: 0.00,
                pv_yeild: "----",
                pv_self_use: "----",
                total_exp: "----",
                total_imp: "----",
                total_genset: last[i].total_wh - first[i].total_wh,
                last_update: new Date(last[i].last_update).toLocaleString()
              })
            }

          }
        }

        if (data[i]) {

          let keys = Object.keys(data[i]);
          let values = [];

          for (let j = 0; j < Object.values(data[i]).length; j++) {

            if (typeof Object.values(data[i])[j] == 'number') {

              let value = Object.values(data[i])[j] as number;
              values.push(value.toFixed(2));

            } else {

              let value = Object.values(data[i])[j];
              values.push(value);
            }
          }

          let merged = keys.reduce((obj, key, index) => ({ ...obj, [key]: values[index] }), {});
          curr_state_table.push(merged);

        }
      }

      for (let i = 0; i < data.length; i++) {

        if (data[i].total_power && typeof data[i].total_power == 'number') this.curr_state_table_power_sum += data[i].total_power;
        if (data[i].pv_yeild && typeof data[i].pv_yeild == 'number') this.curr_state_table_yeild_sum += data[i].pv_yeild;
        if (data[i].pv_self_use && typeof data[i].pv_self_use == 'number') this.curr_state_table_self_sum += data[i].pv_self_use;
        if (data[i].total_exp && typeof data[i].total_exp == 'number') this.curr_state_table_exp_sum += data[i].total_exp;
        if (data[i].total_imp && typeof data[i].total_imp == 'number') this.curr_state_table_imp_sum += data[i].total_imp;
        if (data[i].total_genset && typeof data[i].total_genset == 'number') this.curr_state_table_gs_sum += data[i].total_genset;

      }

      // sum 
      for (let i = 0; i < curr_state_table.length; i++) {
        curr_state_table[i].total_power_percentage = curr_state_table[i].total_power / this.curr_state_table_power_sum * 100;
      }

      this.curr_state_table = curr_state_table;
    })
  }

  getStockChartData(e) {

    this.action = "getEnergyHourlyData"; let stock_data = []; let ke_data = []; let pv_data = []; let gs_data = [];
    let ke_data_diff = []; let pv_data_diff = []; let gs_data_diff = []; this.stock_graph_heading = false;

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      for (let i = 0; i < this.device_ids.length; i++) {

        for (let k = 0; k < this.ke_devices.length; k++) {
          if (this.device_ids[i] == this.ke_devices[k]) {
            ke_data.push({
              first: res.data.first['device_' + [i + 1]],
              last: res.data.last['device_' + [i + 1]]
            });
          }
        }

        for (let p = 0; p < this.pv_devices.length; p++) {
          if (this.device_ids[i] == this.pv_devices[p]) {
            pv_data.push({
              first: res.data.first['device_' + [i + 1]],
              last: res.data.last['device_' + [i + 1]]
            });
          }
        }

        if (this.gs_devices[0]) {
          for (let g = 0; g < this.gs_devices.length; g++) {
            if (this.device_ids[i] == this.gs_devices[g]) {
              gs_data.push({
                first: res.data.first['device_' + [i + 1]],
                last: res.data.last['device_' + [i + 1]]
              });
            }
          }
        }
      }

      for (let i = 1; i <= 24; i++) {

        let ke_imp_value = 0; let ke_exp_value = 0; let pv_value = 0; let gs_value = 0;
        let ke_imp_sum = 0; let ke_exp_sum = 0; let pv_sum = 0; let gs_sum = 0;

        for (let k = 0; k < ke_data.length; k++) {

          ke_imp_value = (ke_data[k].last[i].total_wh == 0 || ke_data[k].first[i].total_wh == 0) ? 0 : ke_data[k].last[i].total_wh - ke_data[k].first[i].total_wh;
          ke_exp_value = (ke_data[k].last[i].total_wh_exp == 0 || ke_data[k].first[i].total_wh_exp == 0) ? 0 : ke_data[k].last[i].total_wh_exp - ke_data[k].first[i].total_wh_exp;

          ke_imp_sum += ke_imp_value;
          ke_exp_sum += ke_exp_value;

          if (k == (ke_data.length - 1)) {
            ke_data_diff.push({
              // date: new Date(ke_data[k].last[i]['@timestamp']),
              total_wh_imp: ke_imp_sum,
              total_wh_exp: ke_exp_sum
            })
          }
        }

        for (let p = 0; p < pv_data.length; p++) {

          pv_value = (pv_data[p].last[i].total_wh == 0 || pv_data[p].first[i].total_wh == 0) ? 0 : pv_data[p].last[i].total_wh - pv_data[p].first[i].total_wh;

          pv_sum += pv_value;

          if (p == (pv_data.length - 1)) {
            pv_data_diff.push({
              // date: new Date(pv_data[p].last[i]['@timestamp']),
              total_wh: pv_sum
            })
          }
        }

        if (this.gs_devices[0]) {

          for (let g = 0; g < gs_data.length; g++) {

            gs_value = (gs_data[g].last[i].total_wh == 0 || gs_data[g].first[i].total_wh == 0) ? 0 : gs_data[g].last[i].total_wh - gs_data[g].first[i].total_wh;

            gs_sum += gs_value;

            if (g == (gs_data.length - 1)) {
              gs_data_diff.push({
                // date: new Date(gs_data[g].last[i]['@timestamp']),
                total_wh: gs_sum
              })
            }
          }
        }

        let date = res.data.last.device_1[i]['@timestamp'];
        let import_from_grid = (ke_data_diff[i - 1]) ? ke_data_diff[i - 1].total_wh_imp : 0;
        let export_to_grid = (ke_data_diff[i - 1]) ? ke_data_diff[i - 1].total_wh_exp : 0;
        let pv_total_wh = (pv_data_diff[i - 1]) ? pv_data_diff[i - 1].total_wh : 0;
        let gs_total_wh = (gs_data_diff[i - 1]) ? gs_data_diff[i - 1].total_wh : 0;

        if (e == 'Pv-Yeild Energy') {

          stock_data.push({
            date: new Date(date).getHours(),
            export_to_grid: export_to_grid,
            pv_self_use: pv_total_wh - export_to_grid,
          });

          this.sum = 0.00;

          this.export_to_grid_sum = stock_data.map(a => a.export_to_grid).reduce(function (a, b) {
            return a + b;
          });
          this.pv_self_use_sum = stock_data.map(a => a.pv_self_use).reduce(function (a, b) {
            return a + b;
          });

          this.sum = this.export_to_grid_sum + this.pv_self_use_sum;
          this.stock_export_to_grid = true; this.stock_pv_self_use = true;
          this.stock_import_from_grid = false; this.stock_genset = false;
        }

        if (e == 'Consumption') {

          stock_data.push({
            date: new Date(date).getHours(),
            import_from_grid: import_from_grid,
            pv_self_use: pv_total_wh - export_to_grid,
            genset: (this.gs_devices[0]) ? gs_total_wh : 0
          });

          this.sum = 0.00;

          this.import_from_grid_sum = stock_data.map(a => a.import_from_grid).reduce(function (a, b) {
            return a + b;
          });
          this.pv_self_use_sum = stock_data.map(a => a.pv_self_use).reduce(function (a, b) {
            return a + b;
          });

          if (this.gs_devices[0]) {
            this.gs_sum = parseFloat(this.dataShared.today_genset_kwh);
            this.stock_genset = true;
          }

          this.sum = this.import_from_grid_sum + this.pv_self_use_sum + this.gs_sum;
          this.stock_pv_self_use = true; this.stock_import_from_grid = true;
          this.stock_export_to_grid = false;
        }

        if (e == 'Energy Balance') {

          stock_data.push({
            date: new Date(date).getHours(),
            export_to_grid: export_to_grid,
            import_from_grid: import_from_grid
          });

          this.sum = 0.00;

          this.import_from_grid_sum = stock_data.map(a => a.import_from_grid).reduce(function (a, b) {
            return a + b;
          });
          this.export_to_grid_sum = stock_data.map(a => a.export_to_grid).reduce(function (a, b) {
            return a + b;
          });

          this.sum = this.import_from_grid_sum + this.export_to_grid_sum;
          this.stock_export_to_grid = true; this.stock_import_from_grid = true;
          this.stock_pv_self_use = false; this.stock_genset = false;
        }
      }

      this.energy_data = stock_data;
      let prop = this.getGraphProp('pv', this.energy_data);
      this.implementStackColumn(prop, e);
    })
  }

  dynamicChartValues() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].chart_setting) : JSON.parse(config[0].sub_chart_setting);

        this.power_header = data.pv_net_metering.graph.chart_1.header;
        this.state_header = data.pv_net_metering.graph.chart_2.header;
        this.weather_header = data.pv_net_metering.graph.chart_4.header;

        this.power_props.title = data.pv_net_metering.graph.chart_1.title;

        this.power_props.unit = data.pv_net_metering.graph.chart_1.unit;

        this.power_status = data.pv_net_metering.graph.chart_1.status;
        this.state_status = data.pv_net_metering.graph.chart_2.status;
        this.stock_status = data.pv_net_metering.graph.chart_3.status;
        this.weather_status = data.pv_net_metering.graph.chart_4.status;

        if (this.power_status == true && this.state_status == false) this.power_class = 'col-md-12 grid-margin stretch-card';
        if (this.power_status == false && this.state_status == true) this.state_class = 'col-md-12 grid-margin stretch-card';
        if (this.stock_status == true && this.weather_status == false) this.stock_class = 'col-md-12 grid-margin stretch-card';
        if (this.stock_status == false && this.weather_status == true) this.weather_class = 'col-md-12 grid-margin stretch-card';

        this.implementBarChart(this.power_props);
      }
    })

  }

  getForcast() {

    let d = new Date(); let n = d.getHours(); let weather = []; let weather_chart = []; let graph_data = []; this.action = 'getForecast';
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday'];

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.city_location = res.message.city.name;



      this.weather_day = days[d.getDay()];
      this.weather_icon_today = 'https://openweathermap.org/img/w/' + res.message.list[0].weather[0].icon + '.png';
      this.weather_temp_today = res.message.list[0].main.temp;

      res.message.list.forEach(element => {


        if (element.dt_txt.slice(11, 19) == '09:00:00') {
          weather_chart.push({
            date: new Date(element.dt_txt),
            temp: element.main.temp
          })

          weather.push(element)
        }
        graph_data.push({
          date: new Date(element.dt_txt),
          temp: element.main.temp
        })

      });

      this.weather_day_1 = days[d.getDay() + 1];
      this.weather_day_2 = days[d.getDay() + 2];
      this.weather_day_3 = days[d.getDay() + 3];
      this.weather_day_4 = days[d.getDay() + 4];

      if (n < 12) {

        this.weather_icon_1 = 'https://openweathermap.org/img/w/' + weather[1].weather[0].icon + '.png';
        this.weather_icon_2 = 'https://openweathermap.org/img/w/' + weather[2].weather[0].icon + '.png';
        this.weather_icon_3 = 'https://openweathermap.org/img/w/' + weather[3].weather[0].icon + '.png';
        this.weather_icon_4 = 'https://openweathermap.org/img/w/' + weather[4].weather[0].icon + '.png';

        this.weather_temp_1 = weather[1].main.temp;
        this.weather_temp_2 = weather[2].main.temp;
        this.weather_temp_3 = weather[3].main.temp;
        this.weather_temp_4 = weather[4].main.temp;

      }
      else if (n >= 12) {

        this.weather_icon_1 = 'https://openweathermap.org/img/w/' + weather[0].weather[0].icon + '.png';
        this.weather_icon_2 = 'https://openweathermap.org/img/w/' + weather[1].weather[0].icon + '.png';
        this.weather_icon_3 = 'https://openweathermap.org/img/w/' + weather[2].weather[0].icon + '.png';
        this.weather_icon_4 = 'https://openweathermap.org/img/w/' + weather[3].weather[0].icon + '.png';

        this.weather_temp_1 = weather[0].main.temp;
        this.weather_temp_2 = weather[1].main.temp;
        this.weather_temp_3 = weather[2].main.temp;
        this.weather_temp_4 = weather[3].main.temp;

      }

      this.weather_chart = weather_chart
      let prop = this.getGraphProp('weather', this.weather_chart);
      this.implementWeatherChart(prop);
    })

  }

  getSolarPerfomance() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {

      let config = res.data?.config;
      this.tab_solar_perfomance_status = true;
      let data = JSON.parse(config[0].solar_performance_parameters);
      if (data) {
        data = Object.freeze(data);
        this.installed_capicity = data.installed_capicity;
        this.predictic_generation = data.predictic_generation;
        this.inverter_make = data.inverter_make;
        this.panel_make = data.panel_make;
        this.location = data.location;
        this.solar_insolation = data.solar_insolation;
        this.average_sun_perday = data.average_sun_perday;
        this.carbon_factor = data.carbon_factor;
      } else {
        this.tab_solar_perfomance_status = false;
        this.installed_capicity = 0.00;
        this.predictic_generation = 0.00;
        this.inverter_make = '------';
        this.panel_make = '------';
        this.location = '------';
        this.solar_insolation = 0.00;
        this.average_sun_perday = 0.00;
        this.carbon_factor = 0.00;
      }


    })

  }

  getSolarPerfomanceGraph() {

    let solar_performance_graph = []; this.solar_irradiance = 0.00; let solar = 0.00; this.action = 'getEnergyDailyData';

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let pv_first = []; let pv_last = []; let pv_data = [];

      for (let i = 0; i < this.device_ids.length; i++) {
        for (let p = 0; p < this.pv_devices.length; p++) {
          if (this.pv_devices[p] == res.data.last['device_' + (i + 1)][1].device_id) {
            pv_first.push(res.data.first['device_' + (i + 1)]);
            pv_last.push(res.data.last['device_' + (i + 1)]);
          }
        }
      }

      for (let i = 1; i < pv_last[0].length; i++) {
        let total_wh_diff = 0; let total_wh_diff_sum = 0;

        for (let j = 0; j < this.pv_devices.length; j++) {
          total_wh_diff = (pv_first[j][i].total_wh <= pv_last[j][i].total_wh && pv_first[j][i].total_wh != 0) ? pv_last[j][i].total_wh - pv_first[j][i].total_wh : 0;
          total_wh_diff_sum += total_wh_diff;

          if (j == (this.pv_devices.length - 1)) {
            pv_data.push({
              key_as_string: pv_last[j][i]['@timestamp'],
              diff_wh: total_wh_diff_sum
            })
          }
        }
      }

      solar = (pv_first[0][1].solar_irradiance) ? pv_first[0][1].solar_irradiance : this.solar_insolation;

      if (pv_data[0].diff_wh) {
        for (let i = 0; i < pv_data.length; i++) {

          solar_performance_graph.push({
            date: (new Date(pv_data[i].key_as_string).toString()).slice(4, 10),
            installed_capicity: this.installed_capicity,
            predictic_generation: this.predictic_generation,
            pv_generation: pv_data[i].diff_wh
          });

          solar_performance_graph[i].carbon_saving = (solar_performance_graph[i].pv_generation * this.carbon_factor).toFixed(2);
          solar_performance_graph[i].differnce = (solar_performance_graph[i].pv_generation - solar_performance_graph[i].predictic_generation).toFixed(2);
          solar_performance_graph[i].cuf = ((solar_performance_graph[i].pv_generation / (solar_performance_graph[i].installed_capicity * 10)) * 100).toFixed(2);
          solar_performance_graph[i].yeild = (solar_performance_graph[i].pv_generation / solar_performance_graph[i].installed_capicity).toFixed(2);
          solar_performance_graph[i].actual_generation = ((solar_performance_graph[i].pv_generation / solar_performance_graph[i].predictic_generation) * 100).toFixed(2);
          solar_performance_graph[i].performance_ratio = ((solar_performance_graph[i].yeild * 100) / solar).toFixed(2);
          solar_performance_graph[i].plant_loading_factor = ((solar_performance_graph[i].pv_generation / (this.installed_capicity * 24)) * 100).toFixed(2);

        }

        this.solar_performance_graph = solar_performance_graph;
        let pv_prop = this.getGraphProp('pv_generation', this.solar_performance_graph);
        let carbon_saving_prop = this.getGraphProp('carbon_saving', this.solar_performance_graph);
        let cuf_prop = this.getGraphProp('cuf', this.solar_performance_graph);
        let yeild_prop = this.getGraphProp('yeild', this.solar_performance_graph);
        let performance_ratio_prop = this.getGraphProp('performance_ratio', this.solar_performance_graph);
        let plant_load_factor_prop = this.getGraphProp('plant_loading_factor', this.solar_performance_graph);

        this.implementClusteredColumnChart(pv_prop);
        this.implementLolipopChart(carbon_saving_prop);
        this.implementAnimatedBulletAtTheEndOfSheSeriesChart(cuf_prop);
        this.implementCurvedColumnsChart(yeild_prop);
        this.implementDateBasedDataChart(performance_ratio_prop);
        this.implementSmoothedLineChart(plant_load_factor_prop);

      } else {
        this.toastr.error("data is not availabe");
      }
    })
  }

  getBuyandSale() {
    let body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }
    this.dashboardService.httpPost(body).subscribe((res: any) => {

      let config = res.data?.config[0];
      let data = JSON.parse(config.cost_by_month);

      if (data[this.month.toLowerCase()][this.tabular_date_from.slice(0, 4)]) {

        this.buy_from_grid = data[this.month.toLowerCase()][this.tabular_date_from.slice(0, 4)]['per_kwh_unit_rate(rs)'];
        this.sale_to_grid = data[this.month.toLowerCase()][this.tabular_date_from.slice(0, 4)]['selling_rate(rs)'];
        this.gs_unit_rate = data[this.month.toLowerCase()][this.tabular_date_from.slice(0, 4)]['genset_unit_rate_rs'];

      } else {

        this.insertCost();

      }
    })
  }

  insertCost() {
    this.action = 'insertCost';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      this.buy_from_grid = res.data[this.month.toLowerCase()][this.tabular_date_from.slice(0, 4)]['per_kwh_unit_rate(rs)'];
      this.sale_to_grid = res.data[this.month.toLowerCase()][this.tabular_date_from.slice(0, 4)]['selling_rate(rs)'];
      this.gs_unit_rate = res.data[this.month.toLowerCase()][this.tabular_date_from.slice(0, 4)]['genset_unit_rate_rs'];

    })
  }

  getTableData() {

    let tabular_billing_and_income_data = []; let tabular_saving_data = []; let pv_yeild_energy = []; let consumption = [];
    let energy_balance = []; let solar_performance = []; this.solar_irradiance = 0.00; let solar = 0.00; this.action = 'getEnergyDailyData';
    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      let ke_first = []; let ke_last = []; let pv_first = []; let pv_last = []; let gs_first = []; let gs_last = [];
      let ke_data = []; let pv_data = []; let gs_data = [];

      for (let i = 0; i < this.device_ids.length; i++) {
        for (let k = 0; k < this.ke_devices.length; k++) {
          if (this.ke_devices[k] == res.data.last['device_' + (i + 1)][1].device_id) {
            ke_first.push(res.data.first['device_' + (i + 1)]);
            ke_last.push(res.data.last['device_' + (i + 1)]);
          }
        }

        for (let p = 0; p < this.pv_devices.length; p++) {
          if (this.pv_devices[p] == res.data.last['device_' + (i + 1)][1].device_id) {
            pv_first.push(res.data.first['device_' + (i + 1)]);
            pv_last.push(res.data.last['device_' + (i + 1)]);
          }
        }
        if (this.gs_devices[0]) {
          for (let g = 0; g < this.gs_devices.length; g++) {
            if (this.gs_devices[g] == res.data.last['device_' + (i + 1)][1].device_id) {
              gs_first.push(res.data.first['device_' + (i + 1)]);
              gs_last.push(res.data.last['device_' + (i + 1)]);
            }
          }
        }
      }

      for (let i = 1; i < pv_last[0].length; i++) {
        let pv_total_wh_diff = 0; let pv_total_wh_diff_sum = 0;
        let gs_total_wh_diff = 0; let gs_total_wh_diff_sum = 0;
        let ke_total_wh_diff_imp = 0; let ke_total_wh_diff_imp_sum = 0;
        let ke_total_wh_diff_exp = 0; let ke_total_wh_diff_exp_sum = 0;

        for (let j = 0; j < this.pv_devices.length; j++) {

          pv_total_wh_diff = (pv_first[j][i].total_wh < pv_last[j][i].total_wh && pv_first[j][i].total_wh != 0) ? pv_last[j][i].total_wh - pv_first[j][i].total_wh : 0;

          pv_total_wh_diff_sum += pv_total_wh_diff;

          if (j == (this.pv_devices.length - 1)) {
            pv_data.push({
              key_as_string: pv_last[j][i]['@timestamp'],
              diff_wh: pv_total_wh_diff_sum
            })
          }
        }

        if (this.gs_devices[0]) {

          for (let g = 0; g < this.gs_devices.length; g++) {

            gs_total_wh_diff = (gs_first[g][i].total_wh < gs_last[g][i].total_wh && gs_first[g][i].total_wh != 0) ? gs_last[g][i].total_wh - gs_first[g][i].total_wh : 0;

            gs_total_wh_diff_sum += gs_total_wh_diff;

            if (g == (this.gs_devices.length - 1)) {
              gs_data.push({
                key_as_string: gs_last[g][i]['@timestamp'],
                diff_wh: gs_total_wh_diff_sum
              })
            }
          }
        }

        for (let k = 0; k < this.ke_devices.length; k++) {

          ke_total_wh_diff_imp = (ke_first[k][i].total_wh < ke_last[k][i].total_wh && ke_first[k][i].total_wh != 0) ? ke_last[k][i].total_wh - ke_first[k][i].total_wh : 0;
          ke_total_wh_diff_exp = (ke_first[k][i].total_wh_exp < ke_last[k][i].total_wh_exp && ke_first[k][i].total_wh_exp != 0) ? ke_last[k][i].total_wh_exp - ke_first[k][i].total_wh_exp : 0;

          ke_total_wh_diff_imp_sum += ke_total_wh_diff_imp;
          ke_total_wh_diff_exp_sum += ke_total_wh_diff_exp;

          if (k == (this.ke_devices.length - 1)) {
            ke_data.push({
              key_as_string: ke_last[0][i]['@timestamp'],
              diff_imp: ke_total_wh_diff_imp_sum,
              diff_exp: ke_total_wh_diff_exp_sum
            });
          }
        }
      }

      if (ke_first[0]) {
        solar = (ke_first[0][1].solar_irradiance) ? ke_first[0][1].solar_irradiance : this.solar_insolation;
      } else solar = this.solar_insolation;

      this.show_gs_power = (this.gs_devices[0]) ? true : false;
      let length = Math.max(ke_data.length, pv_data.length, gs_data.length)

      // if (ke_data[0].diff_imp) {

      for (let i = 0; i < length; i++) {

        let date = res.data.last.device_1[i + 1]['@timestamp'];
        let import_from_grid = (ke_data[i]) ? ke_data[i].diff_imp : 0;
        let export_to_grid = (ke_data[i]) ? ke_data[i].diff_exp : 0;
        let pv_total_wh = (pv_data[i]) ? pv_data[i].diff_wh : 0;
        let gs_total_wh = (gs_data[i]) ? gs_data[i].diff_wh : 0;

        tabular_billing_and_income_data.push({
          date: new Date(date),
          import_from_grid: import_from_grid,
          buy_from_grid: import_from_grid * this.buy_from_grid,
          export_to_grid: export_to_grid,
          sale_to_grid: export_to_grid * this.sale_to_grid,
          balance: (import_from_grid * this.buy_from_grid) - (export_to_grid * this.sale_to_grid)
        });

        tabular_saving_data.push({
          date: new Date(date),
          export_to_grid: export_to_grid,
          sale_to_grid: export_to_grid * this.sale_to_grid,
          pv_self_use: pv_total_wh - export_to_grid,
          saving: (pv_total_wh - export_to_grid) * this.buy_from_grid,
          total_saving: (export_to_grid * this.sale_to_grid) + ((pv_total_wh - export_to_grid) * this.buy_from_grid),
        });

        pv_yeild_energy.push({
          date: new Date(date),
          export_to_grid: export_to_grid,
          pv_self_use: pv_total_wh - export_to_grid,
          pv_yeild_energy: pv_total_wh
        });

        consumption.push({
          date: new Date(date),
          import_from_grid: import_from_grid,
          pv_self_use: pv_total_wh - export_to_grid,
          gs: (this.gs_devices[0]) ? gs_total_wh : 0,
          full_load: parseInt(this.full_load)
        });

        energy_balance.push({
          date: new Date(date),
          import_from_grid: import_from_grid,
          export_to_grid: export_to_grid,
        });

        solar_performance.push({
          date: new Date(date),
          installed_capicity: this.installed_capicity,
          predictic_generation: this.predictic_generation,
          pv_generation: pv_yeild_energy[i].pv_yeild_energy,
        });

        consumption[i].consumption = consumption[i].import_from_grid + consumption[i].pv_self_use + consumption[i].gs;
        consumption[i].saving = consumption[i].full_load - consumption[i].consumption;
        energy_balance[i].energy_balance = energy_balance[i].import_from_grid - energy_balance[i].export_to_grid;
        solar_performance[i].carbon_saving = solar_performance[i].pv_generation * this.carbon_factor;
        solar_performance[i].differnce = solar_performance[i].pv_generation - solar_performance[i].predictic_generation;
        solar_performance[i].cuf = (solar_performance[i].pv_generation / (solar_performance[i].installed_capicity * 10)) * 100;
        solar_performance[i].yeild = solar_performance[i].pv_generation / solar_performance[i].installed_capicity;
        solar_performance[i].actual_generation = (solar_performance[i].pv_generation / solar_performance[i].predictic_generation) * 100;
        solar_performance[i].performance_ratio = (solar_performance[i].yeild * 100) / this.solar_insolation;
        solar_performance[i].plant_loading_factor = (solar_performance[i].pv_generation / (this.installed_capicity * 24)) * 100;

        this.tabular_import_sum = tabular_billing_and_income_data.map(a => a.import_from_grid).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_buy_sum = tabular_billing_and_income_data.map(a => a.buy_from_grid).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_exprot_sum = tabular_billing_and_income_data.map(a => a.export_to_grid).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_sale_sum = tabular_billing_and_income_data.map(a => a.sale_to_grid).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_pv_sum = tabular_saving_data.map(a => a.pv_self_use).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_saving_sum = tabular_saving_data.map(a => a.saving).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_total_saving_sum = tabular_saving_data.map(a => a.total_saving).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_balance_sum = tabular_billing_and_income_data.map(a => a.balance).reduce(function (a, b) {
          return a + b;
        });
        this.pv_yeild_use_sum = pv_yeild_energy.map(a => a.pv_yeild_energy).reduce(function (a, b) {
          return a + b;
        });
        this.tabular_gs_sum = consumption.map(a => a.gs).reduce(function (a, b) {
          return a + b;
        });
        this.consumption_sum = consumption.map(a => a.consumption).reduce(function (a, b) {
          return a + b;
        });
        this.energy_balance_sum = energy_balance.map(a => a.energy_balance).reduce(function (a, b) {
          return a + b;
        });
        this.carbon_saving_sum = solar_performance.map(a => a.carbon_saving).reduce(function (a, b) {
          return a + b;
        });
        this.difference_sum = solar_performance.map(a => a.differnce).reduce(function (a, b) {
          return a + b;
        });
        this.cuf_avg = solar_performance.map(a => a.cuf).reduce(function (a, b) {
          return a + b;
        });
        this.yeild_avg = solar_performance.map(a => a.yeild).reduce(function (a, b) {
          return a + b;
        });
        this.actual_generation_avg = solar_performance.map(a => a.actual_generation).reduce(function (a, b) {
          return a + b;
        });
        this.performance_ratio_avg = solar_performance.map(a => a.performance_ratio).reduce(function (a, b) {
          return a + b;
        });
        this.plant_loading_factor_avg = solar_performance.map(a => a.plant_loading_factor).reduce(function (a, b) {
          return a + b;
        });
      }

      this.tabular_billing_and_income_data = tabular_billing_and_income_data; this.tabular_saving_data = tabular_saving_data;
      this.pv_yeild_energy = pv_yeild_energy; this.consumption = consumption; this.energy_balance = energy_balance;
      this.solar_performance = solar_performance;

      this.predictic_generation_avg = this.predictic_generation * this.solar_performance.length;
      this.cuf_avg = this.cuf_avg / this.solar_performance.length;
      this.yeild_avg = this.yeild_avg / this.solar_performance.length;
      this.actual_generation_avg = this.actual_generation_avg / this.solar_performance.length;
      this.performance_ratio_avg = this.performance_ratio_avg / this.solar_performance.length;
      this.plant_loading_factor_avg = this.plant_loading_factor_avg / this.solar_performance.length;
      this.export_rate = this.tabular_exprot_sum * this.sale_to_grid;
      this.pv_rate = this.tabular_pv_sum * this.buy_from_grid;
      this.import_rate = this.tabular_import_sum * this.buy_from_grid;
      this.gs_rate = this.tabular_gs_sum * this.gs_unit_rate;
      this.pv_yeild_rate = this.export_rate + this.pv_rate;
      this.consumption_rate = this.import_rate + this.pv_rate + this.gs_rate;
      this.energy_balance_rate = this.import_rate - this.export_rate;

      // } else {
      //   this.tabular_billing_and_income_data = [];
      //   this.tabular_saving_data = [];
      //   this.pv_yeild_energy = [];
      //   this.tabular_import_sum = 0.00;
      //   this.tabular_buy_sum = 0.00;
      //   this.tabular_exprot_sum = 0.00;
      //   this.tabular_sale_sum = 0.00;
      //   this.tabular_pv_sum = 0.00;
      //   this.tabular_saving_sum = 0.00;
      //   this.tabular_total_saving_sum = 0.00;
      //   this.tabular_balance_sum = 0.00;
      //   this.pv_yeild_use_sum = 0.00;
      //   this.consumption_sum = 0.00;
      //   this.carbon_saving_sum = 0.00;
      //   this.difference_sum = 0.00;
      //   this.predictic_generation_avg = 0.00;
      //   this.cuf_avg = 0.00;
      //   this.yeild_avg = 0.00;
      //   this.actual_generation_avg = 0.00;
      //   this.performance_ratio_avg = 0.00;
      //   this.plant_loading_factor_avg = 0.00;
      //   this.toastr.error("data is not availabe");
      // }
    })
  }


  getSavingPvKNXTableData() {

    let saving_pv_knx = []; this.saving_pv_knx = [];
    let body = {
      'action': 'getEnergyDailyShopTimingData',
      'user_id': [localStorage.getItem('user')],
      'device_id': this.device_ids,
      'range': {
        from: this.tabular_date_from,
        to: this.tabular_date_to
      },
      'parameters': ['total_wh_exp', 'total_wh', '@timestamp', 'device_id'],
      'sh_timing': this.sh_timing
    }

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      let ke_first = []; let ke_last = []; let pv_first = []; let pv_last = []; let gs_first = []; let gs_last = [];
      let ke_data = []; let pv_data = []; let gs_data = [];

      for (let i = 0; i < this.device_ids.length; i++) {
        for (let k = 0; k < this.ke_devices.length; k++) {
          if (this.ke_devices[k] == res.data.last['device_' + (i + 1)][0].device_id) {
            ke_first.push(res.data.first['device_' + (i + 1)]);
            ke_last.push(res.data.last['device_' + (i + 1)]);
          }
        }

        for (let p = 0; p < this.pv_devices.length; p++) {
          if (this.pv_devices[p] == res.data.last['device_' + (i + 1)][0].device_id) {
            pv_first.push(res.data.first['device_' + (i + 1)]);
            pv_last.push(res.data.last['device_' + (i + 1)]);
          }
        }
        if (this.gs_devices[0]) {
          for (let g = 0; g < this.gs_devices.length; g++) {
            if (this.gs_devices[g] == res.data.last['device_' + (i + 1)][0].device_id) {
              gs_first.push(res.data.first['device_' + (i + 1)]);
              gs_last.push(res.data.last['device_' + (i + 1)]);
            }
          }
        }
      }

      for (let i = 0; i < pv_last[0].length; i++) {
        let pv_total_wh_diff = 0; let pv_total_wh_diff_sum = 0;
        let gs_total_wh_diff = 0; let gs_total_wh_diff_sum = 0;
        let ke_total_wh_diff_imp = 0; let ke_total_wh_diff_imp_sum = 0;
        let ke_total_wh_diff_exp = 0; let ke_total_wh_diff_exp_sum = 0;

        for (let j = 0; j < this.pv_devices.length; j++) {

          pv_total_wh_diff = (pv_first[j][i].total_wh < pv_last[j][i].total_wh && pv_first[j][i].total_wh != 0) ? pv_last[j][i].total_wh - pv_first[j][i].total_wh : 0;

          pv_total_wh_diff_sum += pv_total_wh_diff;

          if (j == (this.pv_devices.length - 1)) {
            pv_data.push({
              key_as_string: pv_last[j][i]['@timestamp'],
              diff_wh: pv_total_wh_diff_sum
            })
          }
        }

        if (this.gs_devices[0]) {

          for (let g = 0; g < this.gs_devices.length; g++) {

            gs_total_wh_diff = (gs_first[g][i].total_wh < gs_last[g][i].total_wh && gs_first[g][i].total_wh != 0) ? gs_last[g][i].total_wh - gs_first[g][i].total_wh : 0;

            gs_total_wh_diff_sum += gs_total_wh_diff;

            if (g == (this.gs_devices.length - 1)) {
              gs_data.push({
                key_as_string: gs_last[g][i]['@timestamp'],
                diff_wh: gs_total_wh_diff_sum
              })
            }
          }
        }

        for (let k = 0; k < this.ke_devices.length; k++) {

          ke_total_wh_diff_imp = (ke_first[k][i].total_wh < ke_last[k][i].total_wh && ke_first[k][i].total_wh != 0) ? ke_last[k][i].total_wh - ke_first[k][i].total_wh : 0;
          ke_total_wh_diff_exp = (ke_first[k][i].total_wh_exp < ke_last[k][i].total_wh_exp && ke_first[k][i].total_wh_exp != 0) ? ke_last[k][i].total_wh_exp - ke_first[k][i].total_wh_exp : 0;

          ke_total_wh_diff_imp_sum += ke_total_wh_diff_imp;
          ke_total_wh_diff_exp_sum += ke_total_wh_diff_exp;

          if (k == (this.ke_devices.length - 1)) {
            ke_data.push({
              key_as_string: ke_last[0][i]['@timestamp'],
              diff_imp: ke_total_wh_diff_imp_sum,
              diff_exp: ke_total_wh_diff_exp_sum
            });
          }
        }
      }



      this.show_gs_power = (this.gs_devices[0]) ? true : false;
      let length = Math.max(ke_data.length, pv_data.length, gs_data.length)

      for (let i = 0; i < length; i++) {

        let date = res.data.last.device_1[i]['@timestamp'];
        let import_from_grid = (ke_data[i]) ? ke_data[i].diff_imp : 0;
        let export_to_grid = (ke_data[i]) ? ke_data[i].diff_exp : 0;
        let pv_total_wh = (pv_data[i]) ? pv_data[i].diff_wh : 0;
        let gs_total_wh = (gs_data[i]) ? gs_data[i].diff_wh : 0;

        saving_pv_knx.push({
          date: new Date(date),
          import_from_grid: import_from_grid,
          pv_self_use: pv_total_wh - export_to_grid,
          gs: (this.gs_devices[0]) ? gs_total_wh : 0,
          full_load: parseInt(this.full_load)
        });


        saving_pv_knx[i].consumption = saving_pv_knx[i].import_from_grid + saving_pv_knx[i].pv_self_use + saving_pv_knx[i].gs;
        saving_pv_knx[i].saving = saving_pv_knx[i].full_load - saving_pv_knx[i].consumption;

        this.saving_pv_knx_import_sum = saving_pv_knx.map(a => a.import_from_grid).reduce(function (a, b) {
          return a + b;
        });
        this.saving_pv_knx_pv_sum = saving_pv_knx.map(a => a.pv_self_use).reduce(function (a, b) {
          return a + b;
        });
        this.saving_pv_knx_gs_sum = saving_pv_knx.map(a => a.gs).reduce(function (a, b) {
          return a + b;
        });
        this.saving_pv_knx_consumption_sum = saving_pv_knx.map(a => a.consumption).reduce(function (a, b) {
          return a + b;
        });
        this.saving_pv_knx_full_load_sum = saving_pv_knx.map(a => a.full_load).reduce(function (a, b) {
          return a + b;
        });
        this.saving_pv_knx_saving_sum = saving_pv_knx.map(a => a.saving).reduce(function (a, b) {
          return a + b;
        });
      }

      this.saving_pv_knx = saving_pv_knx;
      
      this.consumption_rate = this.import_rate + this.pv_rate + this.gs_rate;

    })
  }

  getGraphInterface(e) {

    if (e == 'pv_yeild_energy_graph') {

      let pv_yeild_energy_graph = [];
      this.tab_pv_yeild_energy_graph = (this.pv_yeild_energy.length > 0) ? true : false;

      pv_yeild_energy_graph.push({
        category: "",
        negative1: this.tabular_exprot_sum,
        positive1: this.tabular_pv_sum
      });

      let pv_yeild_energy_prop = this.getGraphProp('pv_yeild_energy', pv_yeild_energy_graph);
      this.implementDivergentStackedBars(pv_yeild_energy_prop, 'Pv Yeild Energy', 'EXPORT TO GRID ( KWH )', 'PV-SELF USE ( KWH )', '');

    } else if (e == 'consumption_graph') {

      let consumption_graph = [];
      this.tab_consumption_graph = (this.consumption.length > 0) ? true : false;

      consumption_graph.push({
        category: "",
        negative1: this.tabular_import_sum,
        positive1: this.tabular_pv_sum,
        gs: this.tabular_gs_sum
      });

      let consumption_prop = this.getGraphProp('consumption', consumption_graph);
      this.implementDivergentStackedBars(consumption_prop, 'Consumption', 'IMPORT FROM GRID ( KWH )', 'PV-SELF USE ( KWH )', 'GENSET ( KWH )');

    } else if (e == 'energy_balance_graph') {

      let energy_balance_graph = [];
      this.tab_energy_balance_graph = (this.energy_balance.length > 0) ? true : false;

      energy_balance_graph.push({
        category: "",
        negative1: this.tabular_import_sum,
        positive1: this.tabular_exprot_sum
      })

      let energy_balance_prop = this.getGraphProp('energy_balance', energy_balance_graph);
      this.implementDivergentStackedBars(energy_balance_prop, 'Energy Balance', 'IMPORT FROM GRID ( KWH )', 'EXPORT TO GRID ( KWH )', '');

    }

  }

  calculateCost() {
    this.getTableData();
  }

  getBody() {

    let body = {
      "action": this.action
    }

    if (this.action == 'netMeteringPowerTrend') {

      body['account_id'] = localStorage.getItem('account'),
        body['user_id'] = localStorage.getItem('user'),
        body['date'] = this.date
      body['site'] = this.nm_site

    }

    if (this.action == 'getChartDataByAccount') {

      body['user_id'] = [localStorage.getItem('user')],
        body['device_id'] = this.device_ids,
        body['date'] = this.date,
        body['fixed_interval'] = this.interval,
        body['parameters'] = ['total_wh_exp', 'total_wh', 'total_w']

      if (this.interval == '30m') {
        delete body['date']
        body['range'] = {
          from: this.tabular_date_from,
          to: this.tabular_date_to
        }
      }

    }

    if (this.action == 'getEnergyDailyData' || this.action == 'getEnergyHourlyData') {

      body['user_id'] = [localStorage.getItem('user')],
        body['device_id'] = this.device_ids

      if (this.action == 'getEnergyDailyData') {

        body['range'] = {
          from: this.tabular_date_from,
          to: this.tabular_date_to
        }
      }

      if (this.action == 'getEnergyHourlyData') {

        body['range'] = {
          from: this.pv_date,
          to: this.pv_date
        }
      }

      body['parameters'] = ['total_wh_exp', 'total_wh', '@timestamp', 'device_id'];
    }

    if (this.action == 'getForecast') {

      body['meta_key'] = "config",
        body['user_id'] = localStorage.getItem('user'),
        body['account_id'] = [localStorage.getItem('account')]
    }

    if (this.action == 'getCurrentStatePower' || this.action == 'getCurrentStateTable') {

      body['user_id'] = localStorage.getItem('user'),
        body['account_id'] = localStorage.getItem('account')
      body['site'] = this.nm_site
    }

    if (this.action == 'insertCost') {
      delete body['user_id']; delete body['device_id']; delete body['range']; delete body['parameters'];
      body['account_id'] = localStorage.getItem('account');
      body['year'] = this.tabular_date_from.slice(0, 4);

    }

    return body;

  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      unit: {},
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 5
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    }
    if (param == 'power_trend') {
      data.element = 'chartPvPower'
      data.title = 'Power (Kw)'
    } else if (param == 'pv') {
      data.element = 'chartPv'
    } else if (param == 'weather') {
      data.element = 'chartWeather'
    } else if (param == 'pv_yeild_energy') {
      data.element = 'chartPvYeild'
    } else if (param == 'consumption') {
      data.element = 'chartConsumption'
    } else if (param == 'energy_balance') {
      data.element = 'chartEnergyBalance'
    } else if (param == 'pv_generation') {
      data.element = 'chartPvGeneration'
    } else if (param == 'carbon_saving') {
      data.element = 'chartCarbonSaving'
    } else if (param == 'cuf') {
      data.element = 'chartCUF'
    } else if (param == 'yeild') {
      data.element = 'chartYeild'
    } else if (param == 'performance_ratio') {
      data.element = 'chartPerformanceRatio'
    } else if (param == 'plant_loading_factor') {
      data.element = 'chartPlantLoadingFactor'
    }
    return data;
  }

  implementWeatherChart(data) {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart
    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      "timeUnit": "minute",
      "count": 1
    };
    dateAxis.tooltipDateFormat = "HH:mm, d MMMM";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "temp";
    series.tooltipText = "Temp: [bold]{valueY}℃[/]";
    series.fillOpacity = 0.3;


    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;

    dateAxis.start = 0.8;
    dateAxis.keepSelection = true;

  }

  implementLineChart(data) {

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.adapter.remove("getTime");

    // Create series
    this.createAxisAndSeries("load_power", "date", this.power_props.unit.unit_3, false, am4core.color("red"), '');
    if (this.gs_devices[0]) this.createAxisAndSeries("gs_power", "date", this.power_props.unit.unit_4, false, am4core.color("#D4AC0D"), '');
    this.createAxisAndSeries("ke_power", "date", this.power_props.unit.unit_2, false, am4core.color("blue"), '');
    this.createAxisAndSeries("pv_power", "date", this.power_props.unit.unit_1, false, am4core.color("green"), data.title);

    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
  }

  createAxisAndSeries(field, date, name, opposite, color, title) {

    this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    this.valueAxis.title.text = title;
    let series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;
    series.showOnInit = true;
    series.stroke = series.fill = color;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    this.valueAxis.renderer.line.strokeWidth = 2;
    this.valueAxis.renderer.line.stroke = series.stroke;
    this.valueAxis.renderer.labels.template.fill = series.stroke;
    this.valueAxis.renderer.opposite = opposite;

  }

  implementBarChart(data) {

    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.colors.step = 2;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    // dateAxis.adapter.remove("getTime");

    // Create series
    this.createBarAxisAndSeries("load_power", "date", this.power_props.unit.unit_3, false, am4core.color("red"), '');
    if (this.gs_devices[0]) this.createBarAxisAndSeries("gs_power", "date", this.power_props.unit.unit_4, false, am4core.color("#D4AC0D"), '');
    this.createBarAxisAndSeries("ke_power", "date", this.power_props.unit.unit_2, false, am4core.color("blue"), '');
    this.createBarAxisAndSeries("pv_power", "date", this.power_props.unit.unit_1, false, am4core.color("green"), data.title);

    // Add legend
    this.chart.legend = new am4charts.Legend();
    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
  }

  createBarAxisAndSeries(field, date, name, opposite, color, title) {


    this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

    this.valueAxis.title.text = title;

    let series = this.chart.series.push(new am4charts.ColumnSeries());

    series.dataFields.valueY = field;
    series.dataFields.dateX = date;
    series.strokeWidth = 2;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.stroke = series.fill = color;

    series.showOnInit = true;
    // Add scrollbar
    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    this.chart.scrollbarX = scrollbarX;

    // Add cursor
    this.valueAxis.renderer.line.strokeWidth = 2;
    this.valueAxis.renderer.line.stroke = series.stroke;
    this.valueAxis.renderer.labels.template.fill = series.stroke;
    this.valueAxis.renderer.opposite = opposite;
  }

  implementDivergentStackedBars(data, name, label_1, label_2, gs) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);

    let title = chart.titles.push(new am4core.Label());
    title.text = name;
    title.fontSize = 25;
    title.marginBottom = 15;

    // Add data
    chart.data = data.data;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.axisFills.template.disabled = false;
    categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minGridDistance = 50;
    valueAxis.renderer.ticks.template.length = 5;
    valueAxis.renderer.ticks.template.disabled = false;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.4;

    // Legend
    chart.legend = new am4charts.Legend();

    // Use only absolute numbers
    chart.numberFormatter.numberFormat = "#.#s";

    let positiveColor = am4core.color("green");
    let negativeColor = am4core.color("blue");
    let gsColor = am4core.color("#D4AC0D");

    function createSeries(field, name, color) {

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "category";
      series.stacked = true;
      series.name = name;
      series.stroke = color;
      series.fill = color;

      let label = series.bullets.push(new am4charts.LabelBullet);
      label.label.text = "{valueX}";
      label.label.fill = am4core.color("#fff");
      label.label.strokeWidth = 5;
      label.label.truncate = false;
      label.label.hideOversized = true;
      label.locationX = 0.5;
      return series;
    }

    createSeries("negative1", label_1, negativeColor);
    createSeries("positive1", label_2, positiveColor);
    if (this.gs_devices[0]) createSeries("gs", gs, gsColor);

  }

  implementStackColumn(data, name) {

    this.chart = am4core.create(data.element, am4charts.XYChart);
    this.chart.data = data.data;
    this.chart.hiddenState.properties.opacity = 0;
    this.chart.colors.step = 2;
    this.chart.padding(30, 30, 10, 30);
    this.chart.legend = new am4charts.Legend();

    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;


    let series1 = this.chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);

    if (name == 'Pv-Yeild Energy' || name == null) {

      series1.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series1.name = "EXPORT TO GRID";
      series1.dataFields.categoryX = "date";
      series1.dataFields.valueY = "export_to_grid";

    }
    if (name == 'Consumption') {
      series1.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series1.name = "IMPORT FROM GRID";
      series1.dataFields.categoryX = "date";
      series1.dataFields.valueY = "import_from_grid";
    }

    if (name == 'Energy Balance') {
      series1.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series1.name = "EXPORT TO GRID";
      series1.dataFields.categoryX = "date";
      series1.dataFields.valueY = "export_to_grid";
    }

    series1.dataFields.valueYShow = "totalPercent";
    series1.dataItems.template.locations.categoryX = 0.5;
    series1.stacked = true;
    series1.tooltip.pointerOrientation = "vertical";

    let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    bullet1.label.fill = am4core.color("#ffffff");
    bullet1.locationY = 0.5;

    let series2 = this.chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(80);

    if (name == 'Energy Balance') {
      series2.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series2.name = "IMPORT FROM GRID";
      series2.dataFields.categoryX = "date";
      series2.dataFields.valueY = "import_from_grid";
    } else {
      series2.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series2.name = "PV-SELF USE";
      series2.dataFields.categoryX = "date";
      series2.dataFields.valueY = "pv_self_use";
    }

    series2.dataFields.valueYShow = "totalPercent";
    series2.dataItems.template.locations.categoryX = 0.5;
    series2.stacked = true;
    series2.tooltip.pointerOrientation = "vertical";

    let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    bullet2.locationY = 0.5;
    bullet2.label.fill = am4core.color("#ffffff");

    if (name == 'Consumption' && this.gs_devices[0]) {

      let series3 = this.chart.series.push(new am4charts.ColumnSeries());
      series3.columns.template.width = am4core.percent(80);
      series3.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series3.name = "GENSET";
      series3.dataFields.categoryX = "date";
      series3.dataFields.valueY = "genset";

      series3.dataFields.valueYShow = "totalPercent";
      series3.dataItems.template.locations.categoryX = 0.5;
      series3.stacked = true;
      series3.tooltip.pointerOrientation = "vertical";
      series3.stroke = am4core.color("#D4AC0D");
      series3.fill = am4core.color("#D4AC0D");

      let bullet3 = series3.bullets.push(new am4charts.LabelBullet());
      bullet3.interactionsEnabled = false;
      bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
      bullet3.locationY = 0.5;
      bullet3.label.fill = am4core.color("#ffffff");
    }
  }

  implementClusteredColumnChart(data) {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chart = am4core.create(data.element, am4charts.XYChart)
    chart.colors.step = 2;
    chart.legend = new am4charts.Legend()
    chart.legend.position = 'top'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'date'
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.numberFormatter = new am4core.NumberFormatter();
    yAxis.numberFormatter.numberFormat = "#,###' Kwh'";
    function createSeries(value, name) {
      let series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'date'
      series.name = name
      series.tooltipText = "{valueY.value} Kwh";
      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      let bullet = series.bullets.push(new am4charts.LabelBullet())
      bullet.interactionsEnabled = false
      bullet.dy = 30;
      bullet.label.text = '{valueY} Kwh'
      bullet.label.fill = am4core.color('#ffffff')

      return series;
    }

    chart.data = data.data;
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarX = new am4core.Scrollbar();
    createSeries('predictic_generation', 'predictic Generation');
    createSeries('pv_generation', 'Pv Generation');

    function arrangeColumns() {

      let series = chart.series.getIndex(0);

      let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            }
            else {
              series.dummyData = chart.series.indexOf(series);
            }
          })
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta

            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          })
        }
      }
    }

  }

  implementLolipopChart(data) {

    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.data = data.data;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.minGridDistance = 15;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.renderer.labels.template.horizontalCenter = "left";
    categoryAxis.renderer.labels.template.location = 0.5;

    categoryAxis.renderer.labels.template.adapter.add("dx", function (dx, target) {
      return -target.maxRight / 2;
    })

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#,###' Kg'";

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "date";
    series.dataFields.valueY = "carbon_saving";
    series.tooltipText = "{valueY.value} Kg";
    series.sequencedInterpolation = true;
    series.fillOpacity = 0;
    series.strokeOpacity = 1;
    series.columns.template.width = 0.01;
    series.tooltip.pointerOrientation = "horizontal";

    let bullet = series.bullets.create(am4charts.CircleBullet);

    chart.cursor = new am4charts.XYCursor();

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();

  }

  implementAnimatedBulletAtTheEndOfSheSeriesChart(data) {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chart = am4core.create(data.element, am4charts.XYChart);

    // Add data
    chart.data = data.data;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#,###'%'";

    // Create series
    let lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "cuf";
    lineSeries.dataFields.dateX = "date";
    // lineSeries.tooltipText = "{valueY.value}";
    lineSeries.name = "Sales";
    lineSeries.strokeWidth = 3;
    lineSeries.strokeDasharray = "5,4";

    // Add simple bullet
    let bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.disabled = false;
    // let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = "{valueY}%";
    // bullet.propertyFields.disabled = "disabled";

    let secondCircle = bullet.createChild(am4core.Circle);
    secondCircle.radius = 6;
    secondCircle.fill = chart.colors.getIndex(8);


    bullet.events.on("inited", function (event) {
      animateBullet(event.target.circle);
    })


    function animateBullet(bullet) {
      let animation = bullet.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
      animation.events.on("animationended", function (event) {
        animateBullet(event.target.object);
      })
    }
  }

  implementCurvedColumnsChart(data) {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    chart.data = data.data;


    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.minGridDistance = 40;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    let series = chart.series.push(new am4charts.CurvedColumnSeries());
    series.dataFields.categoryX = "date";
    series.dataFields.valueY = "yeild";
    series.tooltipText = "{valueY.value}"
    series.columns.template.strokeOpacity = 0;

    series.columns.template.fillOpacity = 0.75;

    let hoverState = series.columns.template.states.create("hover");
    hoverState.properties.fillOpacity = 1;
    hoverState.properties.tension = 0.4;

    chart.cursor = new am4charts.XYCursor();

    // Add distinctive colors for each column using adapter
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    chart.scrollbarX = new am4core.Scrollbar();

  }

  implementDateBasedDataChart(data) {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create(data.element, am4charts.XYChart);

    // Add data
    chart.data = data.data;

    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "performance_ratio";
    series.dataFields.dateX = "date";
    series.tooltipText = "{valueY.value}";
    series.strokeWidth = 2;
    series.minBulletDistance = 15;

    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";

    // Make bullets grow on hover
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color("#fff");

    let bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;

    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;

    // Create vertical scrollbar and place it before the value axis
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.parent = chart.leftAxesContainer;
    chart.scrollbarY.toBack();

    // Create a horizontal scrollbar with previe and place it underneath the date axis
    // chart.scrollbarX = new am4charts.XYChartScrollbar();
    // chart.scrollbarX.series.push(series);

    // Add scrollbar
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    dateAxis.start = 0.79;
    dateAxis.keepSelection = true;
  }

  implementSmoothedLineChart(data) {

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    // Add data
    chart.data = data.data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0.5;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.baseValue = 0;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#,###'%'";
    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "plant_loading_factor";
    series.dataFields.categoryX = "date";
    series.strokeWidth = 2;
    series.tensionX = 0.77;

    // bullet is added because we add tooltip to a bullet for it to change color
    let bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = "{valueY}%";

    let range = valueAxis.createSeriesRange(series);
    range.value = 0;
    range.endValue = -1000;
    range.contents.stroke = am4core.color("#FF0000");
    range.contents.fill = range.contents.stroke;

    // Add scrollbar
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    chart.cursor = new am4charts.XYCursor();
  }

  tabLineChart(element) {

    if (element == 'power_trend' && this.pv_power_chart_active != 'line') {
      this.implementLineChart(this.getGraphProp('power_trend', this.power_data)); this.pv_power_chart_active = 'line';
    }

  }

  tabBarChart(element) {

    if (element == 'power_trend' && this.pv_power_chart_active != 'bar') {
      this.implementBarChart(this.getGraphProp('power_trend', this.power_data)); this.pv_power_chart_active = 'bar';
    }

  }

  exportAsCSV() {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsCsvFormat(data);
  }

  getDataByParam() {

    if (this.data_param == 'power_trend') return this.power_data;
    else if (this.data_param == 'pv') return this.energy_data;
    else if (this.data_param == 'tab_billing_and_income') return this.tabular_billing_and_income_data;
    else if (this.data_param == 'tab_saving') return this.tabular_saving_data;
    else if (this.data_param == 'tab_pv_yeild_energy') return this.pv_yeild_energy;
    else if (this.data_param == 'tab_consumption') return this.consumption;
    else if (this.data_param == 'tab_energy_balance') return this.energy_balance;
    else if (this.data_param == 'tab_saving_pv_knx') return this.saving_pv_knx;
    else if (this.data_param == 'tab_plant_performance_by_formula') return this.solar_performance;
  }

  exportAsPDF(e) {
    let data = [];
    if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}