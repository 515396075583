<ngb-tabset #t="ngbTabset" id="settings-panel" (tabChange)="tabChange($event)" #tabset>

  <ngb-tab id="tab-alarm" title="Alarm">
    <ng-template ngbTabContent>
      <div class="row mt-4">
        <div class="col-lg-12 col-xl-12 stretch-card">
          <div class="row flex-grow card-grid">

            <div class="col grid-margin stretch-card">
              <div class="card">

                <div style="overflow-x: auto;" class="card-body">

                  <!-- alarm setting -->

                  <!-- device hierarcy -->
                  <div class="br-1 float-left pr-2 position-relative">
                    <div class="tree-view float-left" style="max-width: 269px; min-width: 269px;">

                      <div class="tree-tabs">
                        <ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)" #tabset>

                          <ngb-tab *ngIf="tab_energy_status" id="tab-energy" title="Energy" tooltip="test">
                            <ng-template ngbTabContent>
                                <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy">
                                </zainergy-recursive-control-panel>
                            </ng-template>
                        </ngb-tab>
    
                        <ngb-tab *ngIf="tab_flow_status" id="tab-flow" title="Flow">
                            <ng-template ngbTabContent>
                                <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy">        
                                </zainergy-recursive-control-panel>
                            </ng-template>
                        </ngb-tab>
    
                        <ngb-tab *ngIf="tab_sensor_status" id="tab-sensor" title="Sensor">
                            <ng-template ngbTabContent>
                                <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy">                                 
                                </zainergy-recursive-control-panel>
                            </ng-template>
                        </ngb-tab>
    
                        <ngb-tab *ngIf="tab_level_status" id="tab-level" title="Level" tooltip="test">
                            <ng-template ngbTabContent>
                                <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy"></zainergy-recursive-control-panel>
                            </ng-template>
                        </ngb-tab>

                        </ngb-tabset>
                      </div>

                    </div>
                  </div>
                  <!-- end device hierarcy -->
                  <div class="overflow-auto">
                    <form class="card-body">

                    <!-- device location  -->
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <h4>{{ device_location }}</h4>
                          <hr>
                        </div>
                      </div>
                    </div>
                    <!-- end device location -->

                    <div class="row">

                      <!-- parameter -->
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Parameter</label>

                          <select [(ngModel)]="alarm_module.param" [ngModelOptions]="{standalone: true}"
                            (change)="onChangeAlarmParam()" name="Parameter">
                            <option value="" selected disabled>Select Parameter</option>
                            <option *ngFor="let param of device_params" [value]="param.parameter">{{ param.description
                              }}</option>
                          </select>

                        </div>
                      </div>
                      <!-- end parameter -->

                      <!-- Threshold Values -->
                      <div class="col-sm-4">
                        <div class="form-group mb-0">
                          <label for="">Threshold Values</label>
                        </div>

                        <div class="form-row">

                          <!-- min value -->
                          <div class="col">
                            <div class="form-group">
                              <input type="number" placeholder="Min Val" class="form-control" id="min-val"
                                autocomplete="off" [(ngModel)]="alarm_module.min" [ngModelOptions]="{standalone: true}"
                                value="{{alarm_value.min}}" (keyup)="setThresholdValues()">
                            </div>
                          </div>
                          <!-- end min value -->

                          <!-- max value -->
                          <div class="col">
                            <div class="form-group">
                              <input type="number" placeholder="Max Val" class="form-control" id="max-val"
                                autocomplete="off" [(ngModel)]="alarm_module.max" [ngModelOptions]="{standalone: true}"
                                value="{{alarm_value.max}}" (keyup)="setThresholdValues()">
                            </div>
                          </div>
                          <!-- end max value -->


                        </div>
                      </div>
                      <!-- End Threshold Values -->

                      <!-- email  -->
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="alarm-emails">Alarm Emails</label>
                          <input type="email" class="form-control" id="alarm-emails" placeholder="user@zainergy.com"
                            autocomplete="off" [(ngModel)]="alarm_module.email" [ngModelOptions]="{standalone: true}"
                            value="{{alarm_value.email}}">
                        </div>
                      </div>
                      <!-- end email -->
                  
                      <!-- alarm update button -->
                      <div class="col-sm-12">
                        <div class="form-group float-right mb-0">
                          <button type="submit" class="btn theme-primary-btn" (click)="updateAlarmSetting()">Update
                            Setting</button>
                        </div>
                      </div>
                      <!-- end alarm update button -->

                      <!-- alarm table  -->
                      <div class="col-sm-12 mt-4">
                        <div class="table-responsive">
                          <table class="text-nowrap table table-bordered">
                            <thead>
                              <tr>
                                <th style="text-align: center; width: auto;">parameter</th>
                                <th style="text-align: center; width: auto;">Min value</th>
                                <th style="text-align: center; width: auto;">Max value</th>
                                <th style="text-align: center; width: auto;">Alarm email</th>
                                <th style="text-align: center; width: auto;">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let alarm of alarm_table | slice: (page-1) * pageSize : page * pageSize">
                                <td style="text-align: center; width: auto;">{{ alarm.description }}</td>
                                <td style="text-align: center; width: auto;">{{ alarm.min }}</td>
                                <td style="text-align: center; width: auto;">{{ alarm.max }}</td>
                                <td style="text-align: center; width: auto;">{{ alarm.email }}</td>
                                <td style="text-align: center; width: auto;">
                                  <button class="bg-transparent border-0"
                                    (click)="openAlarmDeleteModal(alarm_delete_model, alarm)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round" class="feather feather-trash-2">
                                      <polyline points="3 6 5 6 21 6"></polyline>
                                      <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                      </path>
                                      <line x1="10" y1="11" x2="10" y2="17"></line>
                                      <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                  </button>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>

                        <!-- table pagination -->
                        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [pageSize]="pageSize"
                          [collectionSize]="alarm_table.length"></ngb-pagination>
                        <!-- end table pagination -->

                      </div>
                      <!-- end alarm table -->

                    </div>
                                          <!-- table pagination -->
                                          <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [pageSize]="pageSize"
                                          [collectionSize]="alarm_table.length"></ngb-pagination>
                                        <!-- end table pagination -->
                  </form>
                </div>
                  <!-- Clear modal -->
                  <ng-template #alarm_delete_model let-modal>

                    <div class="modal-header">

                      <h4 class="modal-title" style="text-align: center;">DELETE ALARM VALUE</h4>


                      <button type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>

                    </div>

                    <div class="modal-body">

                      <h5 class="text-danger" style="text-align: center;">Are you sure you want to delete?</h5>

                      <br>
                      <ul>
                        <li>Parameter: {{ alarm_param.desc }}</li>
                        <li>Min value: {{ alarm_param.min }}</li>
                        <li>Max value: {{ alarm_param.max }}</li>
                        <li>email: {{ alarm_param.email }}</li>
                        <li>Message: {{ alarm_param.msg }}</li>
                      </ul>

                    </div>

                    <div class="modal-footer">
                      <button type="button" class="btn btn-light"
                        (click)="modal.dismiss('cancel click')">Cancel</button>
                      <button type="button" class="btn btn-danger" (click)="deleteAlarmSetting()">Ok</button>
                    </div>

                  </ng-template>
                  <!-- End modal  -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- row -->
    </ng-template>
  </ngb-tab>

  <ngb-tab id="tab-cost" title="Cost" *ngIf="tab_cost_status">
    <ng-template ngbTabContent>

      <div class="row  mt-4">
        <div class="col-lg-12 col-xl-12 stretch-card" id="tab-table">
          <div class="row flex-grow">

            <div class="d-grid col grid-margin stretch-card">
              <div class="card">

                <div class="d-flex justify-content-between card-header align-items-center">

                  <h5 class="card-title mb-0 ">Cost Parameters</h5>

                  <div class="d-flex align-items-center justify-content-end">

                    <p>
                      <button class="btn pr-1" type="button" data-toggle="collapse"
                      data-target="#collapseExample" aria-expanded="false"
                      aria-controls="collapseExample">
                      <i class="feather-sliders"
                          style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                      </button>
                    </p>
                    <div class="dropdown ml-2">
                      <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                      </button>
                      <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                      <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Print"
                        (click)="data_param = 'table'; data_param_el = 'tab-table'; exportAsPDF($event)">
                        <i class="feather feather-printer icon-sm mr-2"></i>
                        <span class="">Print</span>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                        (click)="data_param = 'table'; exportAsCSV()">
                        <i class="feather feather-download icon-sm mr-2"></i>
                        <span class="">Export as CSV</span>
                      </a>
                      <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Pdf"
                        (click)="data_param = 'table'; data_param_el = 'tab-table'; exportAsPDF($event)">
                        <i class="feather feather-file-text icon-sm mr-2"></i>
                        <span class="">Export as PDF</span>
                      </a>
                    </div> -->
                    </div>
                  </div>

                </div>

                <div class="collapse px-2" id="collapseExample">
                  <div class="card-body overflow-auto">
                      <div class="w-max-content d-flex header-right-inner-left">

                        <div class="mb-0 mr-2 ml-1">
                          <h6 class="text-nowrap">Select Month:</h6>
                        </div>

                        <div class="mb-0 mr-4">
                          <select style="width: 150px;" [(ngModel)]="month" (change)="onChangeMonthYear()">
                            <option [ngValue]="null" [disabled]="true">Select Month</option>
                            <option *ngFor="let month of months">{{ month }}</option>
                          </select>
                        </div>

                        <div class="mb-0 mr-2">
                          <h6 class="text-nowrap">Select Year:</h6>
                        </div>
                        <div class="mb-0 mr-4">
                          <select style="width: 150px;" [(ngModel)]="year" (change)="onChangeMonthYear()">
                            <option [ngValue]="null" [disabled]="true">Select Year</option>
                            <option *ngFor="let year of years">{{ year}}</option>
                          </select>
                        </div>

                        <div *ngIf="fuel_rate_status" class="mb-0 mr-2">
                          <h6 class="text-nowrap">Fuel Rate:</h6>
                        </div>
                        <div *ngIf="fuel_rate_status" class="mb-0 text-center mr-4" style="width:5em;">
                          <input type="number" class="form-control mr-4 text-center" [(ngModel)]="fuel_rate"
                          [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="mb-0 mr-2">
                          <h6 class="text-nowrap">Late Charges (%):</h6>
                        </div>

                        <div class="mb-0 text-center mr-4" style="width:5em;">
                          <input type="number" class="form-control mr-4 text-center" [(ngModel)]="late_charges"
                            [ngModelOptions]="{standalone: true}">
                        </div>

                        
                        <div class="mb-0 mr-2">
                          <h6 class="text-nowrap">Grid Selling Rate (Rs):</h6>
                        </div>
                        <div class="mb-0 mr-4">
                          <input type="number" class="form-control text-center" style="width:7em;" [(ngModel)]="selling_rate"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                       
                        <div class="mb-0 mr-2">
                          <h6 class="text-nowrap">Misc Charges:</h6>
                        </div>

                        <div class="mb-0 mr-4" style="width:7em;">
                          <input type="number" class="form-control mr-4 text-center" [(ngModel)]="misc_charges"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                        

                        <div class="mb-0 mr-2 text-right">
                          <h6 class="text-nowrap">Service Charges:</h6>
                        </div>

                        <div class="mb-0 text-center mr-4" style="width:7em;">
                          <input type="number" class="form-control mr-4 text-center" [(ngModel)]="service_charges"
                            [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="mb-0 mr-2">
                          <h6 class="text-nowrap">Grid Selling Rate (Rs):</h6>
                        </div>
                        <div class="mb-0 mr-4">
                          <input type="number" class="form-control text-center" style="width:7em;" [(ngModel)]="selling_rate"
                            [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="mb-0 mr-2">
                          <h6 class="text-nowrap">Genset Unit Rate (Rs):</h6>
                        </div>
                        <div class="mb-0 mr-4 text-center mr-4">
                          <input type="number" class="form-control text-center" style="width:7em;" [(ngModel)]="genset_unit_rate_rs"
                            [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="mb-0 mr-2">
                          <h6 class="text-nowrap">Per Kwh Unit Rate (Rs):</h6>
                        </div>

                        <div class="mb-0 mr-4">
                          <input type="number" style="width:7em;" class="form-control text-center" [(ngModel)]="cost"
                            [ngModelOptions]="{standalone: true}">
                        </div>

                        <div class="px-2 py-4 mb-0">
                          <button type="submit" class="btn theme-primary-btn"
                            (click)="updatePerKwhUnitRate()">Update</button>
                        </div>
                      </div>
                  </div>
                   
                </div>
               
                <div class="card-body">

                  <div id="table-responsive" style="overflow-x: auto;" class="table-responsive text-nowrap">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="tablehead" style="text-align: center;">Month</th>
                          <th class="tablehead" style="text-align: center;">Year</th>
                          <th class="tablehead" style="text-align: center;" *ngIf="fuel_rate_status">Fuel Rate</th>
                          <th class="tablehead" style="text-align: center;">Per Kwh Unit Rate (Rs)</th>
                          <th class="tablehead" style="text-align: center;">Grid Selling Rate (Rs)</th>
                          <th class="tablehead" style="text-align: center;">Genset Unit Rate (Rs)</th>
                          <th class="tablehead" style="text-align: center;">Service Charges</th>
                          <th class="tablehead" style="text-align: center;">Misc Charges</th>
                          <th class="tablehead" style="text-align: center;">Late Charges (%)</th>
                          <th class="tablehead" style="text-align: center;">Modified On</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of tabular_data">
                          <td style="text-align: center;">{{ data.month }}</td>
                          <td style="text-align: center;">{{ data.year }}</td>
                          <td style="text-align: center;" *ngIf="fuel_rate_status">{{ data.fuel_rate }}</td>
                          <td style="text-align: center;">{{ data.per_kwh_unit_rate }}</td>
                          <td style="text-align: center;">{{ data.selling_rate }}</td>
                          <td style="text-align: center;">{{ data.genset_unit_rate_rs }}</td>
                          <td style="text-align: center;">{{ data.service_charges }}</td>
                          <td style="text-align: center;">{{ data.misc_charges }}</td>
                          <td style="text-align: center;">{{ data.late_charges }}</td>
                          <td style="text-align: center;">{{ data.modified_on }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-end pagination">

                    <input class="p-btn" type="button" value="Prev"
                        (click)="pageSelection('prev_page')">

                    &nbsp;&nbsp;&nbsp;
                    <label class="mt-2" style="color: rgb(1, 55, 105);"> Page </label>
                    &nbsp;&nbsp;

                    <select [(ngModel)]="cost_page" (change)="pageSelection('curr_page')"
                        style="width:80px; border-radius:5px;color:black;">
                        <option *ngFor="let cost_page of cost_pages">{{ cost_page }}</option>
                    </select>

                    &nbsp; &nbsp;
                    <label class="mt-2 text-nowrap" style="color: rgb(1, 55, 105);"> of {{ cost_pages.length | number
                        : '1.0-0'}}</label>
                    &nbsp; &nbsp;
                    <input class="p-btn" type="button" value="Next"
                        (click)="pageSelection('next_page')">
                </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div> <!-- row -->
    </ng-template>
  </ngb-tab>

  <ngb-tab id="tab-counter-correction" title="Counter Correction">
    <ng-template ngbTabContent>
      <div class="row mt-4">
        <div class="col-lg-12 col-xl-12 stretch-card" id="tab-table">
          <div class="row flex-grow justify-content-evenly">

            <div class="col grid-margin stretch-card">
              <div class="card">
                <div class="card-body card-grid">

                  <!-- device hierarcy -->
                  <div class="br-1 float-left pr-2 position-relative">
                    <div class="tree-view float-left" style="max-width: 269px;min-width: 269px;">

                      <div class="tree-tabs">
                        <ngb-tabset #t="ngbTabset" (tabChange)="tabChange($event)" #tabset>

                          <ngb-tab id="tab-level" title="Level">
                            <ng-template ngbTabContent>
                              <zainergy-recursive-control-panel [device_hierarchy]="device_hierarchy">
                              </zainergy-recursive-control-panel>
                            </ng-template>
                          </ngb-tab>

                        </ngb-tabset>
                      </div>

                    </div>
                  </div>
                  <!-- end device hierarcy -->

                  <form>

                    <!-- device location  -->
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <h4>{{ device_location }}</h4>
                          <hr>
                        </div>
                      </div>
                    </div>
                    <!-- end device location -->

                    <div id="row" style="margin-top: 10em;" class="row">

                      <div class="col-lg-12 col-xl-12 stretch-card" id="tab-table">
                        <div style="justify-content:space-evenly;" class="row flex-grow">

                          <!-- Refilling Counter code -->

                          <div class="counter-card card border-dark mb-3 bg-transparent">

                            <div
                              style="background-color: rgba(128, 128, 128, 0.781); color:#fbfdfc; text-align: center;"
                              class="card-header border-dark">
                              <h4>Refilling Counter</h4>
                            </div>

                            <div style="text-align: center;" class="card-body text-dark">

                              <div class="d-grid mt-5 row justify-content-center">
                                <div class="user-box">
                                  <input type="number" value="{{refelling.current_counter}}">
                                  <label>Current Counter:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" [(ngModel)]="refelling.correction_counter"
                                    [ngModelOptions]="{standalone: true}" value="0.00"
                                    (keyup)="setNewCounter('refelling')">
                                  <label>Correction:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" [(ngModel)]="refelling.new_counter"
                                    [ngModelOptions]="{standalone: true}" value="0.00">
                                  <label>New Counter:</label>
                                </div>
                              </div>
                              <br>
                            </div>
                            <div style="padding: 8px;" class="card-footer border-dark justify-content-end text-right">
                              <button type="button" class="btn btn-primary"
                                (click)="updateLevelCounter('refelling')">Update</button>
                            </div>
                          </div>

                          <!-- Calibration -->
                          <div class="counter-card card border-dark mb-3 bg-transparent">

                            <div
                              style="background-color: rgba(128, 128, 128, 0.781); color:#fbfdfc; text-align: center;"
                              class="card-header border-dark">
                              <h4>Calibration</h4>
                            </div>

                            <div style="text-align: center;" class="card-body text-dark">


                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" value="0.00">
                                  <label>First bit:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" value="0.00">
                                  <label>Last bit:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" value="0.00">
                                  <label>Height:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" value="0.00">
                                  <label>Percentage Factor:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" [(ngModel)]="consumption.new_counter"
                                    [ngModelOptions]="{standalone: true}" value="0.00">
                                  <label>Counter Correction:</label>
                                </div>
                              </div>
                              <br>

                            </div>
                            <div style="padding: 8px;" class="card-footer border-dark justify-content-end text-right">
                              <button type="button" class="btn btn-primary"
                                (click)="updateLevelCounter('consumption')">Update</button>
                            </div>
                          </div>

                          <!-- Consumption Counter code -->

                          <div class="counter-card card border-dark mb-3 bg-transparent">

                            <div
                              style="background-color: rgba(128, 128, 128, 0.781); color:#fbfdfc; text-align: center;"
                              class="card-header border-dark">
                              <h4>Consumption Counter</h4>
                            </div>

                            <div style="text-align: center;" class="card-body text-dark">

                              <div class="d-grid mt-5 row justify-content-center">
                                <div class="user-box">
                                  <input type="number" value="{{ consumption.current_counter }}">
                                  <label>Current Counter:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" [(ngModel)]="consumption.correction_counter"
                                    [ngModelOptions]="{standalone: true}" value="0.00"
                                    (keyup)="setNewCounter('consumption')">
                                  <label>Correction:</label>
                                </div>
                              </div>
                              <br>
                              <div class="d-grid row justify-content-center">
                                <div class="user-box">
                                  <input type="number" [(ngModel)]="consumption.new_counter"
                                    [ngModelOptions]="{standalone: true}" value="0.00">
                                  <label>New Counter:</label>
                                </div>
                              </div>
                              <br>

                            </div>
                            <div style="padding: 8px;" class="card-footer border-dark justify-content-end text-right">
                              <button type="button" class="btn btn-primary"
                                (click)="updateLevelCounter('consumption')">Update</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- row -->
    </ng-template>
  </ngb-tab>

</ngb-tabset>